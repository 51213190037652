<template>
  <div class="h-100 m-0">

    <div class="row m-0 p-0 pt-4 pb-4">
      <!-- Add Advertise Section - Form section -->
      <div class="col-12 col-lg-4 col-md-12 col-sm-12 col-xs-12">

        <div class="row h-100">
          <div class="col-12 col-md-12">
            <div id="premisesGroup">
            <div class="card shadow sm">
              <div class="card-body position-relative">

                <!-- Drafts -->
                <div v-if="!editing" class="row p-0 m-0 pt-2 pb-2 drafts sticky">
                  <FormDraft @selectDraft="getSelectedDraft" :type="'ad premises'" :drafts="drafts" :formData="form"></FormDraft>
                </div>

                <hr/>
                <h3 v-if="editing" class="text-center">Edit Advertise</h3>
                <h3 v-else class="text-center">Add Advertise</h3>
                <hr/>
                <ValidationObserver vid="vo-1" ref="initialDetailsFormObserver" v-slot="{ handleSubmit }">
                <form action="javascript:void(0)" onsubmit="return false;" class="row" method="post" enctype="multipart/form-data" id="initialDetailsForm">
                  <div v-if="!editing" class="form-group col-12 pb-2">
                    <label class="pb-2">What are you looking to do? <span class="required">*</span></label>
                    <ValidationProvider vid="vp-0" rules="required|max:15" v-slot="{ errors }">
                    <select
                        v-model="postType"
                        class="form-select select-placeholder cursor-pointer"
                        @focus="handleFocus($event)"
                        @focusout="handleFocusOut(postType, $event)"
                        @change="resetFormValues"
                        tabindex="-1"
                        aria-label="postType"
                        name="postType"
                        id="postType"
                    >
                      <option value="" disabled>Select advertise type</option>
                      <option :value="1">For Sale</option>
                      <option :value="2">To Buy</option>
                      <option :value="3">To Let</option>
                      <option :value="4">To Rent</option>
<!--                      <option :value="5">Swap</option>-->
                    </select>
                      <span class="red-text"><small>{{ errors[0] }}</small></span>
                    </ValidationProvider>
                  </div>

                  <!-- Start Term (Short or Long) postType 3 = LET -->
                  <div v-if="!editing && postType && postType === 3" class="col-12 mt-3 mb-3">
                    <!-- Start Become Host Info and Lead -->
                    <div v-if="showBecomeHostMsg" class="row">
                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <!-- Information -->
                      <small class="p-3">
                        In order to advertise for a short-term (BnB, Hotels, Rooms, Premises) you must first activate Host account.
                        You can do that by navigating to
                        <router-link to="/dashboard-statistics" class="text-decoration-none">Dashboard Statistics</router-link> and
                        clicking on <strong><i>Become Host</i></strong> button.
                      </small>

                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->
                    </div>
                    <!-- End Become Host Info and Lead -->
                    <label for="term" class="pb-2">Tenancy Term</label><span class="required"> *</span>
                    <br/>
                    <div class="row m-0 p-0 mb-2 text-start">
                      <div class="col-5 text-center">
                        <div class="list-relative">
                          <span data-tooltip="tooltip">
                            <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                              <p :class="form.is_short_term == 0 ? 'options-paragraph m-0 p-0 me-2 d-inline green-text' : 'options-paragraph m-0 p-0 me-2 d-inline grey-text'">Long-Term</p>
                              <font-awesome-icon icon="calendar-week" class="icon-fa" />
                              <span class="tooltiptext">Long-term means lets on longer term basis (usually 3 months +), it goes with binding contract such as AST (Assured Shorthold Tenancy) or similar. You will be able to chose payment plan at the very bottom of the advertise form</span>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="col-2">
                        <label class="switch">
                          <input @change="resetFormValues" type="checkbox" id="term" v-model="form.is_short_term">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <div class="col-5 text-center">
                        <div class="list-relative">
                          <span data-tooltip="tooltip">
                            <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                              <font-awesome-icon icon="calendar-day" class="icon-fa" />
                              <p :class="form.is_short_term == 1 ? 'options-paragraph m-0 p-0 me-2 d-inline green-text' : 'options-paragraph m-0 p-0 me-2 d-inline grey-text'"> Short-Term</p>
                              <span class="tooltiptext">Short-term means lets on short term basis (usually up to 30 days), you should chose it if you are BnB, Hotel, Short-term Room or premises rental etc. Short-term ads are charged on provision basis - 3% off booking price</span>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Term (Short or Long) -->

                  <!-- Start Property Types -->
                  <div v-if="!editing" class="col-12 p-2 border-lr">
                    <label class="pb-2">Premises Type <span class="required">*</span></label>
                    <button
                        type="button"
                        id="dropdownPropertyCatType"

                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        v-model="form.property_category_id"
                        class="form-select select-placeholder text-start dropdown-btn"
                        @focus="handleFocus($event)"
                        @focusout="handleFocusOut(form.property_category_id, $event)"
                    >
                      {{ form.propertyCatType ? form.propertyCatType : 'Select Premises Type' }}
                    </button>
                    <div id="addEditPropType" class="dropdown-menu dropdown-menu-prop-type" aria-labelledby="dropdownPropertyCatType">

                      <div class="row m-0 p-0">
                        <!-- Residential - Houses -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_houses">
                              <input v-if="postType === 2 || postType === 4" type="checkbox" v-model="form.property_types.houses" @click="actionPropType('houses', $event)" id="ad_houses" class="form-check-input m-1">
                              Houses
                            </label>
                          </div>
                          <hr/>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_detached_house">
                              <input type="checkbox" :disabled="form.property_types.houses_disable" v-model="form.property_types.detached_house" id="ad_detached_house" class="form-check-input m-1">
                              Detached House
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_semi_detached_house">
                              <input type="checkbox" :disabled="form.property_types.houses_disable" v-model="form.property_types.semi_detached_house" id="ad_semi_detached_house" class="form-check-input m-1">
                              Semi-Detached House
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_mid_terraced">
                              <input type="checkbox" :disabled="form.property_types.houses_disable" v-model="form.property_types.mid_terraced" id="ad_mid_terraced" class="form-check-input m-1">
                              Mid-Terraced House
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_end_terraced">
                              <input type="checkbox" :disabled="form.property_types.houses_disable" v-model="form.property_types.end_terraced" id="ad_end_terraced" class="form-check-input m-1">
                              End-Terraced House
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_detached_bungalow">
                              <input type="checkbox" :disabled="form.property_types.houses_disable" v-model="form.property_types.detached_bungalow" id="ad_detached_bungalow" class="form-check-input m-1">
                              Detached Bungalow
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_semi_detached_bungalow">
                              <input type="checkbox" :disabled="form.property_types.houses_disable" v-model="form.property_types.semi_detached_bungalow" id="ad_semi_detached_bungalow" class="form-check-input m-1">
                              Semi-Detached Bungalow
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_cottage">
                              <input type="checkbox" :disabled="form.property_types.houses_disable" v-model="form.property_types.cottage" id="ad_cottage" class="form-check-input m-1">
                              Cottage
                            </label>
                          </div>
                        </div>
                        <!-- Residential - Flats -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_flats">
                              <input v-if="postType === 2 || postType === 4" type="checkbox" v-model="form.property_types.flats" @click="actionPropType('flats', $event)" id="ad_flats" class="form-check-input m-1">
                              Flats
                            </label>
                          </div>
                          <hr/>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_flat">
                              <input type="checkbox" :disabled="form.property_types.flats_disable" v-model="form.property_types.flat" id="ad_flat" class="form-check-input m-1">
                              Flat
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_studio">
                              <input type="checkbox" :disabled="form.property_types.flats_disable" v-model="form.property_types.studio" id="ad_studio" class="form-check-input m-1">
                              Studio Flat
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_maisonette">
                              <input type="checkbox" :disabled="form.property_types.flats_disable" v-model="form.property_types.maisonette" id="ad_maisonette" class="form-check-input m-1">
                              Maisonette
                            </label>
                          </div>
                        </div>
                        <!-- Commercial - Leisure -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_leisure">
                              <input v-if="postType === 2 || postType === 4" type="checkbox" v-model="form.property_types.leisure" @click="actionPropType('leisure', $event)" id="ad_leisure" class="form-check-input m-1">
                              Leisure
                            </label>
                          </div>
                          <hr/>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_restaurant_cafe">
                              <input type="checkbox" :disabled="form.property_types.leisure_disable" v-model="form.property_types.restaurant_cafe" id="ad_restaurant_cafe" class="form-check-input m-1">
                              Restaurant / Cafe
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_takeaway">
                              <input type="checkbox" :disabled="form.property_types.leisure_disable" v-model="form.property_types.takeaway" id="ad_takeaway" class="form-check-input m-1">
                              Takeaway
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_pub">
                              <input type="checkbox" :disabled="form.property_types.leisure_disable" v-model="form.property_types.pub" id="ad_pub" class="form-check-input m-1">
                              Pub
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_entertainment">
                              <input type="checkbox" :disabled="form.property_types.leisure_disable" v-model="form.property_types.entertainment" id="ad_entertainment" class="form-check-input m-1">
                              Entertainment
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_sport_healthcare_facility">
                              <input type="checkbox" :disabled="form.property_types.leisure_disable" v-model="form.property_types.sport_healthcare_facility" id="ad_sport_healthcare_facility" class="form-check-input m-1">
                              Sport / Healthcare Facility
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_hotel_tourism">
                              <input type="checkbox" :disabled="form.property_types.leisure_disable" v-model="form.property_types.hotel_tourism" id="ad_hotel_tourism" class="form-check-input m-1">
                              Hotel / Tourism
                            </label>
                          </div>
                        </div>
                        <!--Rooms -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_rooms">
                              <input type="checkbox" v-model="form.property_types.rooms" @click="actionPropType('rooms', $event)" id="ad_rooms" class="form-check-input m-1">
                              Room
                            </label>
                          </div>
                        </div>
                        <!-- Land -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_land">
                              <input v-if="postType === 2 || postType === 4" type="checkbox" v-model="form.property_types.land" @click="actionPropType('land', $event)" id="ad_land" class="form-check-input m-1">
                              Land
                            </label>
                          </div>
                          <hr/>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_greenfield">
                              <input type="checkbox" :disabled="form.property_types.land_disable" v-model="form.property_types.greenfield" id="ad_greenfield" class="form-check-input m-1">
                              Greenfield
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_brownfield">
                              <input type="checkbox" :disabled="form.property_types.land_disable" v-model="form.property_types.brownfield" id="ad_brownfield" class="form-check-input m-1">
                              Brownfield
                            </label>
                          </div>
                        </div>
                        <!-- Commercial - Retail -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_retail">
                              <input v-if="postType === 2 || postType === 4" type="checkbox" v-model="form.property_types.retail" @click="actionPropType('retail', $event)" id="ad_retail" class="form-check-input m-1">
                              Retail
                            </label>
                          </div>
                          <hr/>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_shop">
                              <input type="checkbox" :disabled="form.property_types.retail_disable" v-model="form.property_types.shop" id="ad_shop" class="form-check-input m-1">
                              Shop
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_shopping_mall">
                              <input type="checkbox" :disabled="form.property_types.retail_disable" v-model="form.property_types.shopping_mall" id="ad_shopping_mall" class="form-check-input m-1">
                              Shopping Mall
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_retail_store">
                              <input type="checkbox" :disabled="form.property_types.retail_disable" v-model="form.property_types.retail_store" id="ad_retail_store" class="form-check-input m-1">
                              Retail Store
                            </label>
                          </div>
                        </div>
                        <!-- Commercial - Offices -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_offices">
                              <input v-if="postType === 2 || postType === 4" type="checkbox" v-model="form.property_types.offices" @click="actionPropType('offices', $event)" id="ad_offices" class="form-check-input m-1">
                              Offices
                            </label>
                          </div>
                          <hr/>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_traditional_office">
                              <input type="checkbox" :disabled="form.property_types.offices_disable" v-model="form.property_types.traditional_office" id="ad_traditional_office" class="form-check-input m-1">
                              Traditional Office
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_serviced_office">
                              <input type="checkbox" :disabled="form.property_types.offices_disable" v-model="form.property_types.serviced_office" id="ad_serviced_office" class="form-check-input m-1">
                              Serviced Office
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_managed_office">
                              <input type="checkbox" :disabled="form.property_types.offices_disable" v-model="form.property_types.managed_office" id="ad_managed_office" class="form-check-input m-1">
                              Managed Office
                            </label>
                          </div>
                        </div>
                        <!-- Commercial - Healthcare -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_healthcare">
                              <input v-if="postType === 2 || postType === 4" type="checkbox" v-model="form.property_types.healthcare" @click="actionPropType('healthcare', $event)" id="ad_healthcare" class="form-check-input m-1">
                              Healthcare
                            </label>
                          </div>
                          <hr/>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_pharmacy">
                              <input type="checkbox" :disabled="form.property_types.healthcare_disable" v-model="form.property_types.pharmacy" id="ad_pharmacy" class="form-check-input m-1">
                              Pharmacy
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_medical_center">
                              <input type="checkbox" :disabled="form.property_types.healthcare_disable" v-model="form.property_types.medical_center" id="ad_medical_center" class="form-check-input m-1">
                              Medical Center
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_nursing_home">
                              <input type="checkbox" :disabled="form.property_types.healthcare_disable" v-model="form.property_types.nursing_home" id="ad_nursing_home" class="form-check-input m-1">
                              Nursing Home
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_hospital">
                              <input type="checkbox" :disabled="form.property_types.healthcare_disable" v-model="form.property_types.hospital" id="ad_hospital" class="form-check-input m-1">
                              Hospital
                            </label>
                          </div>
                        </div>
                        <!-- Commercial - Garage/Parking -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_garage_parking">
                              <input v-if="postType === 2 || postType === 4" type="checkbox" v-model="form.property_types.garage_parking" @click="actionPropType('garage_parking', $event)" id="ad_garage_parking" class="form-check-input m-1">
                              Garage / Parking
                            </label>
                          </div>
                          <hr/>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_parking_space">
                              <input type="checkbox" :disabled="form.property_types.garage_parking_disable" v-model="form.property_types.parking_space" id="ad_parking_space" class="form-check-input m-1">
                              Parking Space
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_single_garage">
                              <input type="checkbox" :disabled="form.property_types.garage_parking_disable" v-model="form.property_types.single_garage" id="ad_single_garage" class="form-check-input m-1">
                              Single Garage
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_multiple_garages">
                              <input type="checkbox" :disabled="form.property_types.garage_parking_disable" v-model="form.property_types.multiple_garages" id="ad_multiple_garages" class="form-check-input m-1">
                              Multiple Garages
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_parking_lot">
                              <input type="checkbox" :disabled="form.property_types.garage_parking_disable" v-model="form.property_types.parking_lot" id="ad_parking_lot" class="form-check-input m-1">
                              Parking Lot
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_multi_storey_parking">
                              <input type="checkbox" :disabled="form.property_types.garage_parking_disable" v-model="form.property_types.multi_storey_parking" id="ad_multi_storey_parking" class="form-check-input m-1">
                              Multi-Storey Parking
                            </label>
                          </div>
                        </div>
                        <!-- Commercial - Multifamily -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_multifamily">
                              <input v-if="postType === 2 || postType === 4" type="checkbox" v-model="form.property_types.multifamily" @click="actionPropType('multifamily', $event)" id="ad_multifamily" class="form-check-input m-1">
                              Multifamily
                            </label>
                          </div>
                          <hr/>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_duplex">
                              <input type="checkbox" :disabled="form.property_types.multifamily_disable" v-model="form.property_types.duplex" id="ad_duplex" class="form-check-input m-1">
                              Duplex
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_block_of_flats">
                              <input type="checkbox" :disabled="form.property_types.multifamily_disable" v-model="form.property_types.block_of_flats" id="ad_block_of_flats" class="form-check-input m-1">
                              Block of Flats
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_apartment_complex">
                              <input type="checkbox" :disabled="form.property_types.multifamily_disable" v-model="form.property_types.apartment_complex" id="ad_apartment_complex" class="form-check-input m-1">
                              Apartment Complex
                            </label>
                          </div>
                        </div>
                        <!-- Luxury -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_luxury">
                              <input v-if="postType === 2 || postType === 4" type="checkbox" v-model="form.property_types.luxury" @click="actionPropType('luxury', $event)" id="ad_luxury" class="form-check-input m-1">
                              Luxury
                            </label>
                          </div>
                          <hr/>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_mansion">
                              <input type="checkbox" :disabled="form.property_types.luxury_disable" v-model="form.property_types.mansion" id="ad_mansion" class="form-check-input m-1">
                              Mansion
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_villa">
                              <input type="checkbox" :disabled="form.property_types.luxury_disable" v-model="form.property_types.villa" id="ad_villa" class="form-check-input m-1">
                              Villa
                            </label>
                          </div>
                        </div>
                        <!-- Special Use -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_special_use">
                              <input v-if="postType === 2 || postType === 4" type="checkbox" v-model="form.property_types.special_use" @click="actionPropType('special_use', $event)" id="ad_special_use" class="form-check-input m-1">
                              Special Use
                            </label>
                          </div>
                          <hr/>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_church">
                              <input type="checkbox" :disabled="form.property_types.special_use_disable" v-model="form.property_types.church" id="ad_church" class="form-check-input m-1">
                              Church
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_cemetery">
                              <input type="checkbox" :disabled="form.property_types.special_use_disable" v-model="form.property_types.cemetery" id="ad_cemetery" class="form-check-input m-1">
                              Cemetery
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_park">
                              <input type="checkbox" :disabled="form.property_types.special_use_disable" v-model="form.property_types.park" id="ad_park" class="form-check-input m-1">
                              Park
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_school">
                              <input type="checkbox" :disabled="form.property_types.special_use_disable" v-model="form.property_types.school" id="ad_school" class="form-check-input m-1">
                              School
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_barn">
                              <input type="checkbox" :disabled="form.property_types.special_use_disable" v-model="form.property_types.barn" id="ad_barn" class="form-check-input m-1">
                              Barn
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_campsite">
                              <input type="checkbox" :disabled="form.property_types.special_use_disable" v-model="form.property_types.campsite" id="ad_campsite" class="form-check-input m-1">
                              Campsite
                            </label>
                          </div>
                        </div>
                        <!-- Industrial - Warehouses -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_warehouses">
                              <input type="checkbox" v-model="form.property_types.warehouses" @click="actionPropType('warehouses', $event)" id="ad_warehouses" class="form-check-input m-1">
                              Warehouses
                            </label>
                          </div>
                          <hr/>
                        </div>
                        <!-- Industrial - Factories -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_factories">
                              <input type="checkbox" v-model="form.property_types.factories" @click="actionPropType('factories', $event)" id="ad_factories" class="form-check-input m-1">
                              Factories
                            </label>
                          </div>
                          <hr/>
                        </div>
                        <!-- Industrial - Power Plants -->
                        <div class="col-6 m-0 p-1">
                          <hr/>
                          <div class="col-12 m-1 p-0 filter-prop-label">
                            <label class="form-check-label me-1 ms-1 fw-bold" for="ad_power_plants">
                              <input v-if="postType === 2 || postType === 4" type="checkbox" v-model="form.property_types.power_plants" @click="actionPropType('power_plants', $event)" id="ad_power_plants" class="form-check-input m-1">
                              Power Plants
                            </label>
                          </div>
                          <hr/>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_hydroelectric_plant">
                              <input type="checkbox" :disabled="form.property_types.power_plants_disable" v-model="form.property_types.hydroelectric_plant" id="ad_hydroelectric_plant" class="form-check-input m-1">
                              Hydroelectric Plant
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_coal_fired_plant">
                              <input type="checkbox" :disabled="form.property_types.power_plants_disable" v-model="form.property_types.coal_fired_plant" id="ad_coal_fired_plant" class="form-check-input m-1">
                              Coal-fired Plant
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_diesel_fired_plant">
                              <input type="checkbox" :disabled="form.property_types.power_plants_disable" v-model="form.property_types.diesel_fired_plant" id="ad_diesel_fired_plant" class="form-check-input m-1">
                              Diesel-fired Plant
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_geothermal_plant">
                              <input type="checkbox" :disabled="form.property_types.power_plants_disable" v-model="form.property_types.geothermal_plant" id="ad_geothermal_plant" class="form-check-input m-1">
                              Geothermal Plant
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_gas_fired_plant">
                              <input type="checkbox" :disabled="form.property_types.power_plants_disable" v-model="form.property_types.gas_fired_plant" id="ad_gas_fired_plant" class="form-check-input m-1">
                              Gas-fired Plant
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_solar_power_plant">
                              <input type="checkbox" :disabled="form.property_types.power_plants_disable" v-model="form.property_types.solar_power_plant" id="ad_solar_power_plant" class="form-check-input m-1">
                              Solar Power Plant
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_wind_power_plant">
                              <input type="checkbox" :disabled="form.property_types.power_plants_disable" v-model="form.property_types.wind_power_plant" id="ad_wind_power_plant" class="form-check-input m-1">
                              Wind Power Plant
                            </label>
                          </div>
                          <div class="col-12 m-1 p-0">
                            <label class="form-check-label me-1 ms-1" for="ad_tidal_power_plant">
                              <input type="checkbox" :disabled="form.property_types.power_plants_disable" v-model="form.property_types.tidal_power_plant" id="ad_tidal_power_plant" class="form-check-input m-1">
                              Tidal Power Plant
                            </label>
                          </div>
                        </div>

                      </div>
                    </div>
                    <p v-if="validationErrors && validationErrors.property_category_id" class="required-info text-center">
                      {{ validationErrors.property_category_id[0] }}
                    </p>
                  </div>
                  <!-- End Property Types -->

                  <!-- Start Horizontal Line with Icon -->
                  <div v-if="!editing" class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Information -->
                  <small v-if="!editing" class="p-3">Note that changing any of the form entries above will reset all values of the form below, so please make sure that you are happy with your choices.</small>

                </form>
                </ValidationObserver>

                <div v-if="postType && form.property_category_id"> <!-- v-if="postType && form.property_category_id" -->

                <ValidationObserver vid="vo-2" ref="basicDetailsFormObserver" v-slot="{ handleSubmit }">
                <form action="javascript:void(0)" onsubmit="return false;" class="row" method="post" enctype="multipart/form-data" id="basicDetailsForm">
                  <div v-if="postType" class="form-group col-12 mb-1 text-start">
                    <!-- Start Horizontal Line with Icon -->
                    <div class="horizontal-separator-box pt-1 pb-3">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->

                    <!-- Information -->
                    <div class="col-12 mb-3 text-center">
                      <small class="p-3">By clicking on below sections you can access grouped form fields. Red asterisk (<span class="required">*</span>) shows required fields. </small>
                    </div>

                    <!-- Information -->
                    <button class="section-btn collapsed" @click.prevent="scrollToView('basicDetails')" style="width: 100%;" type="button" data-bs-toggle="collapse" data-bs-target="#basicDetails" aria-expanded="false" aria-controls="basicDetails">
                      <small class="p-3 fw-bold">{{ postType === 3 || postType === 1 ? 'Premises Info' : 'Basic Details'}}</small>
                      <span :class="checkBasicInfoValidation ? 'check-no-validation-error check-validation-error' : 'check-no-validation-error'">
                          <font-awesome-icon icon="asterisk" class="icon-fa"/>
                      </span>
                      <span :class="basicDetailsValid ? 'check-not-filled check-filled' : 'check-not-filled'">
                          <font-awesome-icon icon="check-circle" class="icon-fa"/>
                      </span>
                    </button>

                  </div>

                  <!-- Start Toggles and fields from property details -->
                  <div class="col-12 text-start mb-1 p-0">

                    <!-- Start Basic Details Collapse Section -->
                    <div class="collapse" id="basicDetails" data-bs-parent="#premisesGroup">

                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <!-- postType 2 = Buy; postType 4 = Rent -->
                      <div v-if="postType === 2 || postType === 4" class="row m-0 p-0 text-center">
                        <div class="form-group col-12 pt-2 pb-2">
                          <label for="propertyTitle" class="pb-2">Advertise Title</label><span class="required">*</span>
                          <ValidationProvider vid="vp-1" rules="required|min:3|max:255" v-slot="{ errors }">
                            <input type="text" name="propertyTitle" maxlength="255" v-model="form.property_title" id="propertyTitle" placeholder="Enter advertise title" class="form-control">
                            <span class="red-text"><small>{{ errors[0] }}</small></span>
                          </ValidationProvider>
                          <p v-if="validationErrors && validationErrors.property_title" class="required-info text-center">
                          {{ validationErrors.property_title[0] }}
                          </p>
                        </div>
                      </div>
                      <!-- Start Property Address Fields -->
                      <!-- postType 2 = Buy; postType 4 = Rent  && (postType !== 2 && postType !== 4) -->
                      <div class="row m-0 p-0 text-center">
                        <!-- Address -->
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Should be col-8, for time being col-12 as we do not do manual address enter -->
                            <div class="col-12">
                              <label class="pb-2">{{postType === 2 || postType === 4 ? 'Required Area' : 'Premises Address'}} <span class="required">*</span></label>
                              <div ref="geocoderAddEditAdRef" class="geocoder-add-edit-ad" id="geocoderAddEditAd"></div>
                              <ValidationProvider vid="vp-2" rules="required|min:3|max:200" v-slot="{ errors }">
                                <input type="text" name="fullAddress" maxlength="200" v-model="form.full_address" id="fullAddress" class="visually-hidden">
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.full_address" class="required-info text-center">
                            {{ validationErrors.full_address[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.postcode" class="required-info text-center">
                            {{ validationErrors.postcode[0] }}
                          </p>
  <!--                        <div class="row m-0 p-0 mt-2 mb-2">-->
  <!--                          <div class="col-6 pt-2 text-start">-->
  <!--                            <div class="list-relative">-->
  <!--                            <span data-tooltip="tooltip">-->
  <!--                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
  <!--                                <p class="options-paragraph m-0 p-0 me-2 d-inline">Star Rating</p>-->
  <!--                                <font-awesome-icon icon="star" class="icon-fa star" />-->
  <!--                                <span v-if="postType && (postType === 1 || postType === 3)" class="tooltiptext">What is the current star rating? (Optional - mainly for Hotels)</span>-->
  <!--                                <span v-else class="tooltiptext">What is the required star rating? (Optional - mainly for Hotels)</span>-->
  <!--                              </div>-->
  <!--                            </span>-->
  <!--                            </div>-->
  <!--                          </div>-->
  <!--                          <div class="col-6">-->
  <!--                            <select-->
  <!--                                v-model="form.star_rating"-->
  <!--                                class="options-select form-select select-placeholder"-->
  <!--                                @focus="handleFocus($event)"-->
  <!--                                @focusout="handleFocusOut(form.star_rating, $event)"-->
  <!--                                tabindex="-1"-->
  <!--                                aria-label="star-rating"-->
  <!--                                name="star-rating"-->
  <!--                                id="starRating"-->
  <!--                            >-->
  <!--                              <option value="">Select your rating</option>-->
  <!--                              <option :value="1">1 Star</option>-->
  <!--                              <option :value="2">2 Stars</option>-->
  <!--                              <option :value="3">3 Stars</option>-->
  <!--                              <option :value="4">4 Stars</option>-->
  <!--                              <option :value="5">5 Stars</option>-->
  <!--                            </select>-->
  <!--                          </div>-->
  <!--                        </div>-->
  <!--                        <p v-if="validationErrors && validationErrors.star_rating" class="required-info text-center">-->
  <!--                          {{ validationErrors.star_rating[0] }}-->
  <!--                        </p>-->
                        </div>
                      </div>

  <!--                    <div v-show="postType && is_manual_address" class="row m-0 p-0">-->
  <!--                      &lt;!&ndash; Manual Address &ndash;&gt;-->
  <!--                      <div class="col-12 p-0 mt-1 mb-1">-->
  <!--                        <div class="row m-0 p-0">-->
  <!--                          <div class="col-8 pt-2">-->
  <!--                            line_1, line_2, postcode, city, county, country-->
  <!--                          </div>-->
  <!--                          <div class="col-4 pt-2">-->

  <!--                          </div>-->
  <!--                        </div>-->
  <!--                      </div>-->
  <!--                    </div>-->
                      <!-- End Property Address Fields -->

                      <!-- Start Horizontal Line with Icon -->
                      <div v-if="postType" class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <!-- Start Upload Property Images -->
                      <div v-show="postType" class="row m-0 p-0 text-center">
                        <!-- Property Images Upload -->
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <div class="col-12">
                              <!-- Buy (2) or Rent (4) postType -->
<!--                              <p v-if="postType === 2 || postType === 4" class="pb-2">Advertise Images | Left: <span style="color: red;">{{ calculateAllowedImages(form.previewPropImages, form.editPropImages, 20) }}</span></p>-->
<!--                              <p v-else class="pb-2">Premises Images | Left: <span style="color: red;">{{ calculateAllowedImages(form.previewPropImages, form.editPropImages, 20) }}</span></p>-->
                              <p v-if="postType === 2 || postType === 4" class="pb-2">Advertise Images | Uploaded: <span style="color: red;">{{ calculateImages(form.previewPropImages, form.editPropImages) }} / 20</span></p>
                              <p v-else class="pb-2">Premises Images | Uploaded: <span style="color: red;">{{ calculateImages(form.previewPropImages, form.editPropImages) }} / 20</span></p>
                              <div ref="dropboxImage" class="dropbox">
                                <input type="file" accept="image/*" @change="uploadPropImages" multiple="multiple" name="previewPropImages" id="propImages" class="input-file">
                                <p>Click on the box or drop the images into the box in order to upload them (Max file size: 2MB)</p>
                                <div class="row p-0 m-0 mb-2">
                                  <div v-for="image in form.previewPropImages" class="col-4 p-1 mt-1 mb-1">
                                    <img v-if="image" :src="image" class="uploading-property-images" />
                                  </div>
                                </div>
                              </div>
                              <div v-if="form.editPropImages && form.editPropImages.length > 0" class="col-12">
                                <ul class="list-unstyled text-start p-1">
                                  <li v-for="(editPropImage, index) in form.editPropImages">
                                    <div class="row g-0 m-0 p-1">
                                      <div class="col-1">
                                        <div class="position-relative">
                                          <button @click="deleteImage(editPropImage.id, editPropImage.document_type_id, editPropImage.file_name)" class="delete-btn">X</button>
                                        </div>
                                      </div>
                                      <div class="col-5">
                                        {{ editPropImage.file_name }}
                                      </div>
                                      <div class="col-6">
                                        <img :src="`${$s3bucket}${editPropImage.file_path}`" @error="setDefaultImg"
                                             class="d-block img-fluid uploading-property-images"
                                             alt="..."
                                        >
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <!--
                            It would also be better to display name of the image rather than some random number..
                            not sure how to achieve that though
                          -->
                          <div v-for="(error, key, index) in validationErrors">
                            <p v-if="key.includes('propImages')" class="required-info text-center">
                              Image number {{ Number(key.substr(key.indexOf('.') + 1)) + 1 }} - {{ error[0] }}
                            </p>
                            <p v-if="key.includes('prop_images_limit')" class="required-info text-center">
                              {{ error[0] }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- End Upload Property Images -->

                      <!-- Start Horizontal Line with Icon -->
                      <div v-if="!form.is_short_term && postType && postType !== 2 && postType !== 4" class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <!-- Start Upload Floorplan Images -->
                      <div v-show="!form.is_short_term && postType && postType !== 2 && postType !== 4" class="row m-0 p-0 text-center">
                        <!-- Floorplan Images Upload -->
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <div class="col-12">
<!--                              <p class="pb-2">Floorplan Images | Left: <span style="color: red;">{{ calculateAllowedImages(form.previewFloorplanImages, form.editFloorplanImages, 5) }}</span></p>-->
                              <p class="pb-2">Floorplan Images | Uploaded: <span style="color: red;">{{ calculateImages(form.previewFloorplanImages, form.editFloorplanImages) }} / 5</span></p>
                              <div class="dropbox">
                                <input type="file" accept="image/*" @change="uploadFloorplanImages" multiple="multiple" name="previewFloorplanImages" class="input-file">
                                <p>Click on the box or drop the images into the box in order to upload them (Max file size: 2MB)</p>
                                <div class="row p-0 m-0 mb-2">
                                  <div v-for="image in form.previewFloorplanImages" class="col-4 p-1 mt-1 mb-1">
                                    <img v-if="image" :src="image" class="uploading-floorplan-images" />
                                  </div>
                                </div>
                              </div>
                              <div v-if="form.editFloorplanImages && form.editFloorplanImages.length > 0" class="col-12">
                                <ul class="list-unstyled text-start p-1">
                                  <li v-for="(editFloorplanImage, index) in form.editFloorplanImages">
                                    <div class="row g-0 m-0 p-1">
                                      <div class="col-1">
                                        <div class="position-relative">
                                          <button @click="deleteImage(editFloorplanImage.id, editFloorplanImage.document_type_id, editFloorplanImage.file_name)" class="delete-btn">X</button>
                                        </div>
                                      </div>
                                      <div class="col-5">
                                        {{ editFloorplanImage.file_name }}
                                      </div>
                                      <div class="col-6">
                                        <img :src="`${$s3bucket}${editFloorplanImage.file_path}`" @error="setDefaultImg"
                                             class="d-block img-fluid uploading-floorplan-images"
                                             alt="..."
                                        >
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--
                           It would also be better to display name of the image rather than some random number..
                           not sure how to achieve that though
                         -->
                        <div v-for="(error, key, index) in validationErrors">
                          <p v-if="key.includes('floorplanImages')" class="required-info text-center">
                            Image number {{ Number(key.substr(key.indexOf('.') + 1)) + 1 }} - {{ error[0] }}
                          </p>
                          <p v-if="key.includes('floorplan_images_limit')" class="required-info text-center">
                            {{ error[0] }}
                          </p>
                        </div>
                      </div>
                      <!-- End Upload Floorplan Images -->

                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <div v-if="postType" class="form-group col-12 text-center">
                        <label for="propertyDescription" class="pb-2">Advertise Description</label>
                        <div id="propertyDescription">
                          <TipTap ref="tiptap" v-model="form.property_description" :modelValue="form.property_description"/>
                        </div>
                      </div>

                      <!-- Start Horizontal Line with Icon -->
                      <div v-if="postType" class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                    </div>
                    <!-- End Basic Details Collapse Section -->
                  </div>

                </form>
                </ValidationObserver>

                <ValidationObserver vid="vo-3" ref="premisesDetailsFormObserver" v-slot="{ handleSubmit }">
                <form action="javascript:void(0)" onsubmit="return false;" class="row" method="post" enctype="multipart/form-data" id="premisesDetailsForm">

                  <div v-if="postType" class="form-group col-12 mb-1 text-start">

                    <!-- Information -->
                    <button class="section-btn collapsed" @click.prevent="scrollToView('premisesDetails')" style="width: 100%;" type="button" data-bs-toggle="collapse" data-bs-target="#premisesDetails" aria-expanded="false" aria-controls="premisesDetails">
                      <small class="p-3 fw-bold">Premises Details</small>
                      <span :class="checkPremisesDetailsValidation ? 'check-no-validation-error check-validation-error' : 'check-no-validation-error'">
                          <font-awesome-icon icon="asterisk" class="icon-fa"/>
                      </span>
                      <span :class="premisesDetailsValid ? 'check-not-filled check-filled' : 'check-not-filled'">
                          <font-awesome-icon icon="check-circle" class="icon-fa"/>
                      </span>
                    </button>

                  </div>

                  <div v-if="postType" class="col-12 text-start mb-1 p-0">

                    <div class="collapse" id="premisesDetails" data-bs-parent="#premisesGroup">
                      <!-- Start Premises Details Collapse Section -->

                      <div v-if="postType && postType !== 2 && postType !== 4 && !form.is_short_term" class="row m-0 p-0">
                        <!-- Not RENT and not BUY -->
                        <div v-if="postType && postType !== 2 && postType !== 4 && !form.is_short_term" class="form-group col-12 text-center">
                          <!-- Start Horizontal Line with Icon -->
                          <div class="horizontal-separator-box">
                            <span class="hr-line-short"></span>
                            <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                            <span class="hr-line-short"></span>
                          </div>
                          <!-- End Horizontal Line with Icon -->

                          <!-- Information -->
                          <span data-tooltip="tooltip">
                              <div class="no-text-wrap overflow-hidden text-truncate">
                                <small class="p-3 fw-bold">Available Time Slots</small>
                                <font-awesome-icon icon="info-circle" class="icon-fa " style="color: orange;" />
                                <span class="tooltiptext">Available time slots for users who will potentially want to book viewing, this can also be considered as your working hours</span>
                              </div>
                            </span>

                          <!-- Start Horizontal Line with Icon -->
                          <div class="horizontal-separator-box">
                            <span class="hr-line-short"></span>
                            <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                            <span class="hr-line-short"></span>
                          </div>
                          <!-- End Horizontal Line with Icon -->
                        </div>

                        <!-- Booking Slots Availability -->
                        <!-- Not RENT and not BUY -->
                        <div v-if="postType !== 2 && postType !== 4 && !form.is_short_term" class="row m-0 p-0">
                          <!-- Working Hours From Flexible -->
                          <div class="col-6 p-0 mt-2 mb-2">
                            <div class="row m-0 p-0">
                              <div class="col-9">
                                <div class="list-relative">
                                  <span data-tooltip="tooltip">
                                    <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <p class="options-paragraph m-0 p-0 me-2 d-inline">Flexible From</p>
                                      <font-awesome-icon icon="calendar-check" class="icon-fa" />
                                      <span class="tooltiptext">Do you offer flexible booking dates?</span>
                                    </div>
                                  </span>
                                </div>
                              </div>
                              <div class="col-3">
                                <label class="switch">
                                  <input type="checkbox" v-model="form.working_hours_from_flexible">
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <!-- Working Hours To Flexible -->
                          <div class="col-6 p-0 mt-2 mb-2">
                            <div class="row m-0 p-0">
                              <div class="col-9">
                                <div class="list-relative">
                                  <span data-tooltip="tooltip">
                                    <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <p class="options-paragraph m-0 p-0 me-2 d-inline">Flexible To</p>
                                      <font-awesome-icon icon="calendar-minus" class="icon-fa" />
                                      <span class="tooltiptext">Do you offer flexible booking dates?</span>
                                    </div>
                                  </span>
                                </div>
                              </div>
                              <div class="col-3">
                                <label class="switch">
                                  <input type="checkbox" v-model="form.working_hours_to_flexible">
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Not RENT and not BUY -->
                        <div v-if="postType !== 2 && postType !== 4 && !form.is_short_term" class="row m-0 p-0">
                          <div class="col-6 m-0 p-0 mt-1 mb-1 text-center" id="workingHoursFromTimers">
                            <!-- I will use separate time date-pickers for working_hours_from and working_hours_to and singular_date -->
                            <div :class="form.working_hours_from_flexible ? 'form-control mt-1 mb-1' : 'mt-1 mb-1'">
                              <small><strong>{{ !form.working_hours_from_flexible ? 'From:' : 'From: Flexible'}}</strong></small>
                            </div>
                            <date-picker
                                v-if="!form.working_hours_from_flexible"
                                mode="time"
                                v-model="form.working_hours_from"
                                is24hr
                                :minute-increment="15"
                                :reactive=true
                            />
                          </div>
                          <div class="col-6 m-0 p-0 mt-1 mb-1 text-center" id="workingHoursToTimers">
                            <!-- I will use separate time date-pickers for check_in_from and check_in_to and singular_date -->
                            <div :class="form.working_hours_to_flexible ? 'form-control mt-1 mb-1' : 'mt-1 mb-1'">
                              <small><strong>{{ !form.working_hours_to_flexible ? 'To:' : 'To: Flexible'}}</strong></small>
                            </div>
                            <date-picker
                                v-if="!form.working_hours_to_flexible"
                                mode="time"
                                v-model="form.working_hours_to"
                                is24hr
                                :minute-increment="15"
                                :reactive=true
                            />
                          </div>
                        </div>
                      </div>

                      <div v-if="postType" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Basic Details</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- Start Main input fields -->
                      <!-- property_category_id 7 = ROOM -->
                      <div v-if="postType && (checkShortTermOrRoom || form.studio_room)" class="row m-0 p-0">
                        <!-- Price -->
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <div class="col-3 pt-2">
                              <div class="list-relative">
                                <span data-tooltip="tooltip">
                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">Price</p>
                                    <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                    <span class="required"> *</span>
                                    <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">What is the price for your property?</span>
                                    <span v-else class="tooltiptext">What is your required price for the property?</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div class="col-3">
                              <ValidationProvider vid="vp-4" rules="required|min_value:0|max_value:999999999999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="£"
                                  min="0"
                                  max="999999999999"
                                  v-model="form.price"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.price, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
<!--                            <div v-if="!checkShortTermOrRoom" class="col-6 pt-2">-->
<!--                              <div class="list-relative">-->
<!--                                <span data-tooltip="tooltip">-->
<!--                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">Charge Basis</p>-->
<!--                                    <font-awesome-icon icon="pound-sign" class="icon-fa" />-->
<!--                                    <span class="required"> *</span>-->
<!--                                    <span class="tooltiptext">What is your required payment frequency?</span>-->
<!--                                  </div>-->
<!--                                </span>-->
<!--                              </div>-->
<!--                            </div>-->
                            <div class="col-6">
                              <!-- postType 1 = Sell; postType 2 = Buy -->
                              <ValidationProvider v-if="postType !== 1 && postType !== 2" vid="vp-5" rules="required|max:30" v-slot="{ errors }">
                              <select
                                  v-if="postType !== 1 && postType !== 2"
                                  v-model="form.property_rent_type_id"
                                  class="options-select form-select select-placeholder cursor-pointer"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.property_rent_type_id, $event)"
                                  tabindex="-1"
                                  aria-label="propertyRentType"
                                  name="property-rent-type"
                                  id="propertyRentType"
                              >
                                <option value="" disabled>Select payment frequency</option>
                                <option v-if="postType && (form.is_short_term || postType === 4)" :value="2">Per Hour</option>
                                <option v-if="postType && (form.is_short_term || postType === 4)" :value="3">Per Night</option>
                                <option v-if="postType && !form.is_short_term" :value="4">Per Week</option>
                                <option v-if="postType && !form.is_short_term" :value="5">Per Month</option>
                                <option v-if="postType && !form.is_short_term" :value="6">To Be Discussed (TBD)</option>
                              </select>
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.price" class="required-info text-center">
                            {{ validationErrors.price[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.property_rent_type_id" class="required-info text-center">
                            {{ validationErrors.property_rent_type_id[0] }}
                          </p>
                        </div>

                        <!-- Tenure -->
                        <!-- postType 3 = Let; postType 4 = Rent -->
                        <div v-if="postType && (postType !== 3 && postType !== 4)" class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <div class="col-6 pt-2">
                              <div class="list-relative">
                                <span data-tooltip="tooltip">
                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">Tenure</p>
                                    <font-awesome-icon icon="file-signature" class="icon-fa" />
                                    <span class="required"> *</span>
                                    <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is your property Freehold or Leasehold?</span>
                                    <span v-else class="tooltiptext">Are you looking for Freehold or Leasehold?</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div class="col-6">
                              <ValidationProvider vid="vp-6" rules="required|max:20" v-slot="{ errors }">
                              <select
                                  v-model="form.is_freehold"
                                  class="options-select form-select select-placeholder cursor-pointer"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.is_freehold, $event)"
                                  tabindex="-1"
                                  aria-label="isFreehold"
                                  name="is-freehold"
                                  id="isFreehold"
                              >
                                <option value="" disabled>Select tenure type</option>
                                <option :value="true">Freehold</option>
                                <option :value="false">Leasehold</option>
                              </select>
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.is_freehold" class="required-info text-center">
                            {{ validationErrors.is_freehold[0] }}
                          </p>
                        </div>

                        <!-- EPC Rating -->
                        <!-- postType 2 = Buy; postType 3 = Let; postType 4 = Rent -->
                        <div v-if="checkShortTermOrRoom && postType !== 2 && postType !== 4 && postType !== 3" class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <div class="col-6 pt-2">
                              <div class="list-relative">
                                <span data-tooltip="tooltip">
                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">EPC Rating</p>
                                    <font-awesome-icon icon="poll-h" class="icon-fa" />
                                    <span class="required"> *</span>
                                    <span class="tooltiptext">What is the EPC rating of your property?</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div class="col-6">
                              <ValidationProvider vid="vp-7" rules="required|max:20" v-slot="{ errors }">
                              <select
                                  v-model="form.epc_rating"
                                  class="options-select form-select select-placeholder cursor-pointer"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.epc_rating, $event)"
                                  tabindex="-1"
                                  aria-label="epcRating"
                                  name="epc-rating"
                                  id="epcRating"
                              >
                                <option value="" disabled>Select EPC rating</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                                <option value="E">E</option>
                                <option value="F">F</option>
                                <option value="G">G</option>
                                <option value="N/A">Not known</option>
                              </select>
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.epc_rating" class="required-info text-center">
                            {{ validationErrors.epc_rating[0] }}
                          </p>
                        </div>

                        <!-- Max Guests/Tenants -->
                        <div v-if="postType === 3" class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <div class="col-6 pt-2">
                              <div class="list-relative">
                                  <span data-tooltip="tooltip">
                                    <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.is_short_term ? 'Max Guests' : 'Max Tenants'}}</p>
                                      <font-awesome-icon icon="user-friends" class="icon-fa" />
                                      <span class="required"> *</span>
                                      <span v-if="form.is_short_term" class="tooltiptext">What is the maximum guests allowed?</span>
                                      <span v-else class="tooltiptext">What is the maximum tenants allowed?</span>
                                    </div>
                                  </span>
                              </div>
                            </div>
                            <div class="col-6">
                              <ValidationProvider vid="vp-8" rules="required|min_value:0|max_value:999|numeric" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="999"
                                  v-model="form.tenants_guests"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.tenants_guests, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.tenants_guests" class="required-info text-center">
                          {{ validationErrors.tenants_guests[0] }}
                        </p>

                        <!-- Square Meter -->
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <div class="col-6 pt-2">
                              <div class="list-relative">
                                  <span data-tooltip="tooltip">
                                    <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <p class="options-paragraph m-0 p-0 me-2 d-inline">Square Meter</p>
                                      <font-awesome-icon icon="vector-square" class="icon-fa" />
                                      <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Square meter of your property?</span>
                                      <span v-else class="tooltiptext">Required square meter of the property?</span>
                                    </div>
                                  </span>
                              </div>
                            </div>
                            <div class="col-6">
                              <ValidationProvider vid="vp-9" rules="min_value:0|max_value:999999999|numeric" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="㎡"
                                  min="0"
                                  max="999999999"
                                  v-model="form.property_square_meter"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.property_square_meter, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.property_square_meter" class="required-info text-center">
                          {{ validationErrors.property_square_meter[0] }}
                        </p>
                      </div>

                      <!-- Start Horizontal Line with Icon -->
<!--                      <div v-show="!checkShortTermOrRoom && postType && postType === 3 && !form.studio_room" class="horizontal-separator-box">-->
<!--                        <span class="hr-line-short"></span>-->
<!--                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
<!--                        <span class="hr-line-short"></span>-->
<!--                      </div>-->
                      <!-- End Horizontal Line with Icon -->

                      <!-- Charge Basis - Price Info -->
                      <div v-if="!checkShortTermOrRoom && postType && postType === 3 && !form.studio_room" class="col-12 ms-3 me-3">
                        <small class="p-3">Prices can be assigned per room type during 'Add Room' process</small>
                      </div>

                      <div v-if="postType" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Rooms</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- Studio Flat/Room Info -->
                      <div v-if="form.studio_room && postType" class="col-12 ms-3 me-3">
                        <small v-if="form.studio_room && postType" class="p-3">Studio flat got one room, kitchen and living room assigned automatically</small>
                      </div>

                      <!-- Start Horizontal Line with Icon -->
                      <div v-if="form.studio_room && postType" class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->
                      <!-- End Studio Flat/Room Info -->


                      <!-- Start Rooms input fields -->

                      <!-- Add Room functionality - visibile on LET Long-Term (Room), LET Short-Term (Room, other, except Studio Flat) -->
                      <div v-if="!checkShortTermOrRoom && postType && postType === 3 && !form.studio_room" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1 text-start">
                          <div class="row m-0 p-0">
                            <div class="col-12 text-center">
                              <button class="btn btn-outline-primary btn-add"
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#addRoomModal"
                                      @click="sendModal('new')"
                              >
                                Add Room <font-awesome-icon icon="plus-circle" class="icon-fa add-icon" />
                              </button>
                              <span v-if="!checkShortTermOrRoom" class="required"> *</span>
<!--                              &lt;!&ndash; AddRoomModal &ndash;&gt;-->
<!--                              <AddRoomModal :formData="form" :postType="postType"/>-->
                            </div>
                          </div>
                          <div v-if="form.rooms && form.rooms.length > 0" class="row m-0 p-0">
                            <div class="col-12">
                              <ul class="list-unstyled">
                                <li class="rule-list mt-1">
                                  <div class="row m-0 p-0">
                                    <div class="col-5 p-1 options-paragraph">
                                      Type
                                    </div>
                                    <div class="col-2 p-1 options-paragraph">
                                      Guests
                                    </div>
                                    <div class="col-3 p-1 options-paragraph">
                                      Price
                                    </div>
                                    <div class="col-2 p-1 options-paragraph">
                                      Actions
                                    </div>
                                  </div>
                                </li>
                                <li class="rule-list mt-1" v-for="room in form.rooms" v-bind:key="room.id">
                                  <div class="row m-0 p-0">
                                    <div class="col-5 p-1 options-paragraph">
                                      <div class="position-relative">
                                        <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate ">
                                          {{ room.property_room_type.type }}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-2 p-1 options-paragraph">
                                      {{ room.guests }}
                                    </div>
                                    <div class="col-3 p-1 options-paragraph">
                                      <div class="position-relative">
                                        <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <font-awesome-icon icon="pound-sign" class="icon-fa me-1" />{{ convertNumberToCommas(room.price) }} per {{ room.property_rent_type.type }}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-2 p-1">
                                      <!-- Actions -->
                                      <div class="me-1 d-inline">
                                        <button
                                            data-bs-toggle="modal"
                                            data-bs-target="#addRoomModal"
                                            @click="editRoom(room)"
                                            class="edit-btn d-inline"
                                            :disabled="processing"
                                        >
                                          <font-awesome-icon icon="pencil-alt" class="" />
                                        </button>
                                      </div>
                                      <div class="d-inline">
                                        <button :disabled="processing" @click="deleteRoom(room)" class="delete-btn d-inline">
                                          <font-awesome-icon icon="trash-alt" class="" />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- End Add Room Functionality -->

                      <div v-if="postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Studio Room -->
                            <div v-if="form.studio_room && postType" class="col-4 pt-2">
                              <div class="list-relative">
                                <span data-tooltip="tooltip">
                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">Studio Room</p>
                                    <font-awesome-icon icon="dice-d6" class="icon-fa min-width-10" />
                                    <font-awesome-icon icon="bed" class="icon-fa min-width-20" />
                                    <span class="tooltiptext">Studio flat got one room, kitchen and living room assigned automatically</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div v-if="form.studio_room && postType" class="col-2">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  :disabled="form.studio_room === true"
                                  :value="1"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.studio_room, $event)"
                              >
                            </div>
                            <!-- Single Rooms -->
                            <div v-if="!form.studio_room && !form.is_short_term && !form.is_room" class="col-4 pt-2">
                              <div class="list-relative">
                                <span data-tooltip="tooltip">
                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">Single Rooms</p>
                                    <font-awesome-icon icon="dice-one" class="icon-fa" />
                                    <font-awesome-icon icon="bed" class="icon-fa" />
                                    <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many single rooms are you advertising?</span>
                                    <span v-else class="tooltiptext">How many single rooms are you looking for?</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div v-if="!form.studio_room && !form.is_short_term && !form.is_room" class="col-2">
                              <ValidationProvider vid="vp-10" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  :disabled="form.studio_room === true"
                                  v-model="form.single_room"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.single_room, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <!-- Double Rooms -->
                            <div v-if="!form.studio_room && !form.is_short_term && !form.is_room" class="col-4 pt-2">
                              <div class="list-relative">
                                <span data-tooltip="tooltip">
                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">Double Rooms</p>
                                    <font-awesome-icon icon="dice-two" class="icon-fa" />
                                    <font-awesome-icon icon="bed" class="icon-fa" />
                                    <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many double rooms are you advertising?</span>
                                    <span v-else class="tooltiptext">How many double rooms are you looking for?</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div v-if="!form.studio_room && !form.is_short_term && !form.is_room" class="col-2">
                              <ValidationProvider vid="vp-11" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.double_room"
                                  :disabled="form.studio_room === true"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.double_room, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.single_room" class="required-info text-center">
                          {{ validationErrors.single_room[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.double_room" class="required-info text-center">
                          {{ validationErrors.double_room[0] }}
                        </p>
                      </div>

                      <div v-if="postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Living Rooms -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Living Rooms</p>
                                  <font-awesome-icon icon="couch" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many living rooms are you advertising?</span>
                                  <span v-else class="tooltiptext">How many living rooms are you looking for?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-12" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.living_room"
                                  :disabled="form.studio_room === true"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.living_room, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <!-- Dining Rooms -->
                            <div v-if="!form.studio_room" class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Dining Rooms</p>
                                  <font-awesome-icon icon="utensils" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many dining rooms are you advertising?</span>
                                  <span v-else class="tooltiptext">How many dining rooms are you looking for?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div v-if="!form.studio_room" class="col-2">
                              <ValidationProvider vid="vp-13" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.dining_room"
                                  :disabled="form.studio_room === true"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.dining_room, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.living_room" class="required-info text-center">
                          {{ validationErrors.living_room[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.dining_room" class="required-info text-center">
                          {{ validationErrors.dining_room[0] }}
                        </p>
                      </div>

                      <div v-if="postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Box Rooms -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Box Rooms</p>
                                  <font-awesome-icon icon="boxes" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many box rooms are you advertising?</span>
                                  <span v-else class="tooltiptext">How many box rooms are you looking for?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-14" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.box_room"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.box_room, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.box_room" class="required-info text-center">
                          {{ validationErrors.box_room[0] }}
                        </p>
                      </div>
                      <!-- End Rooms input fields -->

                      <div v-if="postType" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Kitchen</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- Start Kitchen input fields -->
                      <div v-if="postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Kitchen -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Kitchen</p>
                                  <font-awesome-icon icon="fire-alt" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many kitchens are you advertising?</span>
                                  <span v-else class="tooltiptext">How many kitchens are you looking for?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-15" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.kitchen"
                                  :disabled="form.studio_room === true"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.kitchen, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <!-- Oven -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Oven</p>
                                  <font-awesome-icon icon="burn" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there oven in the kitchen?</span>
                                  <span v-else class="tooltiptext">Do you require oven in the kitchen?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2 pt-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.oven">
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <!-- Fridge -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Fridge</p>
                                  <font-awesome-icon icon="hamburger" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there fridge in the kitchen?</span>
                                  <span v-else class="tooltiptext">Do you require fridge in the kitchen?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2 pt-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.fridge">
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <!-- Freezer -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Freezer</p>
                                  <font-awesome-icon icon="snowflake" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there freezer in the kitchen?</span>
                                  <span v-else class="tooltiptext">Do you require freezer in the kitchen?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2 pt-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.freezer">
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <!-- Microwave -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Microwave</p>
                                  <font-awesome-icon icon="pager" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there microwave in the kitchen?</span>
                                  <span v-else class="tooltiptext">Do you require microwave in the kitchen?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2 pt-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.microwave">
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <!-- Kettle -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Kettle</p>
                                  <font-awesome-icon icon="mug-hot" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there kettle in the kitchen?</span>
                                  <span v-else class="tooltiptext">Do you require kettle in the kitchen?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2 pt-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.kettle">
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <!-- Toaster -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Toaster</p>
                                  <font-awesome-icon icon="bread-slice" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there toaster in the kitchen?</span>
                                  <span v-else class="tooltiptext">Do you require toaster in the kitchen?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2 pt-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.toaster">
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <!-- Coffee -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Coffee</p>
                                  <font-awesome-icon icon="coffee" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there coffee in the kitchen?</span>
                                  <span v-else class="tooltiptext">Do you require coffee in the kitchen?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2 pt-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.coffee">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.kitchen" class="required-info text-center">
                          {{ validationErrors.kitchen[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.oven" class="required-info text-center">
                          {{ validationErrors.oven[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.fridge" class="required-info text-center">
                          {{ validationErrors.fridge[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.freezer" class="required-info text-center">
                          {{ validationErrors.freezer[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.microwave" class="required-info text-center">
                          {{ validationErrors.microwave[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.kettle" class="required-info text-center">
                          {{ validationErrors.kettle[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.toaster" class="required-info text-center">
                          {{ validationErrors.toaster[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.coffee" class="required-info text-center">
                          {{ validationErrors.coffee[0] }}
                        </p>
                      </div>
                      <!-- End Kitchen input fields -->

                      <div v-if="postType" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Bathroom</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Bathrooms -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Bathrooms</p>
                                  <font-awesome-icon icon="bath" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many bathrooms are you advertising?</span>
                                  <span v-else class="tooltiptext">How many bathrooms are you looking for?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-16" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.bath_room"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.bath_room, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <!-- Shower -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Shower</p>
                                  <font-awesome-icon icon="shower" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many showers are you advertising?</span>
                                  <span v-else class="tooltiptext">How many showers are you looking for?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-17" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.shower"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.shower, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.bath_room" class="required-info text-center">
                          {{ validationErrors.bath_room[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.shower" class="required-info text-center">
                          {{ validationErrors.shower[0] }}
                        </p>
                      </div>

                      <div v-if="postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- WC -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">WC</p>
                                  <font-awesome-icon icon="toilet" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many WC are you advertising?</span>
                                  <span v-else class="tooltiptext">How many WC are you looking for?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-18" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.wc"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.wc, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <!-- Bath -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Bath</p>
                                  <font-awesome-icon icon="bath" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many baths are you advertising?</span>
                                  <span v-else class="tooltiptext">How many baths are you looking for?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-19" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.bath"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.bath, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.wc" class="required-info text-center">
                          {{ validationErrors.wc[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.bath" class="required-info text-center">
                          {{ validationErrors.bath[0] }}
                        </p>
                      </div>

                      <div v-if="postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Downstairs WC -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Downstairs WC</p>
                                  <font-awesome-icon icon="toilet" class="icon-fa" />
                                  <font-awesome-icon icon="hand-point-down" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many downstairs WC are you advertising?</span>
                                  <span v-else class="tooltiptext">How many downstairs WC are you looking for?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-20" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.downstairs_wc"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.downstairs_wc, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <!-- Sink -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Sink</p>
                                  <font-awesome-icon icon="sink" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many sinks are you advertising?</span>
                                  <span v-else class="tooltiptext">How many sinks are you looking for?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-21" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.sink"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.sink, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.downstairs_wc" class="required-info text-center">
                          {{ validationErrors.downstairs_wc[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.sink" class="required-info text-center">
                          {{ validationErrors.sink[0] }}
                        </p>
                      </div>

                      <div v-if="postType" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Structural</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Front Garden -->
                            <div class="col-4">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Front Garden</p>
                                  <font-awesome-icon icon="leaf" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there front garden on your premises?</span>
                                  <span v-else class="tooltiptext">Are you looking to have front garden on the premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.front_garden">
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <!-- Garden Terrace -->
                            <div class="col-4">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Garden Terrace</p>
                                  <font-awesome-icon icon="ruler-horizontal" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there garden terrace on your premises?</span>
                                  <span v-else class="tooltiptext">Are you looking to have garden terrace on the premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.garden_terrace">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Rear Garden -->
                            <div class="col-4">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Rear Garden</p>
                                  <font-awesome-icon icon="tree" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there rear garden on your premises?</span>
                                  <span v-else class="tooltiptext">Are you looking to have rear garden on the premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.rear_garden">
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <!-- Conservatory -->
                            <div class="col-4">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Conservatory</p>
                                  <font-awesome-icon icon="store-alt" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there conservatory on your premises?</span>
                                  <span v-else class="tooltiptext">Are you looking to have conservatory on the premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.conservatory">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="checkShortTermOrRoom && postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Green House -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Green House</p>
                                  <font-awesome-icon icon="border-none" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many green houses are you advertising?</span>
                                  <span v-else class="tooltiptext">Are you looking to have green houses on the premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-22" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.green_house"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.green_house, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <!-- Shed -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Shed</p>
                                  <font-awesome-icon icon="box-open" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many sheds are you advertising?</span>
                                  <span v-else class="tooltiptext">Are you looking to have sheds on the premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-23" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.shed"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.shed, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.green_house" class="required-info text-center">
                          {{ validationErrors.green_house[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.shed" class="required-info text-center">
                          {{ validationErrors.shed[0] }}
                        </p>
                      </div>

                      <!-- Start Horizontal Line with Icon -->
                      <div v-if="postType" class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <div v-if="checkShortTermOrRoom && postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Front Porch -->
                            <div class="col-4">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Front Porch</p>
                                  <font-awesome-icon icon="door-closed" class="icon-fa" />
                                  <font-awesome-icon icon="level-down-alt" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there front porch on your premises?</span>
                                  <span v-else class="tooltiptext">Are you looking to have front porch on the premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.front_porch">
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <!-- Attic / Loft -->
                            <div class="col-4">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Attic/Loft</p>
                                  <font-awesome-icon icon="caret-square-up" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there attic/loft on your premises?</span>
                                  <span v-else class="tooltiptext">Are you looking to have attic/loft on the premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.attic">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="checkShortTermOrRoom && postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Rear Porch -->
                            <div class="col-4">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Rear Porch</p>
                                  <font-awesome-icon icon="door-closed" class="icon-fa" />
                                  <font-awesome-icon icon="level-up-alt" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there rear porch on your premises?</span>
                                  <span v-else class="tooltiptext">Are you looking to have rear porch on the premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.rear_porch">
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <!-- Basement -->
                            <div class="col-4">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Basement</p>
                                  <font-awesome-icon icon="caret-square-down" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there basement on your premises?</span>
                                  <span v-else class="tooltiptext">Are you looking to have basement on the premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.basement">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="checkShortTermOrRoom && postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Charging Station -->
                            <div class="col-4">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Charging Station</p>
                                  <font-awesome-icon icon="charging-station" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there charging station on your premises?</span>
                                  <span v-else class="tooltiptext">Are you looking to have charging station on the premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.charging_station">
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <!-- Solar Panels -->
                            <div class="col-4">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Solar Panels</p>
                                  <font-awesome-icon icon="solar-panel" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there solar panels on your premises?</span>
                                  <span v-else class="tooltiptext">Are you looking to have solar panels on the premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.solar_panels">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="checkShortTermOrRoom && postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Balcony -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Balcony</p>
                                  <font-awesome-icon icon="chess-rook" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there balcony on your premises?</span>
                                  <span v-else class="tooltiptext">Are you looking to have balcony on the premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2 pt-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.balcony">
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <!-- Floors -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Floors</p>
                                  <font-awesome-icon icon="layer-group" class="icon-fa" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many floors compromise your property?</span>
                                  <span v-else class="tooltiptext">How many floors you are looking for?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-24" rules="min_value:0|max_value:999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="999"
                                  v-model="form.floors"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.floors, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.floors" class="required-info text-center">
                          {{ validationErrors.floors[0] }}
                        </p>
                      </div>

                      <div v-if="checkShortTermOrRoom && postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Driveway -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Driveway</p>
                                  <font-awesome-icon icon="car" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many driveways are you advertising?</span>
                                  <span v-else class="tooltiptext">How many driveways you are looking for?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-25" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.driveway"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.driveway, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <!-- Garage -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Garage</p>
                                  <font-awesome-icon icon="warehouse" />
                                  <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many garages are you advertising?</span>
                                  <span v-else class="tooltiptext">How many garages you are looking for?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-26" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.garage"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.garage, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.driveway" class="required-info text-center">
                          {{ validationErrors.driveway[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.garage" class="required-info text-center">
                          {{ validationErrors.garage[0] }}
                        </p>
                      </div>

                      <!-- Start Parking and Parking Spaces for short-term and rooms -->
                      <div v-if="!checkShortTermOrRoom && postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Parking -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Parking</p>
                                  <font-awesome-icon icon="car" />
                                  <span class="tooltiptext">Is there parking on your premises?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2 pt-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.parking">
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <!-- Parking Paid -->
                            <div v-if="form.parking" class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">{{ form.parking_paid ? 'Status: Paid' : 'Status: Free'}}</p>
                                  <font-awesome-icon icon="pound-sign" />
                                  <span class="tooltiptext">Is parking free or paid?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div v-if="form.parking" class="col-2 pt-2">
                              <label class="switch">
                                <input type="checkbox" v-model="form.parking_paid">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <div v-if="form.parking" class="row m-0 p-0">
                            <!-- Parking Spaces -->
                            <div class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Parking Spaces</p>
                                  <font-awesome-icon icon="parking" />
                                  <span class="tooltiptext">How many parking spaces are you advertising?</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div class="col-2">
                              <ValidationProvider vid="vp-27" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="..."
                                  min="0"
                                  max="9999"
                                  v-model="form.parking_spaces"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.parking_spaces, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <!-- Parking Fee -->
                            <div v-if="form.parking && form.parking_paid" class="col-4 pt-2">
                              <div class="list-relative">
                              <span data-tooltip="tooltip">
                                <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                  <p class="options-paragraph m-0 p-0 me-2 d-inline">Parking Fee</p>
                                  <font-awesome-icon icon="parking" class="icon-fa me-1" />
                                  <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                  <span class="tooltiptext">How much parking fee would you like to charge? (This WILL NOT be added to total price on guest bookings, you need to charge guest yourself)</span>
                                </div>
                              </span>
                              </div>
                            </div>
                            <div v-if="form.parking && form.parking_paid" class="col-2">
                              <ValidationProvider vid="vp-28" rules="min_value:1|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="£"
                                  min="0"
                                  max="9999"
                                  v-model="form.parking_fee"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.parking_fee, $event)"
                              >
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.driveway" class="required-info text-center">
                          {{ validationErrors.driveway[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.garage" class="required-info text-center">
                          {{ validationErrors.garage[0] }}
                        </p>
                      </div>
                      <!-- End Parking and Parking Spaces for short-term and rooms -->

                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <!-- Start House Rules -->
                      <div v-if="postType === 3 && form.is_short_term" class="form-group col-12 text-center">
<!--                        &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
<!--                        <div class="horizontal-separator-box">-->
<!--                          <span class="hr-line-short"></span>-->
<!--                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
<!--                          <span class="hr-line-short"></span>-->
<!--                        </div>-->
<!--                        &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->

                        <!-- Information -->
                        <small class="p-3 fw-bold">House Rules</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="postType === 3 && form.is_short_term" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1 text-start">
                          <div class="row m-0 p-0">
                            <div class="col-8 me-0 pe-0">
                              <ValidationProvider vid="vp-29" rules="min:1|max:200" v-slot="{ errors }">
                                <input type="text" name="rule" maxlength="200" v-model="form.rule" id="rule" placeholder="Enter rule" class="form-control">
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <div class="col-4 ms-0 ps-0">
                              <button class="btn btn-outline-primary btn-add w-100"
                                      type="button"
                                      @click="addRule()"
                              >
                                Add Rule <font-awesome-icon icon="plus-circle" class="icon-fa add-icon" />
                              </button>
                            </div>
                          </div>
                          <div class="row m-0 p-0">
                            <div class="col-12">
                              <ul class="list-unstyled">
                                <li class="rule-list mt-1" v-for="rule in form.house_rules">
                                  {{ rule.rule }}
                                  <!-- Actions -->
                                  <div class="float-end">
                                    <button :disabled="processing" @click="deleteRule(rule)" class="delete-btn">
                                      <font-awesome-icon icon="trash-alt" class="" />
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Start Horizontal Line with Icon -->
                      <div v-if="postType === 3 && form.is_short_term" class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <!-- End House Rules -->

                    </div>
                    <!-- End Premises Details Collapse Section -->
                  </div>

                </form>
                </ValidationObserver>

                <ValidationObserver vid="vo-4" ref="premisesStatusFormObserver" v-slot="{ handleSubmit }">
                <form action="javascript:void(0)" onsubmit="return false;" class="row" method="post" enctype="multipart/form-data" id="premisesStatusForm">

                  <div v-if="postType" class="form-group col-12 mb-1 text-start">
                    <!--                      &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
                    <!--                      <div class="horizontal-separator-box">-->
                    <!--                        <span class="hr-line-short"></span>-->
                    <!--                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
                    <!--                        <span class="hr-line-short"></span>-->
                    <!--                      </div>-->
                    <!--                      &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->

                    <!-- Information -->
                    <button class="section-btn collapsed" @click.prevent="scrollToView('premisesStatus')" style="width: 100%;" type="button" data-bs-toggle="collapse" data-bs-target="#premisesStatus" aria-expanded="false" aria-controls="premisesStatus">
                      <small class="p-3 fw-bold">Premises Status</small>
                      <span :class="checkPremisesStatusValidation ? 'check-no-validation-error check-validation-error' : 'check-no-validation-error'">
                          <font-awesome-icon icon="asterisk" class="icon-fa"/>
                      </span>
                      <span :class="premisesStatusValid ? 'check-not-filled check-filled' : 'check-not-filled'">
                          <font-awesome-icon icon="check-circle" class="icon-fa"/>
                      </span>
                    </button>

                    <!--                      &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
                    <!--                      <div class="horizontal-separator-box">-->
                    <!--                        <span class="hr-line-short"></span>-->
                    <!--                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
                    <!--                        <span class="hr-line-short"></span>-->
                    <!--                      </div>-->
                    <!--                      &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->
                  </div>

                  <div v-if="postType" class="col-12 text-start mb-1 p-0">

                    <div class="collapse" id="premisesStatus" data-bs-parent="#premisesGroup">
                      <!-- Start Premises Status Collapse Section -->

                      <div v-if="postType" class="form-group col-12 text-start">
                        <div v-if="postType && postType !== 4 && !form.is_short_term" class="form-group col-12 text-center">
                          <!-- Start Horizontal Line with Icon -->
                          <div class="horizontal-separator-box">
                            <span class="hr-line-short"></span>
                            <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                            <span class="hr-line-short"></span>
                          </div>
                          <!-- End Horizontal Line with Icon -->

                          <!-- Information -->
                          <small v-if="postType && postType !== 4 && !form.is_short_term" class="p-3 fw-bold">Listing Status</small>

                          <!-- Start Horizontal Line with Icon -->
                          <div v-if="postType && postType !== 4 && !form.is_short_term" class="horizontal-separator-box">
                            <span class="hr-line-short"></span>
                            <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                            <span class="hr-line-short"></span>
                          </div>
                          <!-- End Horizontal Line with Icon -->
                        </div>

                        <!-- Cash or Auction -->
                        <!-- postType 3 = Let; postType 4 = Rent -->
                        <div v-if="postType && (postType !== 3 && postType !== 4)" class="row m-0 p-0">
                          <div class="col-12 p-0 mt-1 mb-1">
                            <div class="row m-0 p-0">
                              <!-- Cash Only -->
                              <div class="col-6 p-0">
                                <div class="row m-0 p-0">
                                  <div class="col-8">
                                    <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Cash Only</p>
                                        <font-awesome-icon icon="coins" class="icon-fa" />
                                        <span class="tooltiptext">Is it cash only purchase/sale?</span>
                                      </div>
                                    </span>
                                    </div>
                                  </div>
                                  <div class="col-4">
                                    <label class="switch">
                                      <input type="checkbox" v-model="form.is_cash">
                                      <span class="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <!-- Auction -->
                              <div class="col-6 p-0">
                                <div class="row m-0 p-0">
                                  <div class="col-8">
                                    <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Auction</p>
                                        <font-awesome-icon icon="gavel" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is it auction?</span>
                                        <span v-else class="tooltiptext">Are you looking for auction?</span>
                                      </div>
                                    </span>
                                    </div>
                                  </div>
                                  <div class="col-4">
                                    <label class="switch">
                                      <input type="checkbox" v-model="form.is_auction">
                                      <span class="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Coming Soon -->
                        <!-- postType 1 = Sale; postType 3 = Let -->
                        <div v-if="postType && (postType !== 4)" class="row m-0 p-0">
                          <div class="col-12 p-0 mt-1 mb-1">
                            <div class="row m-0 p-0">
                              <!-- Is Coming Soon -->
                              <div v-if="postType && (postType == 1 || postType == 3) && !form.is_short_term" class="col-6 p-0">
                                <div class="row m-0 p-0">
                                  <div class="col-8">
                                    <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Coming soon</p>
                                        <font-awesome-icon icon="clock" class="icon-fa" />
                                        <span class="tooltiptext">Is this listing 'coming soon' in terms of availability?</span>
                                      </div>
                                    </span>
                                    </div>
                                  </div>
                                  <div class="col-4">
                                    <label class="switch">
                                      <input type="checkbox" v-model="form.is_coming_soon">
                                      <span class="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <!-- Mixed-use Property -->
                              <div v-if="postType && (postType !== 3 && postType !== 4)" class="col-6 p-0">
                                <div class="row m-0 p-0">
                                  <div class="col-8">
                                    <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Mixed use</p>
                                        <font-awesome-icon icon="balance-scale" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is it mixed use property?</span>
                                        <span v-else class="tooltiptext">Are you looking for mixed use property?</span>
                                      </div>
                                    </span>
                                    </div>
                                  </div>
                                  <div class="col-4">
                                    <label class="switch">
                                      <input type="checkbox" v-model="form.is_mixed_use">
                                      <span class="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Investment Properties -->
                        <!-- postType 3 = Let; postType 4 = Rent -->
                        <div v-if="postType && (postType !== 3 && postType !== 4)" class="row m-0 p-0">
                          <div class="col-12 p-0 mt-1 mb-1">
                            <div class="row m-0 p-0">
                              <!-- Investment -->
                              <div class="col-6 p-0">
                                <div class="row m-0 p-0">
                                  <div class="col-8">
                                    <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Investment</p>
                                        <font-awesome-icon icon="chart-line" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is it investment property?</span>
                                        <span v-else class="tooltiptext">Are you looking for investment property?</span>
                                      </div>
                                    </span>
                                    </div>
                                  </div>
                                  <div class="col-4">
                                    <label class="switch">
                                      <input type="checkbox" v-model="form.is_investment">
                                      <span class="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Additional stuff if investment -->
                        <div v-if="postType && form.is_investment" class="row m-0 p-0">
                          <div class="col-12 p-0 mt-1 mb-1">
                            <div class="row m-0 p-0">
                              <!-- ROI - Return on Investment -->
                              <div class="col-4 pt-2">
                                <div class="list-relative">
                                  <span data-tooltip="tooltip">
                                    <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <p class="options-paragraph m-0 p-0 me-2 d-inline">ROI</p>
                                      <font-awesome-icon icon="chart-bar" class="icon-fa" />
                                      <span class="tooltiptext">
                                        Return on investment (ROI) is a performance measure used
                                        to evaluate the efficiency or profitability of an investment or compare the
                                        efficiency of a number of different investments.
                                      </span>
                                    </div>
                                  </span>
                                </div>
                              </div>
                              <div class="col-2">
                                <ValidationProvider vid="vp-30" rules="min_value:0|max_value:999" v-slot="{ errors }">
                                <input
                                    type="number"
                                    placeholder="%"
                                    min="0"
                                    max="999"
                                    v-model="form.roi"
                                    class="options-input options-price-input select-placeholder"
                                    @focus="handleFocus($event)"
                                    @focusout="handleFocusOut(form.roi, $event)"
                                >
                                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                                </ValidationProvider>
                              </div>
                              <!-- ROA - Return on Assets -->
                              <div class="col-4 pt-2">
                                <div class="list-relative">
                                  <span data-tooltip="tooltip">
                                    <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <p class="options-paragraph m-0 p-0 me-2 d-inline">ROA</p>
                                      <font-awesome-icon icon="chart-pie" class="icon-fa" /> <!-- I need icon -->
                                      <span class="tooltiptext">
                                        Return on assets (ROA) is an indicator of how profitable a company is relative to its total assets.
                                      </span>
                                    </div>
                                  </span>
                                </div>
                              </div>
                              <div class="col-2">
                                <ValidationProvider vid="vp-31" rules="min_value:0|max_value:999" v-slot="{ errors }">
                                <input
                                    type="number"
                                    placeholder="%"
                                    min="0"
                                    max="999"
                                    v-model="form.roa"
                                    class="options-input options-price-input select-placeholder"
                                    @focus="handleFocus($event)"
                                    @focusout="handleFocusOut(form.roa, $event)"
                                >
                                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.roi" class="required-info text-center">
                            {{ validationErrors.roi[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.roa" class="required-info text-center">
                            {{ validationErrors.roa[0] }}
                          </p>
                        </div>

                        <div v-if="postType && form.is_investment" class="row m-0 p-0">
                          <div class="col-12 p-0 mt-1 mb-1">
                            <div class="row m-0 p-0">
                              <!-- Yield -->
                              <div class="col-4 pt-2">
                                <div class="list-relative">
                                  <span data-tooltip="tooltip">
                                    <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <p class="options-paragraph m-0 p-0 me-2 d-inline">Yield</p>
                                      <font-awesome-icon icon="calculator" class="icon-fa" />
                                      <span class="tooltiptext">
                                        Yield refers to the earnings generated and realized on an investment over a
                                        particular period of time. It's expressed as a percentage based on the invested
                                        amount, current market value, or face value of the security.
                                      </span>
                                    </div>
                                  </span>
                                </div>
                              </div>
                              <div class="col-2">
                                <ValidationProvider vid="vp-32" rules="min_value:0|max_value:999" v-slot="{ errors }">
                                <input
                                    type="number"
                                    placeholder="%"
                                    min="0"
                                    max="999"
                                    v-model="form.yield"
                                    class="options-input options-price-input select-placeholder"
                                    @focus="handleFocus($event)"
                                    @focusout="handleFocusOut(form.yield, $event)"
                                >
                                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                                </ValidationProvider>
                              </div>
                              <!-- Share Percentage -->
                              <div class="col-4 pt-2">
                                <div class="list-relative">
                                  <span data-tooltip="tooltip">
                                    <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <p class="options-paragraph m-0 p-0 me-2 d-inline">Share Percentage</p>
                                      <font-awesome-icon icon="handshake" class="icon-fa" />
                                      <font-awesome-icon icon="percentage" class="icon-fa" />
                                      <span class="tooltiptext">
                                        Share Percentage means the percentage obtained by dividing
                                        the aggregate value of the Share Consideration (determined by reference to the Closing Share Price)
                                        by the Aggregate Consideration Value.
                                      </span>
                                    </div>
                                  </span>
                                </div>
                              </div>
                              <div class="col-2">
                                <ValidationProvider vid="vp-33" rules="min_value:0|max_value:999" v-slot="{ errors }">
                                <input
                                    type="number"
                                    placeholder="%"
                                    min="0"
                                    max="999"
                                    v-model="form.share_percentage"
                                    class="options-input options-price-input select-placeholder"
                                    @focus="handleFocus($event)"
                                    @focusout="handleFocusOut(form.share_percentage, $event)"
                                >
                                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.yield" class="required-info text-center">
                            {{ validationErrors.yield[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.share_percentage" class="required-info text-center">
                            {{ validationErrors.share_percentage[0] }}
                          </p>
                        </div>

                        <div v-if="postType && form.is_investment" class="row m-0 p-0">
                          <div class="col-12 p-0 mt-1 mb-1">
                            <div class="row m-0 p-0">
                              <!-- Cash Flow -->
                              <div class="col-4 pt-2">
                                <div class="list-relative">
                                  <span data-tooltip="tooltip">
                                    <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <p class="options-paragraph m-0 p-0 me-2 d-inline">Cash Flow</p>
                                      <font-awesome-icon icon="money-bill-wave" class="icon-fa" />
                                      <span class="tooltiptext">
                                        Cash flow refers to the net balance of cash moving into and out of a business
                                        at a specific point in time. Cash flow can be positive or negative.
                                      </span>
                                    </div>
                                  </span>
                                </div>
                              </div>
                              <div class="col-8">
                                <ValidationProvider vid="vp-34" rules="min_value:0|max_value:999999999" v-slot="{ errors }">
                                <input
                                    type="number"
                                    placeholder="£"
                                    min="0"
                                    max="999999999"
                                    v-model="form.cash_flow"
                                    class="options-input options-price-input select-placeholder"
                                    @focus="handleFocus($event)"
                                    @focusout="handleFocusOut(form.cash_flow, $event)"
                                >
                                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.cash_flow" class="required-info text-center">
                            {{ validationErrors.cash_flow[0] }}
                          </p>
                        </div>

                        <div v-if="postType && form.is_investment" class="row m-0 p-0">
                          <div class="col-12 p-0 mt-1 mb-1">
                            <div class="row m-0 p-0">
                              <!-- Maintenance Cost -->
                              <div class="col-4 pt-2">
                                <div class="list-relative">
                                  <span data-tooltip="tooltip">
                                    <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <p class="options-paragraph m-0 p-0 me-2 d-inline">Maintenance Cost</p>
                                      <font-awesome-icon icon="wallet" class="icon-fa" />
                                      <span class="tooltiptext">
                                        The term maintenance expense refers to any cost incurred by an individual or business to keep their assets in good working condition.
                                      </span>
                                    </div>
                                  </span>
                                </div>
                              </div>
                              <div class="col-5">
                                <ValidationProvider vid="vp-35" rules="min_value:0|max_value:999999999" v-slot="{ errors }">
                                <input
                                    type="number"
                                    placeholder="£"
                                    min="0"
                                    max="999999999"
                                    v-model="form.maintenance_cost"
                                    class="options-input options-price-input select-placeholder"
                                    @focus="handleFocus($event)"
                                    @focusout="handleFocusOut(form.maintenance_cost, $event)"
                                >
                                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                                </ValidationProvider>
                              </div>
                              <div class="col-3 pt-2 options-paragraph">
                                per year
                              </div>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.maintenance_cost" class="required-info text-center">
                            {{ validationErrors.maintenance_cost[0] }}
                          </p>
                        </div>

                        <div v-if="postType && form.is_investment" class="row m-0 p-0">
                          <div class="col-12 p-0 mt-1 mb-1">
                            <div class="row m-0 p-0">
                              <!-- Number of Flats -->
                              <div class="col-4 pt-2">
                                <div class="list-relative">
                                  <span data-tooltip="tooltip">
                                    <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <p class="options-paragraph m-0 p-0 me-2 d-inline">Number of Flats</p>
                                      <font-awesome-icon icon="building" class="icon-fa" />
                                      <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many flats are you advertising?</span>
                                      <span v-else class="tooltiptext">How many flats are you looking for?</span>
                                    </div>
                                  </span>
                                </div>
                              </div>
                              <div class="col-2">
                                <ValidationProvider vid="vp-36" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                                <input
                                    type="number"
                                    placeholder="..."
                                    min="0"
                                    max="9999"
                                    v-model="form.number_of_flats"
                                    class="options-input options-price-input select-placeholder"
                                    @focus="handleFocus($event)"
                                    @focusout="handleFocusOut(form.number_of_flats, $event)"
                                >
                                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                                </ValidationProvider>
                              </div>
                              <!-- Number of Blocks -->
                              <div class="col-4 pt-2">
                                <div class="list-relative">
                                  <span data-tooltip="tooltip">
                                    <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <p class="options-paragraph m-0 p-0 me-2 d-inline">Number of Blocks</p>
                                      <font-awesome-icon icon="th" class="icon-fa" />
                                      <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many blocks are you advertising?</span>
                                      <span v-else class="tooltiptext">How many blocks are you looking for?</span>
                                    </div>
                                  </span>
                                </div>
                              </div>
                              <div class="col-2">
                                <ValidationProvider vid="vp-37" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                                <input
                                    type="number"
                                    placeholder="..."
                                    min="0"
                                    max="9999"
                                    v-model="form.number_of_blocks"
                                    class="options-input options-price-input select-placeholder"
                                    @focus="handleFocus($event)"
                                    @focusout="handleFocusOut(form.number_of_blocks, $event)"
                                >
                                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.number_of_flats" class="required-info text-center">
                            {{ validationErrors.number_of_flats[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.number_of_blocks" class="required-info text-center">
                            {{ validationErrors.number_of_blocks[0] }}
                          </p>
                        </div>

                        <div v-if="postType && form.is_investment" class="row m-0 p-0">
                          <div class="col-12 p-0 mt-1 mb-1">
                            <div class="row m-0 p-0">
                              <!-- Number of Units -->
                              <div class="col-4 pt-2">
                                <div class="list-relative">
                                  <span data-tooltip="tooltip">
                                    <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                      <p class="options-paragraph m-0 p-0 me-2 d-inline">Number of Units</p>
                                      <font-awesome-icon icon="stream" class="icon-fa" />
                                      <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">How many units are you advertising?</span>
                                      <span v-else class="tooltiptext">How many units are you looking for?</span>
                                    </div>
                                  </span>
                                </div>
                              </div>
                              <div class="col-2">
                                <ValidationProvider vid="vp-38" rules="min_value:0|max_value:9999" v-slot="{ errors }">
                                <input
                                    type="number"
                                    placeholder="..."
                                    min="0"
                                    max="9999"
                                    v-model="form.number_of_units"
                                    class="options-input options-price-input select-placeholder"
                                    @focus="handleFocus($event)"
                                    @focusout="handleFocusOut(form.number_of_units, $event)"
                                >
                                  <span class="red-text"><small>{{ errors[0] }}</small></span>
                                </ValidationProvider>
                              </div>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.number_of_units" class="required-info text-center">
                            {{ validationErrors.number_of_units[0] }}
                          </p>
                        </div>
                      </div>

                      <div v-if="postType" class="text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Additional Info</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- START SWITCHES DETAILES -->
                      <div v-if="postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Accessible -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Accessible</p>
                                        <font-awesome-icon icon="wheelchair" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is your property adapted to people with disabilities?</span>
                                        <span v-else class="tooltiptext">Do you require property adapted to people with disabilities?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_accessible">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Shared Ownership - Dont show on 3 - LET -->
                            <div v-if="postType !== 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Shared Ownership</p>
                                        <font-awesome-icon icon="handshake" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is your property shared ownership?</span>
                                        <span v-else class="tooltiptext">Do you require shared ownership?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_shared_ownership">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Over 60's -->
                            <div v-if="!form.is_short_term || postType !== 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Over 60's</p>
                                          <font-awesome-icon icon="hand-holding-heart" class="icon-fa" />
                                          <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is your property only for over 60's?</span>
                                          <span v-else class="tooltiptext">Do you require property only for over 60's?</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_over_60s">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- HMO -->
                            <div v-if="!form.is_short_term || postType !== 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">HMO</p>
                                          <font-awesome-icon icon="users" class="icon-fa" />
                                          <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is your property classified as HMO (House in Multiple Occupation)?</span>
                                          <span v-else class="tooltiptext">Do you require property classified as HMO (House in Multiple Occupation)?</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_hmo">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Furnished -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Furnished</p>
                                          <font-awesome-icon icon="chair" class="icon-fa" />
                                          <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is your property furnished?</span>
                                          <span v-else class="tooltiptext">Do you require furnished property?</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_furnished">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Tenanted - Dont show on 3 - LET -->
                            <div v-if="postType !== 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Tenanted</p>
                                          <font-awesome-icon icon="house-user" class="icon-fa" />
                                          <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is your property occupied?</span>
                                          <span v-else class="tooltiptext">Do you require occupied property?</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_tenanted">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Pet Friendly -->
                            <div :class="form.is_pet_friendly && postType === 3 && form.is_short_term ? 'col-12 p-0 mt-1 mb-1' : 'col-6 p-0 mt-1 mb-1'">
                              <div class="row m-0 p-0">
                                <div :class="form.is_pet_friendly && postType === 3 && form.is_short_term ? 'col-4' : 'col-8'">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Pet Friendly</p>
                                          <font-awesome-icon icon="paw" class="icon-fa" />
                                          <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is your property pet friendly?</span>
                                          <span v-else class="tooltiptext">Do you require pet friendly property?</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div :class="form.is_pet_friendly && postType === 3 && form.is_short_term ? 'col-2' : 'col-4'">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_pet_friendly">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Pet Friendly Paid - Show on 3 - LET (short-term) and when pet friendly -->
                            <div v-if="postType === 3 && form.is_pet_friendly && form.is_short_term" :class="form.pet_friendly_paid ? 'col-6 p-0 mt-1 mb-1' : 'col-12 p-0 mt-1 mb-1'">
                              <div class="row m-0 p-0">
                                <div :class="form.pet_friendly_paid && postType === 3 ? 'col-8' : 'col-4'">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">{{ form.pet_friendly_paid ? 'Status: Paid' : 'Status: Free'}}</p>
                                          <font-awesome-icon icon="paw" class="icon-fa me-1" />
                                          <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                          <span class="tooltiptext">Is there any fee for pets?</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div :class="form.pet_friendly_paid && postType === 3 ? 'col-2' : 'col-4'">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.pet_friendly_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Pet Friendly Fee - Show on 3 - LET and when pet friendly -->
                            <div v-if="postType === 3 && form.is_pet_friendly && form.pet_friendly_paid && form.is_short_term" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Pet Fee</p>
                                          <font-awesome-icon icon="paw" class="icon-fa me-1" />
                                          <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                          <span class="required">*</span>
                                          <span class="tooltiptext">How much would you like to charge per pet per stay? (This will be added to total price on guest bookings)</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <ValidationProvider vid="vp-39" rules="required|min_value:1|max_value:9999" v-slot="{ errors }">
                                  <input
                                      type="number"
                                      placeholder="£"
                                      min="0"
                                      max="9999"
                                      v-model="form.pet_friendly_fee"
                                      class="options-input options-price-input select-placeholder"
                                      @focus="handleFocus($event)"
                                      @focusout="handleFocusOut(form.pet_friendly_fee, $event)"
                                  >
                                    <span class="red-text"><small>{{ errors[0] }}</small></span>
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>
                            <!-- Events Allowed -->
                            <div v-if="postType === 3 || postType === 4" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Events Allowed</p>
                                          <font-awesome-icon icon="music" class="icon-fa" />
                                          <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Do you allow organising events/parties?</span>
                                          <span v-else class="tooltiptext">Do you require permission to organize events/parties?</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.events_allowed">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- DSS Accepted -->
                            <div v-if="!form.is_short_term || postType !== 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">DSS Accepted</p>
                                          <font-awesome-icon icon="user-shield" class="icon-fa" />
                                          <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Do you accept DSS (Department of Social Security)?</span>
                                          <span v-else class="tooltiptext">Do you require DSS (Department of Social Security) property?</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_dss_accepted">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Easements and Covenants - Dont show on 3 - LET -->
                            <div v-if="postType !== 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Easements & Covenants</p>
                                          <font-awesome-icon icon="exclamation-circle" class="icon-fa" />
                                          <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Are there any easements or covenants on your property?</span>
                                          <span v-else class="tooltiptext">Do you require any easements or covenants on your property?</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.easements">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Holiday -->
                            <div v-if="form.is_short_term || postType !== 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Holiday</p>
                                          <font-awesome-icon icon="plane" class="icon-fa" />
                                          <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is it holiday property?</span>
                                          <span v-else class="tooltiptext">Do you require holiday property?</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_holiday">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Seashore -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Seashore</p>
                                          <font-awesome-icon icon="water" class="icon-fa" />
                                          <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is you property located near seashore?</span>
                                          <span v-else class="tooltiptext">Do you require property to be located near seashore?</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_seashore">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Waterfront -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Waterfront</p>
                                          <font-awesome-icon icon="home" class="icon-fa" />
                                          <font-awesome-icon icon="water" class="icon-fa" />
                                          <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is you property located waterfront?</span>
                                          <span v-else class="tooltiptext">Do you require property to be located waterfront?</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_waterfront">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- END SWITCHES DETAILES -->

                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                    </div>
                    <!-- End Premises Status Collapse Section -->
                  </div>

                </form>
                </ValidationObserver>

                <ValidationObserver vid="vo-5" ref="servicesAndAmenitiesFormObserver" v-slot="{ handleSubmit }">
                <form action="javascript:void(0)" onsubmit="return false;" class="row" method="post" enctype="multipart/form-data" id="servicesAndAmenitiesForm">

                  <div v-if="postType" class="form-group col-12 mb-1 text-start">
                    <!--                      &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
                    <!--                      <div class="horizontal-separator-box">-->
                    <!--                        <span class="hr-line-short"></span>-->
                    <!--                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
                    <!--                        <span class="hr-line-short"></span>-->
                    <!--                      </div>-->
                    <!--                      &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->

                    <!-- Information -->
                    <button class="section-btn collapsed" @click.prevent="scrollToView('servicesAndAmenities')" style="width: 100%;" type="button" data-bs-toggle="collapse" data-bs-target="#servicesAndAmenities" aria-expanded="false" aria-controls="servicesAndAmenities">
                      <small class="p-3 fw-bold">Services & Amenities</small>
                      <span :class="checkServicesAmenitiesValidation ? 'check-no-validation-error check-validation-error' : 'check-no-validation-error'">
                          <font-awesome-icon icon="asterisk" class="icon-fa"/>
                      </span>
                      <span :class="servicesAndAmenitiesValid ? 'check-not-filled check-filled' : 'check-not-filled'">
                          <font-awesome-icon icon="check-circle" class="icon-fa"/>
                      </span>
                    </button>

                    <!--                      &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
                    <!--                      <div class="horizontal-separator-box">-->
                    <!--                        <span class="hr-line-short"></span>-->
                    <!--                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
                    <!--                        <span class="hr-line-short"></span>-->
                    <!--                      </div>-->
                    <!--                      &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->
                  </div>

                  <div v-if="postType" class="col-12 text-start mb-1 p-0">

                    <div class="collapse" id="servicesAndAmenities" data-bs-parent="#premisesGroup">
                      <!-- Start Services & Amenities Collapse Section -->

                      <div v-if="postType" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Services & Amenities</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- START SWITCHES EXTRAS -->
                      <div v-if="postType" class="row m-0 p-0">
                        <div class="col-12 p-0 mt-1 mb-1">
                          <div class="row m-0 p-0">
                            <!-- Room Service -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Room Service</p>
                                        <font-awesome-icon icon="door-open" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Do you provide room service?</span>
                                        <span v-else class="tooltiptext">Do you require room service on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.room_service">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Room Service Paid - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.room_service ? (form.room_service_paid ? 'Status: Paid' : 'Status: Free') : 'Status: N/A'}}</p>
                                        <font-awesome-icon icon="door-open" class="icon-fa me-1" />
                                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                        <span class="tooltiptext">Is room service free or paid?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input :disabled="!form.room_service" type="checkbox" v-model="form.room_service_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Room Service Fee - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3 && form.room_service && form.room_service_paid" class="col-12 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-4">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Service Fee</p>
                                          <font-awesome-icon icon="door-open" class="icon-fa me-1" />
                                          <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                          <span class="required">*</span>
                                          <span class="tooltiptext">How much service fee would you like to charge (per stay per room)? (This will be added to total price on guest bookings)</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div class="col-2">
                                  <ValidationProvider vid="vp-40" rules="required|min_value:1|max_value:9999" v-slot="{ errors }">
                                  <input
                                      type="number"
                                      placeholder="£"
                                      min="0"
                                      max="9999"
                                      v-model="form.service_fee"
                                      class="options-input options-price-input select-placeholder"
                                      @focus="handleFocus($event)"
                                      @focusout="handleFocusOut(form.service_fee, $event)"
                                  >
                                    <span class="red-text"><small>{{ errors[0] }}</small></span>
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>
                            <!-- Cleaning Service -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Cleaning Service</p>
                                        <font-awesome-icon icon="hands-wash" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Do you provide cleaning service?</span>
                                        <span v-else class="tooltiptext">Do you require cleaning service on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.cleaning_service">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Cleaning Service Paid - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.cleaning_service ? (form.cleaning_service_paid ? 'Status: Paid' : 'Status: Free') : 'Status: N/A'}}</p>
                                        <font-awesome-icon icon="hands-wash" class="icon-fa me-1" />
                                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                        <span class="tooltiptext">Is cleaning service free or paid?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input :disabled="!form.cleaning_service" type="checkbox" v-model="form.cleaning_service_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Cleaning Service Fee - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3 && form.cleaning_service && form.cleaning_service_paid" class="col-12 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-4">
                                  <div class="list-relative">
                                      <span data-tooltip="tooltip">
                                        <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                          <p class="options-paragraph m-0 p-0 me-2 d-inline">Cleaning Fee</p>
                                          <font-awesome-icon icon="hands-wash" class="icon-fa me-1" />
                                          <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                          <span class="required">*</span>
                                          <span class="tooltiptext">How much cleaning fee would you like to charge (per stay per room)? (This will be added to total price on guest bookings)</span>
                                        </div>
                                      </span>
                                  </div>
                                </div>
                                <div class="col-2">
                                  <ValidationProvider vid="vp-41" rules="required|min_value:1|max_value:9999" v-slot="{ errors }">
                                  <input
                                      type="number"
                                      placeholder="£"
                                      min="0"
                                      max="9999"
                                      v-model="form.cleaning_fee"
                                      class="options-input options-price-input select-placeholder"
                                      @focus="handleFocus($event)"
                                      @focusout="handleFocusOut(form.cleaning_fee, $event)"
                                  >
                                    <span class="red-text"><small>{{ errors[0] }}</small></span>
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>
                            <!-- Wifi -->
                            <div v-if="postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Wi-Fi</p>
                                        <font-awesome-icon icon="wifi" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there Wi-Fi on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require Wi-Fi on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.wifi">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Wifi - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.wifi ? (form.wifi_paid ? 'Status: Paid' : 'Status: Free') : 'Status: N/A'}}</p>
                                        <font-awesome-icon icon="wifi" class="icon-fa me-1" />
                                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                        <span class="tooltiptext">Is Wi-Fi free or paid?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input :disabled="!form.wifi" type="checkbox" v-model="form.wifi_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Breakfast -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Breakfast</p>
                                        <font-awesome-icon icon="bread-slice" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there option to have breakfast?</span>
                                        <span v-else class="tooltiptext">Do you require option to have breakfast on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.breakfast">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Breakfast - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.breakfast ? (form.breakfast_paid ? 'Status: Paid' : 'Status: Free') : 'Status: N/A'}}</p>
                                        <font-awesome-icon icon="bread-slice" class="icon-fa me-1" />
                                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                        <span class="tooltiptext">Is breakfast free or paid?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input :disabled="!form.breakfast" type="checkbox" v-model="form.breakfast_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Inside Swimming Pool -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Inside Pool</p>
                                        <font-awesome-icon icon="swimmer" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there inside pool on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require inside pool on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.inside_pool">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Inside Swimming Pool Paid - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.inside_pool ? (form.inside_pool_paid ? 'Status: Paid' : 'Status: Free') : 'Status: N/A'}}</p>
                                        <font-awesome-icon icon="swimmer" class="icon-fa me-1" />
                                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                        <span class="tooltiptext">Is inside swimming pool free or paid?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input :disabled="!form.inside_pool" type="checkbox" v-model="form.inside_pool_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Outside Swimming Pool -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Outside Pool</p>
                                        <font-awesome-icon icon="swimming-pool" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there outside pool on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require outside pool on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.outside_pool">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Outside Swimming Pool Paid - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.outside_pool ? (form.outside_pool_paid ? 'Status: Paid' : 'Status: Free') : 'Status: N/A'}}</p>
                                        <font-awesome-icon icon="swimming-pool" class="icon-fa me-1" />
                                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                        <span class="tooltiptext">Is outside swimming pool free or paid?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input :disabled="!form.outside_pool" type="checkbox" v-model="form.outside_pool_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Cinema Room -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Cinema Room</p>
                                        <font-awesome-icon icon="film" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there cinema room on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require cinema room on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.cinema_room">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Cinema Room Paid - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.cinema_room ? (form.cinema_room_paid ? 'Status: Paid' : 'Status: Free') : 'Status: N/A'}}</p>
                                        <font-awesome-icon icon="film" class="icon-fa me-1" />
                                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                        <span class="tooltiptext">Is cinema room free or paid?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input :disabled="!form.cinema_room" type="checkbox" v-model="form.cinema_room_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Gym -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Gym</p>
                                        <font-awesome-icon icon="dumbbell" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there a gym on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require a gym on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.gym">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Gym - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.gym ? (form.gym_paid ? 'Status: Paid' : 'Status: Free') : 'Status: N/A'}}</p>
                                        <font-awesome-icon icon="dumbbell" class="icon-fa me-1" />
                                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                        <span class="tooltiptext">Is gym free or paid?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input :disabled="!form.gym" type="checkbox" v-model="form.gym_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Sauna -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Sauna</p>
                                        <font-awesome-icon icon="thermometer-full" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there sauna on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require sauna on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.sauna">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Sauna - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.sauna ? (form.sauna_paid ? 'Status: Paid' : 'Status: Free') : 'Status: N/A'}}</p>
                                        <font-awesome-icon icon="thermometer-full" class="icon-fa me-1" />
                                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                        <span class="tooltiptext">Is sauna free or paid?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input :disabled="!form.sauna" type="checkbox" v-model="form.sauna_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Hot Tub -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Hot Tub</p>
                                        <font-awesome-icon icon="hot-tub" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there hot tub on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require hot tub on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.hot_tub">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Hot Tub - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.hot_tub ? (form.hot_tub_paid ? 'Status: Paid' : 'Status: Free') : 'Status: N/A'}}</p>
                                        <font-awesome-icon icon="hot-tub" class="icon-fa me-1" />
                                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                        <span class="tooltiptext">Is hot tub free or paid?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input :disabled="!form.hot_tub" type="checkbox" v-model="form.hot_tub_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Spa -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Spa</p>
                                        <font-awesome-icon icon="spa" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there spa on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require spa on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.spa">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Spa - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.spa ? (form.spa_paid ? 'Status: Paid' : 'Status: Free') : 'Status: N/A'}}</p>
                                        <font-awesome-icon icon="spa" class="icon-fa me-1" />
                                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                        <span class="tooltiptext">Is spa free or paid?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input :disabled="!form.spa" type="checkbox" v-model="form.spa_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Bicycle -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Bicycle</p>
                                        <font-awesome-icon icon="bicycle" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there bicycle on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require bicycle on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.bicycle">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Bicycle - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.bicycle ? (form.bicycle_paid ? 'Status: Paid' : 'Status: Free') : 'Status: N/A'}}</p>
                                        <font-awesome-icon icon="bicycle" class="icon-fa me-1" />
                                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                        <span class="tooltiptext">Is bicycle free or paid?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input :disabled="!form.bicycle" type="checkbox" v-model="form.bicycle_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Fire Rings -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Fire Rings</p>
                                        <font-awesome-icon icon="fire" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Are there fire rings on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require fire rings on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.fire_rings">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Fire Rings Paid - Show Only on 3 - LET and short-term -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">{{form.fire_rings ? (form.fire_rings_paid ? 'Status: Paid' : 'Status: Free') : 'Status: N/A'}}</p>
                                        <font-awesome-icon icon="fire" class="icon-fa me-1" />
                                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                        <span class="tooltiptext">Are fire rings free or paid?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input :disabled="!form.fire_rings" type="checkbox" v-model="form.fire_rings_paid">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Bar -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Bar</p>
                                        <font-awesome-icon icon="cocktail" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there a bar on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require bar on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.bar">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Restaurant -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Restaurant</p>
                                        <font-awesome-icon icon="utensils" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there a restaurant on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require restaurant on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.restaurant">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- h24 Front Desk -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">24h Front Desk</p>
                                        <font-awesome-icon icon="concierge-bell" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Do you provide 24h front desk?</span>
                                        <span v-else class="tooltiptext">Do you require 24h front desk on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.h24_front_desk">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Airport Shuttle -->
                            <div v-if="form.is_short_term && postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Airport Shuttle</p>
                                        <font-awesome-icon icon="plane-arrival" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Do you provide airport shuttle service?</span>
                                        <span v-else class="tooltiptext">Do you require airport shuttle service?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.airport_shuttle">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Smoking Rooms -->
                            <div v-if="postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Smoking Rooms</p>
                                        <font-awesome-icon icon="smoking" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Do you have smoking rooms on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require smoking rooms on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.smoking_rooms">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Non Smoking Rooms -->
                            <div v-if="postType === 3" class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Non Smoking Rooms</p>
                                        <font-awesome-icon icon="smoking-ban" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Do you provide non smoking rooms on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require non smoking rooms on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.non_smoking_rooms">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Air Conditioning -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Air Conditioning</p>
                                        <font-awesome-icon icon="temperature-low" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there air conditioning on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require air conditioning on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.air_conditioning">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Stove -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Stove</p>
                                        <font-awesome-icon icon="fire" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Is there stove on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require stove on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.stove">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Hammocks -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Hammocks</p>
                                        <font-awesome-icon icon="feather" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Are there hammocks on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require hammocks on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.hammocks">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Security Cameras -->
                            <div class="col-6 p-0 mt-1 mb-1">
                              <div class="row m-0 p-0">
                                <div class="col-8">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Security Cameras</p>
                                        <font-awesome-icon icon="eye" class="icon-fa" />
                                        <span v-if="postType !== 2 && postType !== 4" class="tooltiptext">Are there security cameras on your premises?</span>
                                        <span v-else class="tooltiptext">Do you require security cameras on the premises?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.security_cameras">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- END SWITCHES EXTRAS -->

                      <!-- Start Horizontal Line with Icon -->
                      <div class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                    </div>
                    <!-- End Services & Amenities Collapse Section -->
                  </div>

                </form>
                </ValidationObserver>

                <ValidationObserver vid="vo-6" ref="availabilityFormObserver" v-slot="{ handleSubmit }">
                <form action="javascript:void(0)" onsubmit="return false;" class="row" method="post" enctype="multipart/form-data" id="availabilityForm">

                  <div v-if="postType === 3 || postType === 4" class="form-group col-12 mb-1 text-start">
                    <!--                      &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
                    <!--                      <div class="horizontal-separator-box">-->
                    <!--                        <span class="hr-line-short"></span>-->
                    <!--                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
                    <!--                        <span class="hr-line-short"></span>-->
                    <!--                      </div>-->
                    <!--                      &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->

                    <!-- Information -->
                    <button class="section-btn collapsed" @click.prevent="scrollToView('availabilityPolicies')" style="width: 100%;" type="button" data-bs-toggle="collapse" data-bs-target="#availabilityPolicies" aria-expanded="false" aria-controls="availabilityPolicies">
                      <small class="p-3 fw-bold">{{ form.is_short_term && postType === 3 ? 'Availability & Policies' : 'Availability'}}</small>
                      <span :class="checkAvailabilityValidation ? 'check-no-validation-error check-validation-error' : 'check-no-validation-error'">
                          <font-awesome-icon icon="asterisk" class="icon-fa"/>
                      </span>
                      <span :class="availabilityValid ? 'check-not-filled check-filled' : 'check-not-filled'">
                          <font-awesome-icon icon="check-circle" class="icon-fa"/>
                      </span>
                    </button>

                    <!--                      &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
                    <!--                      <div class="horizontal-separator-box">-->
                    <!--                        <span class="hr-line-short"></span>-->
                    <!--                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
                    <!--                        <span class="hr-line-short"></span>-->
                    <!--                      </div>-->
                    <!--                      &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->
                  </div>

                  <div v-if="postType === 3 || postType === 4" class="col-12 text-start mb-1 p-0">

                    <div class="collapse" id="availabilityPolicies" data-bs-parent="#premisesGroup">
                      <!-- Start Availability Collapse Section -->

                      <div v-if="postType" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Availability</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- postType 3 = Let; postType 4 = Rent -->
                      <div v-if="postType === 3 || postType === 4" class="form-group col-12 mt-3 mb-3 text-center">
                        <label for="calendar-dates" class="pb-2">{{!form.is_short_term ? (postType === 3 ? 'Availability' : 'Required Dates') : (postType === 3 ? 'Available From' : '')}}</label><span class="required"> *</span>
                        <br/>
                        <div v-if="!form.is_short_term" class="d-block">
                          <small>
                            By clicking on calendar dates, you can chose a date range when your premises are available. If you
                            do not want to specify 'end date' as it is a long-term let, toggle 'End date to be discussed' field,
                            this will allow you to pick only 'start date' and skip 'end date'.
                          </small>
                        </div>
                        <div v-else class="d-block">
                          <small>
                            By clicking on calendar, you can chose a date from which your premises are available.
                          </small>
                        </div>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <br/>
                        <div v-if="!form.is_short_term" class="row m-0 p-0 mb-2 text-start">
                          <div class="col-8 col-lg-7 offset-lg-1">
                            <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                <p class="options-paragraph m-0 p-0 me-2 d-inline">End date To Be Discussed(TBD)?</p>
                                <font-awesome-icon icon="calendar-day" class="icon-fa" />
                                <span class="tooltiptext">When you don't know for how long you want to let, make end date TBD (To Be Discussed)</span>
                              </div>
                            </span>
                            </div>
                          </div>
                          <div class="col-4">
                            <label class="switch">
                              <input type="checkbox" id="calendar-dates" v-model="form.is_date_range_end_tbd">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <date-picker
                            v-show="form.show_range_date === true && !form.is_short_term"
                            id="ranged"
                            :key="changed_dates"
                            :value="form.date_range"
                            v-model="form.date_range"
                            :disabled-dates="disabledDates"
                            mode="date"
                            :is-range=true
                            :min-date='new Date()'
                            :show-weeknumbers=true
                            color="blue"
                            :attributes="attr"
                        />
                        <div v-if="form.show_range_date === true && form.is_short_term == false">
                          <ValidationProvider :disabled="form.show_range_date === false && form.is_short_term == false" vid="vp-61" rules="required" v-slot="{ errors }" tag="div">
                            <input type="text" name="dateStart" maxlength="100" v-model="form.date_range.start" id="dateStart" class="visually-hidden">
                            <span class="red-text"><small>{{ errors[0] }}</small></span>
                          </ValidationProvider>
                        </div>

                        <date-picker
                            v-show="form.show_range_date === false || form.is_short_term"
                            id="singular"
                            :key="changed_singular_date"
                            :value="form.single_start_date"
                            v-model="form.single_start_date"
                            :disabled-dates="disabledDates"
                            mode="date"
                            :is-range=false
                            :min-date='new Date()'
                            :show-weeknumbers=true
                            color="blue"
                            :attributes="attr"
                        />
                        <div v-if="form.show_range_date === false || form.is_short_term == true">
                          <ValidationProvider :disabled="form.show_range_date === true || form.is_short_term == true" vid="vp-62" rules="required" v-slot="{ errors }" tag="div">
                            <input type="text" name="dateSingle" maxlength="100" v-model="form.single_start_date" id="dateSingle" class="visually-hidden">
                            <span class="red-text"><small>{{ errors[0] }}</small></span>
                          </ValidationProvider>
                        </div>

                        <div v-show="postType === 3 && form.is_short_term" class="row m-0 p-0">
                          <div class="row m-0 p-0">
                            <!-- Check-in Flexible -->
                            <div class="col-6 p-0 mt-2 mb-2">
                              <div class="row m-0 p-0">
                                <div class="col-9">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Flexible Check-in</p>
                                        <font-awesome-icon icon="calendar-check" class="icon-fa" />
                                        <span class="tooltiptext">Do you offer flexible check-in?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-3 text-start">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.check_in_flexible">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Checkout Flexible -->
                            <div class="col-6 p-0 mt-2 mb-2">
                              <div class="row m-0 p-0">
                                <div class="col-9">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Flexible Checkout</p>
                                        <font-awesome-icon icon="calendar-minus" class="icon-fa" />
                                        <span class="tooltiptext">Do you offer flexible checkout?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-3 text-start">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.check_out_flexible">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 m-0 p-0 mt-1 mb-1" id="checkInTimers">
                            <!-- I will use separate time date-pickers for check_in_from and check_in_to and singular_date -->
                            <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                 <div :class="form.check_in_flexible ? 'form-control mt-1 mb-1' : 'mt-1 mb-1'">
                                   <small><strong>{{ !form.check_in_flexible ? 'Check-in From ' : 'From: ' }}</strong> {{(form.check_in_flexible && form.date_range && form.date_range.start) ? moment.utc(form.date_range.start).format('DD-MM-YYYY') : (!form.check_in_flexible ? '' : 'None')}}</small>
                                 </div>
                                <span class="tooltiptext">This is preview only, to change it click on calendar dates</span>
                              </div>
                            </span>
                            </div>
<!--                            <div :class="form.check_in_flexible ? 'form-control mt-1 mb-1' : 'mt-1 mb-1'">-->
<!--                              <small><strong>{{ !form.check_in_flexible ? 'Check-in From ' : 'From: ' }}</strong> {{(form.check_in_flexible && form.date_range && form.date_range.start) ? moment.utc(form.date_range.start).format('DD-MM-YYYY') : (!form.check_in_flexible ? '' : 'None')}}</small>-->
<!--                            </div>-->
                            <date-picker
                                v-if="postType === 3 && form.is_short_term && !form.check_in_flexible"
                                mode="time"
                                v-model="form.check_in_from"
                                is24hr
                                :minute-increment="15"
                                :valid-hours="validHours"
                                :reactive=true
                            />
                            <!-- I will use separate time date-pickers for check_in_from and check_in_to and singular_date -->
                            <div v-if="!form.check_in_flexible" class="mt-1 mb-1">
                              <small><strong>Check-in To</strong></small>
                            </div>
                            <date-picker
                                v-if="postType === 3 && form.is_short_term && !form.check_in_flexible"
                                mode="time"
                                v-model="form.check_in_to"
                                is24hr
                                :minute-increment="15"
                                :valid-hours="validHours"
                                :reactive=true
                            />
                          </div>
                          <div class="col-6 m-0 p-0 mt-1 mb-1" id="checkOutTimers">
                            <!-- I will use separate time date-pickers for check_out_from and check_out_to -->
                            <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                 <div :class="form.check_out_flexible ? 'form-control mt-1 mb-1' : 'mt-1 mb-1'">
                                   <small><strong>{{ !form.check_out_flexible ? 'Checkout From ' : 'To: ' }}</strong> {{ form.check_out_flexible ? 'TBD' : ''}}</small>
                                 </div>
                                <span class="tooltiptext">This is preview only, to change it click on calendar dates</span>
                              </div>
                            </span>
                            </div>
<!--                            <div :class="form.check_out_flexible ? 'form-control mt-1 mb-1' : 'mt-1 mb-1'">-->
<!--                              <small><strong>{{ !form.check_out_flexible ? 'Checkout From ' : 'To: ' }}</strong> {{ form.check_out_flexible ? 'TBD' : ''}}</small>-->
<!--                            </div>-->
                            <date-picker
                                v-if="postType === 3 && form.is_short_term && !form.check_out_flexible"
                                mode="time"
                                v-model="form.check_out_from"
                                is24hr
                                :minute-increment="15"
                                :valid-hours="validHours"
                                :reactive=true
                            />
                            <!-- I will use separate time date-pickers for check_out_from and check_out_to -->
                            <div v-if="!form.check_out_flexible" class="mt-1 mb-1">
                              <small><strong>Checkout To</strong></small>
                            </div>
                            <date-picker
                                v-if="postType === 3 && form.is_short_term && !form.check_out_flexible"
                                mode="time"
                                v-model="form.check_out_to"
                                is24hr
                                :minute-increment="15"
                                :valid-hours="validHours"
                                :reactive=true
                            />
                          </div>
                        </div>
                        <div v-if="!form.is_short_term" class="row p-0 m-1">
                          <div class="col-6">
                            <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                 <div class="form-control">
                                   <strong>From:</strong> {{form.date_range && form.date_range.start ? moment.utc(form.date_range.start).format('DD-MM-YYYY') : 'None'}}
                                 </div>
                                <span class="tooltiptext">This is preview only, to change it click on calendar dates</span>
                              </div>
                            </span>
                            </div>
<!--                            <div class="form-control">-->
<!--                              <strong>From:</strong> {{form.date_range && form.date_range.start ? moment.utc(form.date_range.start).format('DD-MM-YYYY') : 'None'}}-->
<!--                            </div>-->
                          </div>
                          <div class="col-6">
                            <div class="list-relative">
                            <span data-tooltip="tooltip">
                              <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                 <div class="form-control">
                                   <strong>To:</strong> {{form.show_range_date ? (form.date_range && form.date_range.end ? moment.utc(form.date_range.end).format('DD-MM-YYYY') : 'None') : 'TBD'}}
                                 </div>
                                <span class="tooltiptext">This is preview only, to change it click on calendar dates</span>
                              </div>
                            </span>
                            </div>
<!--                            <div class="form-control">-->
<!--                              <strong>To:</strong> {{form.show_range_date ? (form.date_range && form.date_range.end ? moment.utc(form.date_range.end).format('DD-MM-YYYY') : 'None') : 'TBD'}}-->
<!--                            </div>-->
                          </div>
                        </div>
                        <p v-if="validationErrors && validationErrors.date_start" class="required-info text-center">
                          {{ validationErrors.date_start[0] }}
                        </p>
                        <p v-if="validationErrors && validationErrors.date_end" class="required-info text-center">
                          {{ validationErrors.date_end[0] }}
                        </p>

                        <div v-show="postType === 3 && form.is_short_term" class="row m-0 p-0">
                          <div class="col-6">
                            <!-- Check-in Instructions -->
                            <div class="col-12 pt-2 pb-2">
                              <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="not-absolute-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Check-in Instructions</p>
                                        <font-awesome-icon icon="hourglass-start" class="icon-fa" />
                                        <span class="required"> *</span>
                                        <span class="tooltiptext">What are the check-in instructions?</span>
                                      </div>
                                    </span>
                              </div>
                            </div>
                            <div class="col-12">
                              <!-- postType 1 = Sell; postType 2 = Buy -->
                              <ValidationProvider :disabled="form.is_short_term !== false || form.is_short_term !== 0" vid="vp-42" rules="required|max:30" v-slot="{ errors }">
                              <select
                                  v-if="postType !== 1 && postType !== 2"
                                  v-model="form.check_in_instructions_id"
                                  class="options-select form-select select-placeholder cursor-pointer"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.check_in_instructions_id, $event)"
                                  tabindex="-1"
                                  aria-label="check-in-instructions"
                                  name="check-in-instructions"
                                  id="checkInInstructions"
                              >
                                <option value="" disabled>Select instructions</option>
                                <option :value="1">Keypad</option>
                                <option :value="2">Lockbox</option>
                                <option :value="3">Smart Lock</option>
                                <option :value="4">Building Staff</option>
                                <option :value="5">Host Greets You</option>
                              </select>
                              <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-6">
                            <!-- Checkout Instructions -->
                            <div class="col-12 pt-2 pb-2">
                              <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="not-absolute-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Checkout Instructions</p>
                                        <font-awesome-icon icon="hourglass-end" class="icon-fa" />
                                        <span class="required"> *</span>
                                        <span class="tooltiptext">What are the checkout instructions?</span>
                                      </div>
                                    </span>
                              </div>
                            </div>
                            <div class="col-12">
                              <!-- postType 1 = Sell; postType 2 = Buy -->
                              <ValidationProvider :disabled="form.is_short_term !== false || form.is_short_term !== 0" vid="vp-43" rules="required|max:30" v-slot="{ errors }">
                              <select
                                  v-if="postType !== 1 && postType !== 2"
                                  v-model="form.check_out_instructions_id"
                                  class="options-select form-select select-placeholder cursor-pointer"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.check_out_instructions_id, $event)"
                                  tabindex="-1"
                                  aria-label="check-out-instructions"
                                  name="check-out-instructions"
                                  id="checkOutInstructions"
                              >
                                <option value="" disabled>Select instructions</option>
                                <option :value="1">Keypad</option>
                                <option :value="2">Lockbox</option>
                                <option :value="3">Smart Lock</option>
                                <option :value="4">Building Staff</option>
                                <option :value="6">Host Farewell You</option>
                              </select>
                              <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div v-if="form.is_short_term && postType && postType === 3" class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small v-if="form.is_short_term && postType && postType === 3" class="p-3 fw-bold">Cancellation Policy</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="form.is_short_term && postType && postType === 3" class="form-group col-12 mt-3 mb-3 text-center">
                        <div class="row m-0 p-0 mb-2 text-start">
                          <!-- Cancellation Period  calendar-minus -->
                          <div class="row m-0 p-0 mt-1 mb-1">
                            <div class="col-6 pt-2">
                              <div class="list-relative">
                                <span data-tooltip="tooltip">
                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">Free Cancellation Period</p>
                                    <font-awesome-icon icon="calendar-times" class="icon-fa" />
                                    <span class="tooltiptext">What is your allowed free cancellation period?</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div class="col-6">
                              <select
                                  v-model="form.cancellation_period"
                                  class="options-select form-select select-placeholder cursor-pointer"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.cancellation_period, $event)"
                                  tabindex="-1"
                                  aria-label="cancellation-period"
                                  name="cancellation-period"
                                  id="cancellationPeriod"
                              >
                                <option value="">Select period</option>
                                <option value="Day of Arrival">Day of Arrival</option>
                                <option value="1 Day">1 Day</option>
                                <option value="2 Days">2 Days</option>
                                <option value="3 Days">3 Days</option>
                                <option value="4 Days">4 Days</option>
                                <option value="5 Days">5 Days</option>
                                <option value="6 Days">6 Days</option>
                                <option value="7 Days">7 Days</option>
                                <option value="10 Days">10 Days</option>
                                <option value="14 Days">14 Days</option>
                              </select>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.cancellation_period" class="required-info text-center">
                            {{ validationErrors.cancellation_period[0] }}
                          </p>
                          <!-- Late Cancellation Rules -->
                          <div class="row m-0 p-0 mt-1 mb-1">
                            <div class="col-6 pt-2">
                              <div class="list-relative">
                                <span data-tooltip="tooltip">
                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">Late Cancellation Rules</p>
                                    <font-awesome-icon icon="ban" class="icon-fa" />
                                    <span class="tooltiptext">What are the late cancellation rules?</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div class="col-6">
                              <select
                                  v-model="form.cancellation_late_rule_id"
                                  class="options-select form-select select-placeholder cursor-pointer"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.cancellation_late_rule_id, $event)"
                                  tabindex="-1"
                                  aria-label="cancellation-late-rules"
                                  name="cancellation-late-rules"
                                  id="cancellationLateRules"
                              >
                                <option value="">Select rules</option>
                                <option value="1">First Night Price Charged</option>
                                <option value="2">Full Stay Price Charged</option>
                                <option value="3">Fixed Fee Charged</option>
                              </select>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.cancellation_late_rule_id" class="required-info text-center">
                            {{ validationErrors.cancellation_late_rule_id[0] }}
                          </p>
                          <!-- Cancellation Fee -->
                          <div v-if="form.cancellation_late_rule_id === '3'" class="row m-0 p-0 mt-1 mb-1">
                            <div class="col-6 pt-2">
                              <div class="list-relative">
                                <span data-tooltip="tooltip">
                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">Cancellation Fee</p>
                                    <font-awesome-icon icon="ban" class="icon-fa me-1" />
                                    <font-awesome-icon icon="pound-sign" class="icon-fa" />
                                    <span class="required"> *</span>
                                    <span class="tooltiptext">How much cancellation fee would you like to charge? (Deducted on refund)</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div class="col-3">
                              <ValidationProvider vid="vp-44" rules="required|min_value:1|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  placeholder="£"
                                  min="0"
                                  max="9999"
                                  v-model="form.cancellation_fee"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.cancellation_fee, $event)"
                              >
                              <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.cancellation_fee" class="required-info text-center">
                            {{ validationErrors.cancellation_fee[0] }}
                          </p>
                        </div>
                      </div>

                      <div v-if="form.is_short_term && postType && postType === 3" class="form-group col-12 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Discounts Policy</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <div v-if="form.is_short_term && postType && postType === 3" class="form-group col-12 mt-1 mb-1 text-center">
                        <div class="row m-0 p-0 mb-2 text-start">
                          <!-- Is Discount Term -->
                          <div class="row m-0 p-0 mt-1 mb-1">
                            <div class="col-12 p-0 mt-2 mb-2">
                              <div class="row m-0 p-0">
                                <div class="col-6">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Term Discount</p>
                                        <font-awesome-icon icon="tag" class="icon-fa" />
                                        <span class="tooltiptext">Do you offer term discount? As an example, if one books more than 14 days they get a discount</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-6 text-start">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_discount_term_term">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Discount Term -->
                            <div v-if="form.is_discount_term_term" class="col-12 p-0">
                              <div class="row m-0 p-0">
                                <div class="col-6 pt-2">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Discount Term</p>
                                        <font-awesome-icon icon="tag" class="icon-fa me-1" />
                                        <font-awesome-icon icon="calendar-day" class="icon-fa" />
                                        <span class="tooltiptext">How many days before booking should be considered for last minute discount?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-6 text-start">
                                  <select
                                      v-model="form.discount_term_term"
                                      class="options-select form-select select-placeholder cursor-pointer"
                                      @focus="handleFocus($event)"
                                      @focusout="handleFocusOut(form.discount_term_term, $event)"
                                      tabindex="-1"
                                      aria-label="discount-term-term"
                                      name="discount-term-term"
                                      id="discountTermTerm"
                                  >
                                    <option value="" disabled>Select term</option>
                                    <option value="1">1 Day</option>
                                    <option value="2">2 Days</option>
                                    <option value="3">3 Days</option>
                                    <option value="4">4 Days</option>
                                    <option value="5">5 Days</option>
                                    <option value="6">6 Days</option>
                                    <option value="7">7 Days</option>
                                    <option value="8">8 Days</option>
                                    <option value="9">9 Days</option>
                                    <option value="10">10 Days</option>
                                    <option value="14">14 Days</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.discount_fee_type_term" class="required-info text-center">
                            {{ validationErrors.discount_fee_type_term[0] }}
                          </p>

                          <!-- Term Discount Fee -->
                          <div v-if="form.is_discount_term_term" class="row m-0 p-0 mt-1 mb-1">
                            <div class="col-6 pt-2">
                              <div class="list-relative">
                                <span data-tooltip="tooltip">
                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">Term Discount Fee</p>
                                    <font-awesome-icon icon="tag" class="icon-fa me-1" />
                                    <font-awesome-icon :icon="form.discount_fee_type_term === '1' ? 'percentage' : 'pound-sign'" class="icon-fa" />
                                    <span class="required"> *</span>
                                    <span class="tooltiptext">How much discount fee would you like to charge? (Deducted on bookings meeting the requirements)</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div class="col-3">
                              <ValidationProvider vid="vp-45" rules="required|min_value:1|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  :placeholder="form.discount_fee_type_term === '1' ? '%' : '£'"
                                  :key="form.refreshTermDiscountFeePlaceholder"
                                  min="0"
                                  max="9999"
                                  v-model="form.discount_fee_term"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.discount_fee_term, $event)"
                              >
                              <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <div v-if="form.is_discount_term_term" class="col-3">
                              <ValidationProvider vid="vp-46" rules="required|max:30" v-slot="{ errors }">
                              <select
                                  v-model="form.discount_fee_type_term"
                                  class="options-select form-select select-placeholder cursor-pointer"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.discount_fee_type_term, $event)"
                                  tabindex="-1"
                                  aria-label="discount-term-term"
                                  name="discount-term-term"
                                  id="discountTermTerm"
                              >
                                <option value="" disabled>Select discount rate</option>
                                <option value="1">%</option>
                                <option value="2">£</option>
                              </select>
                              <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.discount_fee_term" class="required-info text-center">
                            {{ validationErrors.discount_fee_term[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.is_discount_term_term" class="required-info text-center">
                            {{ validationErrors.is_discount_term_term[0] }}
                          </p>
                        </div>
                      </div>

                      <!-- Start Horizontal Line with Icon -->
                      <div v-if="form.is_short_term && postType && postType === 3" class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <div v-if="form.is_short_term && postType && postType === 3" class="form-group col-12 mt-1 mb-1 text-center">
                        <div class="row m-0 p-0 mb-2 text-start">
                          <!-- Is Discount Last Minute -->
                          <div class="row m-0 p-0 mt-1 mb-1">
                            <div class="col-12 p-0 mt-2 mb-2">
                              <div class="row m-0 p-0">
                                <div class="col-6">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Last-minute Discount</p>
                                        <font-awesome-icon icon="clock" class="icon-fa" />
                                        <span class="tooltiptext">Do you offer last-minute discount?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-6 text-start">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_discount_term_last_minute">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Discount Term Last Minute -->
                            <div v-if="form.is_discount_term_last_minute" class="col-12 p-0">
                              <div class="row m-0 p-0">
                                <div class="col-6 pt-2">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Discount Term</p>
                                        <font-awesome-icon icon="clock" class="icon-fa me-1" />
                                        <font-awesome-icon icon="calendar-day" class="icon-fa" />
                                        <span class="tooltiptext">How many days before booking should be considered for last minute discount?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-6 text-start">
                                  <select
                                      v-model="form.discount_term_last_minute"
                                      class="options-select form-select select-placeholder cursor-pointer"
                                      @focus="handleFocus($event)"
                                      @focusout="handleFocusOut(form.discount_term_last_minute, $event)"
                                      tabindex="-1"
                                      aria-label="discount-term-list-minute"
                                      name="discount-term-list-minute"
                                      id="discountTermLastMinute"
                                  >
                                    <option value="" disabled>Select term</option>
                                    <option value="1">1 Day</option>
                                    <option value="2">2 Days</option>
                                    <option value="3">3 Days</option>
                                    <option value="4">4 Days</option>
                                    <option value="5">5 Days</option>
                                    <option value="6">6 Days</option>
                                    <option value="7">7 Days</option>
                                    <option value="8">8 Days</option>
                                    <option value="9">9 Days</option>
                                    <option value="10">10 Days</option>
                                    <option value="14">14 Days</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.is_discount_term_last_minute" class="required-info text-center">
                            {{ validationErrors.is_discount_term_last_minute[0] }}
                          </p>
                          <!-- Last Minute Discount Fee -->
                          <div v-if="form.is_discount_term_last_minute" class="row m-0 p-0 mt-1 mb-1">
                            <div class="col-6 pt-2">
                              <div class="list-relative">
                                <span data-tooltip="tooltip">
                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">Last-minute Discount Fee</p>
                                    <font-awesome-icon icon="clock" class="icon-fa me-1" />
                                    <font-awesome-icon :icon="form.discount_fee_type_last_minute === '1' ? 'percentage' : 'pound-sign'" class="icon-fa" />
                                    <span class="required"> *</span>
                                    <span class="tooltiptext">How much discount fee would you like to charge? (Deducted on bookings meeting the requirements)</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div class="col-3">
                              <ValidationProvider vid="vp-47" rules="required|min_value:1|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  :placeholder="form.discount_fee_type_last_minute === '1' ? '%' : '£'"
                                  :key="form.refreshLastMinuteDiscountFeePlaceholder"
                                  min="0"
                                  max="9999"
                                  v-model="form.discount_fee_last_minute"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.discount_fee_last_minute, $event)"
                              >
                              <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <div v-if="form.is_discount_term_last_minute" class="col-3">
                              <ValidationProvider vid="vp-48" rules="required|max:30" v-slot="{ errors }">
                              <select
                                  v-model="form.discount_fee_type_last_minute"
                                  class="options-select form-select select-placeholder cursor-pointer"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.discount_fee_type_last_minute, $event)"
                                  tabindex="-1"
                                  aria-label="discount-fee-type-last-minute"
                                  name="discount-fee-type-last-minute"
                                  id="discountFeeTypeLastMinute"
                              >
                                <option value="" disabled>Select discount rate</option>
                                <option value="1">%</option>
                                <option value="2">£</option>
                              </select>
                              <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.discount_fee_last_minute" class="required-info text-center">
                            {{ validationErrors.discount_fee_last_minute[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.discount_fee_type_last_minute" class="required-info text-center">
                            {{ validationErrors.discount_fee_type_last_minute[0] }}
                          </p>
                        </div>
                      </div>

                      <!-- Start Horizontal Line with Icon -->
                      <div v-if="form.is_short_term && postType && postType === 3" class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                      <div v-if="form.is_short_term && postType && postType === 3" class="form-group col-12 mt-1 mb-1 text-center">
                        <div class="row m-0 p-0 mb-2 text-start">
                          <!-- Is Discount Guest Number -->
                          <div class="row m-0 p-0 mt-1 mb-1">
                            <div class="col-12 p-0 mt-2 mb-2">
                              <div class="row m-0 p-0">
                                <div class="col-6">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Guest Number Discount</p>
                                        <font-awesome-icon icon="user-friends" class="icon-fa" />
                                        <span class="tooltiptext">Do you offer discount based on minimum number of guests?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-6 text-start">
                                  <label class="switch">
                                    <input type="checkbox" v-model="form.is_discount_term_no_of_guests">
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <!-- Discount Term Guests Number -->
                            <div v-if="form.is_discount_term_no_of_guests" class="col-12 p-0">
                              <div class="row m-0 p-0">
                                <div class="col-6 pt-2">
                                  <div class="list-relative">
                                    <span data-tooltip="tooltip">
                                      <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                        <p class="options-paragraph m-0 p-0 me-2 d-inline">Discount Term</p>
                                        <font-awesome-icon icon="user-friends" class="icon-fa me-1" />
                                        <font-awesome-icon icon="calendar-day" class="icon-fa" />
                                        <span class="tooltiptext">How many guests should be considered for the discount?</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-6 text-start">
                                  <select
                                      v-model="form.discount_term_no_of_guests"
                                      class="options-select form-select select-placeholder cursor-pointer"
                                      @focus="handleFocus($event)"
                                      @focusout="handleFocusOut(form.discount_term_no_of_guests, $event)"
                                      tabindex="-1"
                                      aria-label="discount-term-no-of-guests"
                                      name="discount-term-no-of-guests"
                                      id="discountTermNoOfGuests"
                                  >
                                    <option value="" disabled>Select number of guests</option>
                                    <option value="2">2+</option>
                                    <option value="3">3+</option>
                                    <option value="4">4+</option>
                                    <option value="5">5+</option>
                                    <option value="6">6+</option>
                                    <option value="7">7+</option>
                                    <option value="8">8+</option>
                                    <option value="9">9+</option>
                                    <option value="10">10+</option>
                                    <option value="11">11+</option>
                                    <option value="12">12+</option>
                                    <option value="13">13+</option>
                                    <option value="14">14+</option>
                                    <option value="15">15+</option>
                                    <option value="16">16+</option>
                                    <option value="17">17+</option>
                                    <option value="18">18+</option>
                                    <option value="19">19+</option>
                                    <option value="20">20+</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.is_discount_term_no_of_guests" class="required-info text-center">
                            {{ validationErrors.is_discount_term_no_of_guests[0] }}
                          </p>
                          <!-- Guests Number Discount Fee -->
                          <div v-if="form.is_discount_term_no_of_guests" class="row m-0 p-0 mt-1 mb-1">
                            <div class="col-6 pt-2">
                              <div class="list-relative">
                                <span data-tooltip="tooltip">
                                  <div class="options-text-truncate-item no-text-wrap overflow-hidden text-truncate">
                                    <p class="options-paragraph m-0 p-0 me-2 d-inline">Guest Number Discount Fee</p>
                                    <font-awesome-icon icon="user-friends" class="icon-fa me-1" />
                                    <font-awesome-icon :icon="form.discount_fee_type_no_of_guests === '1' ? 'percentage' : 'pound-sign'" class="icon-fa" />
                                    <span class="required"> *</span>
                                    <span class="tooltiptext">How much discount fee would you like to charge? (Deducted on bookings meeting the requirements)</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div class="col-3">
                              <ValidationProvider vid="vp-49" rules="required|min_value:1|max_value:9999" v-slot="{ errors }">
                              <input
                                  type="number"
                                  :placeholder="form.discount_fee_type_no_of_guests === '1' ? '%' : '£'"
                                  :key="form.refreshNoOfGuestsDiscountFeePlaceholder"
                                  min="0"
                                  max="9999"
                                  v-model="form.discount_fee_no_of_guests"
                                  class="options-input options-price-input select-placeholder"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.discount_fee_no_of_guests, $event)"
                              >
                              <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <div v-if="form.is_discount_term_no_of_guests" class="col-3">
                              <ValidationProvider vid="vp-50" rules="required|max:30" v-slot="{ errors }">
                              <select
                                  v-model="form.discount_fee_type_no_of_guests"
                                  class="options-select form-select select-placeholder cursor-pointer"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.discount_fee_type_no_of_guests, $event)"
                                  tabindex="-1"
                                  aria-label="discount-fee-type-no-of-guests"
                                  name="discount-fee-type-no-of-guests"
                                  id="discountFeeTypeNoOfGuests"
                              >
                                <option value="" disabled>Select discount rate</option>
                                <option value="1">%</option>
                                <option value="2">£</option>
                              </select>
                              <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.discount_fee_no_of_guests" class="required-info text-center">
                            {{ validationErrors.discount_fee_no_of_guests[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.discount_fee_type_no_of_guests" class="required-info text-center">
                            {{ validationErrors.discount_fee_type_no_of_guests[0] }}
                          </p>
                        </div>
                      </div>

                      <!-- Start Horizontal Line with Icon -->
                      <div v-if="form.is_short_term && postType && postType === 3" class="horizontal-separator-box">
                        <span class="hr-line-short"></span>
                        <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                        <span class="hr-line-short"></span>
                      </div>
                      <!-- End Horizontal Line with Icon -->

                    </div>
                    <!-- End Availability Collapse Section -->
                  </div>

                </form>
                </ValidationObserver>

                <ValidationObserver vid="vo-7" ref="contactDetailsFormObserver" v-slot="{ handleSubmit }">
                <form action="javascript:void(0)" onsubmit="return false;" class="row" method="post" enctype="multipart/form-data" id="contactDetailsForm">

                  <div v-if="postType" class="form-group col-12 mb-1 text-center">

                    <!-- Information -->
                    <button class="section-btn collapsed" @click.prevent="scrollToView('contactDetails')" style="width: 100%;" type="button" data-bs-toggle="collapse" data-bs-target="#contactDetails" aria-expanded="false" aria-controls="contactDetails">
                      <small class="p-3 fw-bold">Contact Details</small>
                      <span :class="checkContactDetailsValidation ? 'check-no-validation-error check-validation-error' : 'check-no-validation-error'">
                          <font-awesome-icon icon="asterisk" class="icon-fa"/>
                      </span>
<!--                      {{checkContactDetailsFilled}}-->
<!--                      <span v-if="contactDetailsValid" class="check-not-filled check-filled">-->
<!--                          <font-awesome-icon icon="check-circle" class="icon-fa"/>-->
<!--                      </span>-->
<!--                      <span v-else class="check-not-filled">-->
<!--                          <font-awesome-icon icon="check-circle" class="icon-fa"/>-->
<!--                      </span>-->
                      <span :class="contactDetailsValid ? 'check-not-filled check-filled' : 'check-not-filled'">
                        <font-awesome-icon icon="check-circle" class="icon-fa"/>
                      </span>
                    </button>

                  </div>

                  <div v-if="postType" class="col-12 text-start mb-1 p-0">

                    <div class="collapse" id="contactDetails" data-bs-parent="#premisesGroup">
                      <!-- Start Contact Details Collapse Section -->

                      <div v-if="postType" class="form-group col-12 p-0 text-center">
                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3 fw-bold">Contact Details</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->
                      </div>

                      <!-- Start Contact Details -->
                      <div v-if="postType" class="form-group col-12 ps-2 pe-2">

                        <div v-if="postType" class="form-group col-12 pb-2">
                          <div class="row m-0 p-0">
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 p-1 m-0">
                              <label class="font-weight-bold options-paragraph">Title</label> <span class="required">*</span>
                              <ValidationProvider vid="vp-51" rules="required|min:2|max:5" v-slot="{ errors }">
                              <select
                                  v-model="form.title"
                                  class="form-select select-placeholder cursor-pointer"
                                  @focus="handleFocus($event)"
                                  @focusout="handleFocusOut(form.title, $event)"
                                  tabindex="-1"
                                  aria-label="title"
                                  name="title"
                                  id="title"
                              >
                                <option value="" disabled>Select title</option>
                                <option v-for="(item , index) in titleOptions" v-bind:key="index" :value="item.text" >
                                  {{item.text}}
                                </option>
                              </select>
                              <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 p-1 m-0">
                              <label for="name" class="font-weight-bold options-paragraph">Name</label> <span class="required">*</span>
                              <ValidationProvider vid="vp-52" rules="required|min:2|max:50" v-slot="{ errors }">
                                <input type="text" name="name" maxlength="50" v-model="form.name" id="name" placeholder="Enter name" class="form-control">
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 p-1 m-0">
                              <label for="surname" class="font-weight-bold options-paragraph">Surname</label> <span class="required">*</span>
                              <ValidationProvider vid="vp-53" rules="required|min:2|max:50" v-slot="{ errors }">
                                <input type="text" name="surname" maxlength="50" v-model="form.surname" id="surname" placeholder="Enter surname" class="form-control">
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.title" class="required-info text-center">
                            {{ validationErrors.title[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.name" class="required-info text-center">
                            {{ validationErrors.name[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.surname" class="required-info text-center">
                            {{ validationErrors.surname[0] }}
                          </p>
                        </div>

                        <div class="form-group col-12 pb-2">
                          <div class="row m-0 p-0">
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 p-1 m-0">
                              <label for="company_name" class="font-weight-bold options-paragraph">Company name</label>
                              <ValidationProvider vid="vp-54" rules="max:100" v-slot="{ errors }">
                                <input type="text" name="company_name" maxlength="100" v-model="form.company_name" id="company_name" placeholder="Enter company name" class="form-control">
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                                <!--                    <small>Note that mobile is the number that will be displayed on your advertising.</small>-->
                            </div>
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 p-1 m-0">
                              <label for="landing" class="font-weight-bold options-paragraph">Show company name</label><br/>
                              <label class="switch mt-2">
                                <input type="checkbox" v-model="form.show_company_name">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <!-- Address -->
                        <div class="col-12 pb-2">
                          <div class="row m-0 p-0">
                            <!-- Should be col-8, for time being col-12 as we do not do manual address enter -->
                            <div class="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 m-0 p-1">
                              <label class="font-weight-bold options-paragraph">Company Address</label>
                              <div id="geocoderAddEditPropertyContactAddress"></div>
                            </div>
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 p-1 m-0">
                              <label for="landing" class="font-weight-bold options-paragraph">Show address</label><br/>
                              <label class="switch mt-2">
                                <input type="checkbox" v-model="form.show_address">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.prop_contact_full_address" class="required-info text-center">
                            {{ validationErrors.prop_contact_full_address[0] }}
                          </p>
                        </div>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <!-- Information -->
                        <small class="p-3">Note that you need to display either mobile or email as a way to contact you. Those will be shown on your advertise.</small>

                        <!-- Start Horizontal Line with Icon -->
                        <div class="horizontal-separator-box">
                          <span class="hr-line-short"></span>
                          <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                          <span class="hr-line-short"></span>
                        </div>
                        <!-- End Horizontal Line with Icon -->

                        <div class="form-group col-12 pb-2">
                          <div class="row m-0 p-0">
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 p-1 m-0">
                              <label for="mobile-prefix" class="font-weight-bold options-paragraph">Prefix</label>
                              <ValidationProvider vid="vp-55" rules="numeric|min:1|max:7" v-slot="{ errors }">
                                <input type="number" name="mobile-prefix" minlength="1" maxlength="7" v-model="form.mobile_prefix" id="mobile-prefix" placeholder="+" class="form-control">
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-1 m-0">
                              <label for="mobile" class="font-weight-bold options-paragraph">Mobile</label> <span class="required">*</span>
                              <ValidationProvider vid="vp-56" rules="required|numeric|min:4|max:15" v-slot="{ errors }">
                                <input type="number" name="mobile" minlength="4" maxlength="15" v-model="form.mobile" id="mobile" placeholder="Enter mobile number" class="form-control">
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 p-1 m-0">
                              <label for="landing" class="font-weight-bold options-paragraph">Show mobile number</label><br/>
                              <label class="switch mt-2">
                                <input type="checkbox" v-model="form.show_mobile">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.mobile_prefix" class="required-info text-center">
                            {{ validationErrors.mobile_prefix[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.mobile" class="required-info text-center">
                            {{ validationErrors.mobile[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.show_mobile" class="required-info text-center">
                            {{ validationErrors.show_mobile[0] }}
                          </p>
                        </div>

                        <div class="form-group col-12 pb-2">
                          <div class="row m-0 p-0">
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 p-1 m-0">
                              <label for="landing-prefix" class="font-weight-bold options-paragraph">Prefix</label>
                              <ValidationProvider vid="vp-57" rules="numeric|min:1|max:7" v-slot="{ errors }">
                                <input type="number" name="landing-prefix" minlength="1" maxlength="7" v-model="form.landing_prefix" id="landing-prefix" placeholder="+" class="form-control">
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 p-1 m-0">
                              <label for="landing" class="font-weight-bold options-paragraph">Landing</label>
                              <ValidationProvider vid="vp-58" rules="numeric|min:4|max:15" v-slot="{ errors }">
                                <input type="number" name="landing" minlength="4" maxlength="15" v-model="form.landing" id="landing" placeholder="Enter landing number" class="form-control">
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 p-1 m-0">
                              <label for="landing" class="font-weight-bold options-paragraph">Show landing number</label><br/>
                              <label class="switch mt-2">
                                <input type="checkbox" v-model="form.show_landing">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.landing_prefix" class="required-info text-center">
                            {{ validationErrors.landing_prefix[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.landing" class="required-info text-center">
                            {{ validationErrors.landing[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.show_landing" class="required-info text-center">
                            {{ validationErrors.show_landing[0] }}
                          </p>
                        </div>

                        <div class="form-group col-12 pb-2">
                          <div class="row m-0 p-0">
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 p-1 m-0">
                              <label for="email" class="font-weight-bold options-paragraph">Email</label> <span class="required">*</span>
                              <ValidationProvider vid="vp-59" rules="required|email|max:100" v-slot="{ errors }">
                                <input type="text" name="email" maxlength="100" v-model="form.email" id="email" placeholder="Enter email" class="form-control">
                                <span class="red-text"><small>{{ errors[0] }}</small></span>
                              </ValidationProvider>
                            </div>
                            <div class="form-group col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 p-1 m-0">
                              <label for="landing" class="font-weight-bold options-paragraph">Show email</label><br/>
                              <label class="switch mt-2">
                                <input type="checkbox" v-model="form.show_email">
                                <span class="slider round"></span>
                              </label>
                            </div>
                          </div>
                          <p v-if="validationErrors && validationErrors.email" class="required-info text-center">
                            {{ validationErrors.email[0] }}
                          </p>
                          <p v-if="validationErrors && validationErrors.show_email" class="required-info text-center">
                            {{ validationErrors.show_email[0] }}
                          </p>
                        </div>

                      </div>
                      <!-- End Contact Details -->
                    </div>
                    <!-- End Contact Details Collapse Section -->
                  </div>

                </form>
                </ValidationObserver>

                <ValidationObserver vid="vo-8" ref="paymentFormObserver" v-slot="{ handleSubmit }">
                <form action="javascript:void(0)" onsubmit="return false;" class="row" method="post" enctype="multipart/form-data" id="paymentForm">

                  <div v-if="postType" class="form-group col-12 text-center">
                    <!-- Start Horizontal Line with Icon -->
                    <div class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->

                    <!-- Information -->
                    <small class="p-3 fw-bold">Declaration of Truth</small>

                    <!-- Start Horizontal Line with Icon -->
                    <div class="horizontal-separator-box">
                      <span class="hr-line-short"></span>
                      <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                      <span class="hr-line-short"></span>
                    </div>
                    <!-- End Horizontal Line with Icon -->
                  </div>

                  <div v-if="postType" class="form-group col-12 ps-3 pe-3 pb-2 text-start">
                    <span role="button">
                      <ValidationProvider vid="vp-60" rules="required_checkbox" v-slot="{ errors }">
                        <input role="button" type="checkbox" name="declaration-of-truth" v-model="form.declaration_of_truth" id="declarationOfTruth" class="me-2 d-inline">
                        <label role="button" for="declarationOfTruth" class="font-weight-bold d-inline">
                        I declare that provided information are best to my knowledge and truthful, it is also genuine advertise of existing premises and I take full responsibility for the content of this advertise.
                        </label> <span class="required d-inline">* </span>
                        <span class="red-text"><small>{{ errors[0] }}</small></span>
                      </ValidationProvider>
                    </span>
                    <p v-if="validationErrors && validationErrors.declaration_of_truth" class="required-info text-center">
                      {{ validationErrors.declaration_of_truth[0] }}
                    </p>
                  </div>

                  <!-- Start Horizontal Line with Icon -->
                  <div v-if="postType" class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- (#FREEPAYMENT) Start For now we comment this out, as all ads will be for free -->
<!--                  <div v-show="postType && form.is_short_term == 0" class="form-group col-12 mt-3 mb-3">-->
<!--                    <Subscribe ref="purchase" @isPaidAd="changeAdPlan"/>-->
<!--                    <p v-if="validationErrors && validationErrors.is_free_ad" class="required-info text-center">-->
<!--                      {{ validationErrors.is_free_ad[0]}}-->
<!--                    </p>-->
<!--                    <p v-else-if="validationErrors && validationErrors.is_paid_ad" class="required-info text-center">-->
<!--                      {{ validationErrors.is_paid_ad[0] }}-->
<!--                    </p>-->
<!--                  </div>-->
                  <!-- (#FREEPAYMENT) End For now we comment this out, as all ads will be for free -->

<!--                  &lt;!&ndash; Useful error spitting for debugging &ndash;&gt;-->
<!--                  <div v-if="validationErrors" class="col-12 mb-2">-->
<!--                    <ValidationErrors :errors="validationErrors" v-if="validationErrors"/>-->
<!--                  </div>-->

                  <div v-if="postType" class="col-12 mb-2">
                    <button type="submit" :disabled="processing" @click.prevent="addProperty" class="btn-branding border-branding btn-block min-width-100">
                      {{ processing ? "Please wait" : "Submit" }}
                    </button>
                    <div v-if="processing" class="col-12 p-2 text-center">
                      <div class="spinner-border text-info" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <p v-if="validationCardMessage" class="required-info text-center">
                      {{ validationCardMessage }}
                    </p>
                    <p v-if="validationMessage && validationErrors && !validationErrors.free_ad_exists" class="required-info text-center">
                      {{ validationMessage }} Please scroll up to see if there's more error messages.
                    </p>
                    <p v-if="validationErrors && validationErrors && validationErrors.free_ad_exists" class="required-info text-center">
                      {{ validationErrors.free_ad_exists[0] }}
                    </p>
                    <p v-if="veeValidationMessage && veeValidationErrors" class="required-info text-center">
                      {{ veeValidationMessage }} Please scroll up to see if there's more error messages.
                    </p>
                  </div>

                  </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
            </div>

          </div>
        </div>

      </div>
      <!-- End Add Advertise Section - Form section -->
      <!-- Preview AdDetails Section -->
      <div class="col-12 col-lg-8 col-md-12 col-sm-12 col-xs-12">
        <div class="position-relative h-100">
          <div id="previewPropDetailsModal" class="preview-modal-tab">
            <!-- Loading -->
            <div class="fw-bold p-1 m-1" v-if="!postType">Advert Preview</div>
            <hr v-if="!postType"/>
            <div class="fw-bold p-5 m-2" v-if="!postType">Please choose your advertise type</div>
            <Loader :loading="!postType" :small="false"></Loader>
            <PreviewAdDetails v-if="postType && !loading" :formData="form" :postType="postType"/>
          </div>
        </div>
      </div>
      <!-- End Preview AdDetails Section -->
    </div>

  </div>
</template>

<script>

import axios from 'axios'
import Loader from "./Loader.vue"
import TipTap from './TipTap.vue'
import PreviewAdDetails from './PreviewAdDetails'
import AddRoomModal from './AddRoomModal'
import Subscribe from './Subscribe'
import ValidationErrors from './ValidationErrors'
import FormDraft from './FormDrafts/FormDraft';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import router from "../router"

export default {
  name:"AddEditAd",
  props: ['id', 'postTypeProp'],
  components: {
    'Loader': Loader,
    'TipTap': TipTap,
    'PreviewAdDetails': PreviewAdDetails,
    'AddRoomModal': AddRoomModal,
    'Subscribe': Subscribe,
    'ValidationErrors': ValidationErrors,
    'FormDraft': FormDraft
  },
  data(){
    return {
      drafts: '',
      selectedDraft: '',
      draftLoad: false,
      showBecomeHostMsg: this.$store.state.auth.user && this.$store.state.auth.user.is_host ? false : true,
      form: {
        id: this.id ? this.id : '',
        user_id: '', // Should be pre-populated with user_id
        // Contact details must be provided on this stage (unless been provided earlier via user panel)
        /* Property contact details */
        title: '',
        /* Start Property Types */
        property_types: {
          checked: false,
          /* Residential Houses */
          houses_disable: false,
          houses: false,
          detached_house: false,
          semi_detached_house: false,
          mid_terraced: false,
          end_terraced: false,
          detached_bungalow: false,
          semi_detached_bungalow: false,
          cottage: false,
          /* Residential Flats */
          flats_disable: false,
          flats: false,
          flat: false,
          studio: false,
          maisonette: false,
          /* Commercial Offices */
          offices_disable: false,
          offices: false,
          traditional_office: false,
          serviced_office: false,
          managed_office: false,
          /* Commercial Retail */
          retail_disable: false,
          retail: false,
          shop: false,
          shopping_mall: false,
          retail_store: false,
          /* Commercial Leisure */
          leisure_disable: false,
          leisure: false,
          restaurant_cafe: false,
          takeaway: false,
          pub: false,
          entertainment: false,
          sport_healthcare_facility: false,
          hotel_tourism: false,
          /* Commercial Healthcare */
          healthcare_disable: false,
          healthcare: false,
          pharmacy: false,
          medical_center: false,
          nursing_home: false,
          hospital: false,
          /* Commercial Garage/Parking */
          garage_parking_disable: false,
          garage_parking: false,
          parking_space: false,
          single_garage: false,
          multiple_garages: false,
          parking_lot: false,
          multi_storey_parking: false,
          /* Commercial Multifamily */
          multifamily_disable: false,
          multifamily: false,
          duplex: false,
          block_of_flats: false,
          apartment_complex: false,
          /* Industrial Warehouse */
          warehouses_disable: false,
          warehouses: false,
          /* Industrial Factory */
          factories_disable: false,
          factories: false,
          /* Commercial Powerplant */
          power_plants_disable: false,
          power_plants: false,
          hydroelectric_plant: false,
          coal_fired_plant: false,
          diesel_fired_plant: false,
          geothermal_plant: false,
          gas_fired_plant: false,
          solar_power_plant: false,
          wind_power_plant: false,
          tidal_power_plant: false,
          /* Land */
          land_disable: false,
          land: false,
          greenfield: false,
          brownfield: false,
          /* Luxury */
          luxury_disable: false,
          luxury: false,
          mansion: false,
          villa: false,
          /* Special Use */
          special_use_disable: false,
          special_use: false,
          church: false,
          cemetery: false,
          park: false,
          school: false,
          barn: false,
          campsite: false,
          /* Rooms */
          rooms_disable: false,
          rooms: false,
          single_room: false,
          double_room: false
        },
        /* End Property Types */
        /* Date Picker */
        date_range: {
          start: null,
          end: null
        },
        /* For Working Hours */
        working_hours_from: null, // new Date(this.moment.utc().set("hour", 8).set("minute", 0).format('YYYY-MM-DD HH:mm:ss')),
        working_hours_to: null, // new Date(this.moment.utc().set("hour", 17).set("minute", 0).format('YYYY-MM-DD HH:mm:ss')),
        working_hours_from_flexible: false,
        working_hours_to_flexible: false,
        // For check_in_to and check_out_to values
        date_range_to: {
          start: null,
          end: null,
        },
        date_start: null,
        date_end: null,
        date_start_to: null,
        date_end_to: null,
        is_date_range_end_tbd: false,
        show_range_date: true,
        single_start_date: null,
        check_in_flexible: false,
        check_out_flexible: false,
        check_in_instructions_id: '',
        check_out_instructions_id: '',
        check_in_from: null,
        check_in_to: null,
        check_out_from: null,
        check_out_to: null,
        /* End Date Picker */
        name: '',
        surname: '',
        company_name: '',
        show_company_name: false,
        landing_prefix: '',
        landing: '',
        show_landing: false,
        mobile_prefix: '',
        mobile: '',
        show_mobile: true,
        email: '',
        show_email: false,
        /* End of property contact details */
        contact_type_id: '', // Should be pre-populated with the id in controller (this should be added during creation of account)
        property_category_id: '',
        property_type_id: '',
        property_type_details_id: '',
        property_details_id: '',
        property_address_id: '', // Should be pre-populated with the id of below address data (should happen in controller really)
        /* Property address details */
        address_type_id: '', // Should be pre-populated with Property (id = 2 if I am correct - do it in controller)
        full_address: '',
        postcode: '',
        street: '',
        line_1: '',
        line_2: '',
        city: '',
        county: '',
        country: '',
        longitude: '',
        latitude: '',
        boundary_box: '',
        /* End of property address details */
        /* Property Contact Address */
        prop_contact_full_address: '',
        prop_contact_postcode: '',
        prop_contact_street: '',
        prop_contact_line_1: '',
        prop_contact_line_2: '',
        prop_contact_city: '',
        prop_contact_county: '',
        prop_contact_country: '',
        prop_contact_longitude: '',
        prop_contact_latitude: '',
        prop_contact_boundary_box: '',
        show_address: false,
        /* End Property Contact Address */
        property_title: '',
        property_description: '',
        /* Start of property details */
        property_id: '', // Should be set in controller really
        easements: false,
        epc_rating: '',
        floor_plans: false,
        floors: '',
        basement: false,
        attic: false,
        balcony: false,
        front_porch: false,
        rear_porch: false,
        front_garden: false,
        rear_garden: false,
        garden_terrace: false,
        conservatory: false,
        green_house: '',
        shed: '',
        garage: '',
        driveway: '',
        parking: false,
        parking_paid: false,
        parking_fee: '',
        parking_spaces: '',
        charging_station: false,
        charging_station_paid: false,
        solar_panels: false,
        room: '', // Used for edit rooms #look at editRoom() function - it should hold current room data
        rooms: [],
        single_room_suite: '',
        double_room_suite: '',
        single_room: '',
        double_room: '',
        twin_room: '',
        twin_double_room: '',
        triple_room: '',
        quadruple_room: '',
        family_room: '',
        studio_room: 0,
        box_room: '',
        living_room: '',
        dining_room: '',
        kitchen: '',
        fridge: false,
        freezer: false,
        microwave: false,
        oven: false,
        kettle: false,
        coffee: false,
        toaster: false,
        bath_room: '',
        bath: '',
        shower: '',
        sink: '',
        wc: '',
        downstairs_wc: '',
        inside_pool: false,
        inside_pool_paid: false,
        outside_pool: false,
        outside_pool_paid: false,
        sauna: false,
        sauna_paid: false,
        hot_tub: false,
        hot_tub_paid: false,
        spa: false,
        spa_paid: false,
        gym: false,
        gym_paid: false,
        cinema_room: false,
        cinema_room_paid: false,
        bicycle: false,
        bicycle_paid: false,
        wifi: false,
        wifi_paid: false,
        restaurant: false,
        room_service: false,
        room_service_paid: false,
        service_fee: '',
        bar: false,
        h24_front_desk: false,
        non_smoking_rooms: false,
        smoking_rooms: false,
        airport_shuttle: false,
        air_conditioning: false,
        breakfast: false,
        breakfast_paid: false,
        stove: false,
        security_cameras: false,
        fire_rings: false, // Campsite as well
        fire_rings_paid: false, // Campsite as well
        other: '',
        /* Start Campsite */
        tents: '',
        caravans: '',
        hammocks: false,
        campsite_shower: false,
        campsite_shower_paid: false,
        campsite_shower_fee: '',
        campsite_toilet: false,
        campsite_toilet_paid: false,
        campsite_toilet_fee: '',
        electricity: false,
        electricity_paid: false,
        water_supply: false,
        water_supply_paid: false,
        fire_fuels: false,
        fire_fuels_paid: false,
        /* End Campsite */
        /* End of property details */
        cancellation_period: '',
        cancellation_late_rule_id: '',
        cancellation_fee: '',
        cancellation_rule: {
          rule: null
        },
        // Term Discount
        is_discount_term_term: false,
        discount_term_term: '',
        discount_fee_type_term: '1',
        discount_fee_term: '',
        refreshTermDiscountFeePlaceholder: 0,
        refreshLastMinuteDiscountFeePlaceholder: 0,
        refreshNoOfGuestsDiscountFeePlaceholder: 0,
        // Last Minute Discount
        is_discount_term_last_minute: false,
        discount_term_last_minute: '',
        discount_fee_type_last_minute: '1',
        discount_fee_last_minute: '',
        // Number of Guests Discount
        is_discount_term_no_of_guests: false,
        discount_term_no_of_guests: '',
        discount_fee_type_no_of_guests: '1',
        discount_fee_no_of_guests: '',
        // House Rules must be stored as an array of strings (json ?)
        house_rules: [],
        rule: '',
        cleaning_service: false,
        cleaning_service_paid: false,
        cleaning_fee: '',
        star_rating: '',
        property_square_meter: '',
        price: '',
        tenants_guests: '',
        // offer: '', // -
        // sold_price: '', // -
        // rating_id: '', // -
        document_id: '',
        property_history_id: '',
        is_coming_soon: false,
        is_sale: false,
        is_swap: false,
        property_rent_type_id: '',
        property_rent_type: {
          id: null,
          type: null
        },
        is_rent: false,
        is_let: false,
        is_buy: false,
        is_short_term: false,
        is_residential: false,
        is_commercial: false,
        is_industrial: false,
        is_luxury: false,
        is_land: false,
        is_special_use: false,
        is_room: false,
        is_furnished: false,
        is_freehold: '',
        is_shared_ownership: false,
        is_over_60s: false,
        is_accessible: false,
        is_pet_friendly: false,
        pet_friendly_paid: false,
        pet_friendly_fee: '',
        events_allowed: false,
        is_dss_accepted: false,
        is_holiday: false,
        is_seashore: false,
        is_waterfront: false,
        is_mixed_use: false,
        is_investment: false,
        // is_buy_to_let: false,
        /* Start of Investment Details */
        roi: '',
        roa: '',
        yield: '',
        cash_flow: '',
        share_percentage: '',
        maintenance_cost: '',
        number_of_flats: '',
        number_of_blocks: '',
        number_of_units: '',
        /* End of Investment Details */
        investment_property_id: '',
        is_hmo: false,
        is_tenanted: false,
        is_cash: false,
        is_auction: false,
        // is_under_offer: false, // -
        // is_sold: false, // -
        is_published: false,
        is_completed: false,
        is_deleted: false,
        is_free_ad: '',
        is_paid_ad: '',
        created_by: '',
        updated_by: '',
        deleted_by: '',
        // Extra bits necessary in form for previewAdDetails Component
        propertyCatType: 'Select premises type',
        propertyCategory: '',
        previewPropImages: [],
        previewFloorplanImages: [],
        propImages: [],
        floorplanImages: [],
        editPropImages: [],
        editFloorplanImages: [],
        declaration_of_truth: false
      },
      defaultForm: {
        id: this.id ? this.id : '',
        user_id: '', // Should be pre-populated with user_id
        // Contact details must be provided on this stage (unless been provided earlier via user panel)
        /* Property contact details */
        title: '',
        /* Start Property Types */
        property_types: {
          checked: false,
          /* Residential Houses */
          houses_disable: false,
          houses: false,
          detached_house: false,
          semi_detached_house: false,
          mid_terraced: false,
          end_terraced: false,
          detached_bungalow: false,
          semi_detached_bungalow: false,
          cottage: false,
          /* Residential Flats */
          flats_disable: false,
          flats: false,
          flat: false,
          studio: false,
          maisonette: false,
          /* Commercial Offices */
          offices_disable: false,
          offices: false,
          traditional_office: false,
          serviced_office: false,
          managed_office: false,
          /* Commercial Retail */
          retail_disable: false,
          retail: false,
          shop: false,
          shopping_mall: false,
          retail_store: false,
          /* Commercial Leisure */
          leisure_disable: false,
          leisure: false,
          restaurant_cafe: false,
          takeaway: false,
          pub: false,
          entertainment: false,
          sport_healthcare_facility: false,
          hotel_tourism: false,
          /* Commercial Healthcare */
          healthcare_disable: false,
          healthcare: false,
          pharmacy: false,
          medical_center: false,
          nursing_home: false,
          hospital: false,
          /* Commercial Garage/Parking */
          garage_parking_disable: false,
          garage_parking: false,
          parking_space: false,
          single_garage: false,
          multiple_garages: false,
          parking_lot: false,
          multi_storey_parking: false,
          /* Commercial Multifamily */
          multifamily_disable: false,
          multifamily: false,
          duplex: false,
          block_of_flats: false,
          apartment_complex: false,
          /* Industrial Warehouse */
          warehouses_disable: false,
          warehouses: false,
          /* Industrial Factory */
          factories_disable: false,
          factories: false,
          /* Commercial Powerplant */
          power_plants_disable: false,
          power_plants: false,
          hydroelectric_plant: false,
          coal_fired_plant: false,
          diesel_fired_plant: false,
          geothermal_plant: false,
          gas_fired_plant: false,
          solar_power_plant: false,
          wind_power_plant: false,
          tidal_power_plant: false,
          /* Land */
          land_disable: false,
          land: false,
          greenfield: false,
          brownfield: false,
          /* Luxury */
          luxury_disable: false,
          luxury: false,
          mansion: false,
          villa: false,
          /* Special Use */
          special_use_disable: false,
          special_use: false,
          church: false,
          cemetery: false,
          park: false,
          school: false,
          barn: false,
          campsite: false,
          /* Rooms */
          rooms_disable: false,
          rooms: false,
          single_room: false,
          double_room: false
        },
        /* End Property Types */
        /* Date Picker */
        date_range: {
          start: null,
          end: null
        },
        /* For Working Hours */
        working_hours_from: null,  // new Date(this.moment.utc().set("hour", 8).set("minute", 0).format('YYYY-MM-DD HH:mm:ss')),
        working_hours_to: null,  // new Date(this.moment.utc().set("hour", 17).set("minute", 0).format('YYYY-MM-DD HH:mm:ss')),
        working_hours_from_flexible: false,
        working_hours_to_flexible: false,
        // For check_in_to and check_out_to values
        date_range_to: {
          start: null,
          end: null,
        },
        date_start: null,
        date_end: null,
        date_start_to: null,
        date_end_to: null,
        is_date_range_end_tbd: false,
        show_range_date: true,
        single_start_date: null,
        check_in_flexible: false,
        check_out_flexible: false,
        check_in_from: null,
        check_in_to: null,
        check_out_from: null,
        check_out_to: null,
        check_in_instructions_id: '',
        check_out_instructions_id: '',
        /* End Date Picker */
        name: '',
        surname: '',
        company_name: '',
        show_company_name: false,
        landing_prefix: '',
        landing: '',
        show_landing: false,
        mobile_prefix: '',
        mobile: '',
        show_mobile: true,
        email: '',
        show_email: false,
        /* End of property contact details */
        contact_type_id: '', // Should be pre-populated with the id in controller (this should be added during creation of account)
        property_category_id: '',
        property_type_id: '',
        property_type_details_id: '',
        property_details_id: '',
        property_address_id: '', // Should be pre-populated with the id of below address data (should happen in controller really)
        /* Property address details */
        address_type_id: '', // Should be pre-populated with Property (id = 2 if I am correct - do it in controller)
        full_address: '',
        postcode: '',
        street: '',
        line_1: '',
        line_2: '',
        city: '',
        county: '',
        country: '',
        longitude: '',
        latitude: '',
        boundary_box: '',
        /* End of property address details */
        /* Property Contact Address */
        prop_contact_full_address: '',
        prop_contact_postcode: '',
        prop_contact_street: '',
        prop_contact_line_1: '',
        prop_contact_line_2: '',
        prop_contact_city: '',
        prop_contact_county: '',
        prop_contact_country: '',
        prop_contact_longitude: '',
        prop_contact_latitude: '',
        prop_contact_boundary_box: '',
        show_address: false,
        /* End Property Contact Address */
        property_title: '',
        property_description: '',
        /* Start of property details */
        property_id: '', // Should be set in controller really
        easements: false,
        epc_rating: '',
        floor_plans: false,
        floors: '',
        basement: false,
        attic: false,
        balcony: false,
        front_porch: false,
        rear_porch: false,
        front_garden: false,
        rear_garden: false,
        garden_terrace: false,
        conservatory: false,
        green_house: '',
        shed: '',
        garage: '',
        driveway: '',
        parking: false,
        parking_paid: false,
        parking_fee: '',
        parking_spaces: '',
        charging_station: false,
        charging_station_paid: false,
        solar_panels: false,
        room: '', // Used for edit rooms #look at editRoom() function - it should hold current room data
        rooms: [],
        single_room_suite: '',
        double_room_suite: '',
        single_room: '',
        double_room: '',
        twin_room: '',
        twin_double_room: '',
        triple_room: '',
        quadruple_room: '',
        family_room: '',
        studio_room: 0,
        box_room: '',
        living_room: '',
        dining_room: '',
        kitchen: '',
        fridge: false,
        freezer: false,
        microwave: false,
        oven: false,
        kettle: false,
        coffee: false,
        toaster: false,
        bath_room: '',
        bath: '',
        shower: '',
        sink: '',
        wc: '',
        downstairs_wc: '',
        inside_pool: false,
        inside_pool_paid: false,
        outside_pool: false,
        outside_pool_paid: false,
        sauna: false,
        sauna_paid: false,
        hot_tub: false,
        hot_tub_paid: false,
        spa: false,
        spa_paid: false,
        gym: false,
        gym_paid: false,
        cinema_room: false,
        cinema_room_paid: false,
        bicycle: false,
        bicycle_paid: false,
        wifi: false,
        wifi_paid: false,
        restaurant: false,
        room_service: false,
        room_service_paid: false,
        service_fee: '',
        bar: false,
        h24_front_desk: false,
        non_smoking_rooms: false,
        smoking_rooms: false,
        airport_shuttle: false,
        air_conditioning: false,
        breakfast: false,
        breakfast_paid: false,
        stove: false,
        security_cameras: false,
        fire_rings: false, // Campsite as well
        fire_rings_paid: false, // Campsite as well
        other: '',
        /* Start Campsite */
        tents: '',
        caravans: '',
        hammocks: false,
        campsite_shower: false,
        campsite_shower_paid: false,
        campsite_shower_fee: '',
        campsite_toilet: false,
        campsite_toilet_paid: false,
        campsite_toilet_fee: '',
        electricity: false,
        electricity_paid: false,
        water_supply: false,
        water_supply_paid: false,
        fire_fuels: false,
        fire_fuels_paid: false,
        /* End Campsite */
        /* End of property details */
        cancellation_period: '',
        cancellation_late_rule_id: '',
        cancellation_fee: '',
        cancellation_rule: {
          rule: null
        },
        // Term Discount
        is_discount_term_term: false,
        discount_term_term: '',
        discount_fee_type_term: '1',
        discount_fee_term: '',
        refreshTermDiscountFeePlaceholder: 0,
        refreshLastMinuteDiscountFeePlaceholder: 0,
        refreshNoOfGuestsDiscountFeePlaceholder: 0,
        // Last Minute Discount
        is_discount_term_last_minute: false,
        discount_term_last_minute: '',
        discount_fee_type_last_minute: '1',
        discount_fee_last_minute: '',
        // Number of Guests Discount
        is_discount_term_no_of_guests: false,
        discount_term_no_of_guests: '',
        discount_fee_type_no_of_guests: '1',
        discount_fee_no_of_guests: '',
        // House Rules must be stored as an array of strings (json ?)
        house_rules: [],
        rule: '',
        cleaning_service: false,
        cleaning_service_paid: false,
        cleaning_fee: '',
        star_rating: '',
        property_square_meter: '',
        price: '',
        tenants_guests: '',
        // offer: '', // -
        // sold_price: '', // -
        // rating_id: '', // -
        document_id: '',
        property_history_id: '',
        is_coming_soon: false,
        is_sale: false,
        is_swap: false,
        property_rent_type_id: '',
        property_rent_type: {
          id: null,
          type: null
        },
        is_rent: false,
        is_let: false,
        is_buy: false,
        is_short_term: false,
        is_residential: false,
        is_commercial: false,
        is_industrial: false,
        is_luxury: false,
        is_land: false,
        is_special_use: false,
        is_room: false,
        is_furnished: false,
        is_freehold: '',
        is_shared_ownership: false,
        is_over_60s: false,
        is_accessible: false,
        is_pet_friendly: false,
        pet_friendly_paid: false,
        pet_friendly_fee: '',
        events_allowed: false,
        is_dss_accepted: false,
        is_holiday: false,
        is_seashore: false,
        is_waterfront: false,
        is_mixed_use: false,
        is_investment: false,
        // is_buy_to_let: false,
        /* Start of Investment Details */
        roi: '',
        roa: '',
        yield: '',
        cash_flow: '',
        share_percentage: '',
        maintenance_cost: '',
        number_of_flats: '',
        number_of_blocks: '',
        number_of_units: '',
        /* End of Investment Details */
        investment_property_id: '',
        is_hmo: false,
        is_tenanted: false,
        is_cash: false,
        is_auction: false,
        // is_under_offer: false, // -
        // is_sold: false, // -
        is_published: false,
        is_completed: false,
        is_deleted: false,
        is_free_ad: '',
        is_paid_ad: '',
        created_by: '',
        updated_by: '',
        deleted_by: '',
        // Extra bits necessary in form for previewAdDetails Component
        propertyCatType: 'Select premises type',
        propertyCategory: '',
        previewPropImages: [],
        previewFloorplanImages: [],
        propImages: [],
        floorplanImages: [],
        editPropImages: [],
        editFloorplanImages: [],
        declaration_of_truth: false
      },
      titleOptions: [
        { value: '1', text: 'Mr' },
        { value: '2', text: 'Mrs' },
        { value: '3', text: 'Miss' },
        { value: '4', text: 'Ms' }
      ],
      validationErrors: '',
      validationMessage: '',
      veeValidationErrors: '',
      veeValidationMessage: '',
      validationCardMessage: '',
      changed_dates: 0,
      changed_singular_date: 9999,
      disabledDates: [],
      validHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      defaultValidHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      processing: false,
      editing: false,
      editLoaded: false,
      editDataLoaded: false,
      editData: '',
      is_manual_address: false,
      postType: '',
      /* Main Level Dropdown - Holds property categories */
      propertyCategoriesOptions: [],
      propertyTypesOptions: [],
        /* First Level Dropdown - Holds property types according to property categories - skipped room as no subitems */
        propResidentialTypesOptions: [],
        propCommercialTypesOptions: [],
        propIndustrialTypesOptions: [],
        propLandTypesOptions: [],
        propLuxuryTypesOptions: [],
        propSpecialUseTypesOptions: [],
          /* Second Level Dropdown - Holds property type details according to property types */
          // Residential
          propHouseTypeDetails: [],
          propFlatTypeDetails: [],
          // Commercial
          propOfficeTypeDetails: [],
          propRetailTypeDetails: [],
          propLeisureTypeDetails: [],
          propHealthcareTypeDetails: [],
          propParkingTypeDetails: [],
          propMultifamilyTypeDetails: [],
          // Industrial (we skipped Warehouse and Factory as no subcategories)
          propPowerPlantTypeDetails: [],
      propId: '',
      loading: true,
      firstLoad: this.editing ? true : false,
      firstLoadShowRangeDate: this.editing ? true : false,
      // Validation Statuses
      basicDetailsValid: false,
      recomputeBasicDetails: 0,
      premisesDetailsValid: false,
      recomputePremisesDetails: 0,
      premisesStatusValid: false,
      recomputePremisesStatus: 0,
      servicesAndAmenitiesValid: false,
      recomputeServicesAndAmenities: 0,
      availabilityValid: false,
      recomputeAvailability: 0,
      contactDetailsValid: false,
      recomputeContactDetails: 0
    }
  },
  computed:{
    attr() {
      return [
        // {
        //   highlight:'red',
        //   dates: this.disabledDates,
        // },

        {
          key: 'today',
          highlight: { color: 'green', fillMode: 'outline'},
          dates: new Date(),
        }
      ];

    },
    checkShortTermOrRoom: function() {
      return !this.form.is_short_term && !this.form.is_room;
    },
    // Start Sections Validation
    checkBasicInfoValidation: function() {
      let counter = this.recomputeBasicDetails;
      if (this.validationErrors) {
        let keys = ['property_title', 'full_address', 'postcode'];
        for (let i = 0; i < keys.length; i++) {
          if (this.checkIfKeyExists(this.validationErrors, keys[i])) {
            return true;
          }
          if (i === keys.length - 1) {
            return false;
          }
        }
      } else {
        if (this.basicDetailsValid === true) {
          return false;
        } else {
          return true;
        }
      }
    },
    checkPremisesDetailsValidation: function() {
      let counter = this.recomputePremisesDetails;
      if (this.validationErrors) {
        let keys = ['price', 'tenants_guests', 'property_rent_type_id', 'tenure', 'epc_rating', 'rooms'];
        for (let i = 0; i < keys.length; i++) {
          if (this.checkIfKeyExists(this.validationErrors, keys[i])) {
            return true;
          }
          if (i === keys.length - 1) {
            return false;
          }
        }
      } else {
        if (this.premisesDetailsValid === true) {
          return false;
        } else {
          return true;
        }
      }
    },
    checkPremisesStatusValidation: function() {
      let counter = this.recomputePremisesStatus;
      // Only on LET short-term (check for pet fees)
      if (this.validationErrors && this.form.is_short_term && this.form.is_pet_friendly && this.form.pet_friendly_paid) {
        let keys = ['pet_friendly_fee'];
        for(let i = 0; i < keys.length; i++) {
          if (this.checkIfKeyExists(this.validationErrors, keys[i])) {
            return true;
          }
          if (i === keys.length - 1) {
            return false;
          }
        }
      } else {
        if (this.premisesStatusValid === true) {
          return false;
        } else {
          return true;
        }
      }
    },
    checkServicesAmenitiesValidation: function() {
      let counter = this.recomputeServicesAndAmenities;
      // Only on LET short-term (check for service and cleaning fees)
      if (this.form.is_short_term &&
          (
              (this.form.room_service && this.form.room_service_paid) ||
              (this.form.cleaning_service && this.form.cleaning_service_paid)
          )
      ) {
        if (this.validationErrors) {
          let keys = ['service_fee', 'cleaning_fee'];
          for (let i = 0; i < keys.length; i++) {
            if (this.checkIfKeyExists(this.validationErrors, keys[i])) {
              return true;
            }
            if (i === keys.length - 1) {
              return false;
            }
          }
        } else {
          if (this.servicesAndAmenitiesValid === true) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    },
    checkAvailabilityValidation: function() {
      let counter = this.recomputeAvailability;
      if (this.validationErrors) {
        let keys = ['check_in_instructions_id', 'check_out_instructions_id', 'cancellation_fee', 'discount_fee_term',
        'discount_fee_last_minute', 'discount_fee_no_of_guests'];
        for (let i = 0; i < keys.length; i++) {
          if (this.checkIfKeyExists(this.validationErrors, keys[i])) {
            return true;
          }
          if (i === keys.length - 1) {
            return false;
          }
        }
      } else {
        if (this.availabilityValid === true) {
          return false;
        } else {
          return true;
        }
      }
    },
    checkContactDetailsValidation: function() {
      let counter = this.recomputeContactDetails;
      if (this.validationErrors) {
        let keys = ['title', 'name', 'surname', 'mobile', 'mobile_prefix', 'email', 'show_mobile', 'show_email'];
        for (let i = 0; i < keys.length; i++) {
          if (this.checkIfKeyExists(this.validationErrors, keys[i])) {
            return true;
          }
          if (i === keys.length - 1) {
            return false;
          }
        }
      } else {
        if (this.contactDetailsValid === true) {
          return false;
        } else {
          return true;
        }
      }
    },
    // End Sections Validation
  },
  methods: {
    // Helper functions for Sections Validation
    checkIfKeyExists(object, keyName) {
        let keyExist = Object.keys(object).some(key => key === keyName);
        return keyExist;
    },
    async validateAll() {
      await this.validateBasicDetails();
      await this.validatePremisesDetails();
      await this.validatePremisesStatus();
      await this.validateServicesAndAmenities();
      await this.validateAvailability();
      await this.validateContactDetails();
      // EXCEPT payment validation
    },
    async validateBasicDetails() {
      if (this.$refs.basicDetailsFormObserver) {
        let validate = await this.$refs.basicDetailsFormObserver.validate().then(function (data) {
          return data;
        });
        if (validate) {
          this.basicDetailsValid = true;
        } else {
          this.basicDetailsValid = false;
        }
      } else {
        this.basicDetailsValid = false;
      }
      this.recomputeBasicDetails++;
    },
    async validatePremisesDetails() {
      if (this.$refs.premisesDetailsFormObserver) {
        let validate = await this.$refs.premisesDetailsFormObserver.validate().then(function (data) {
          return data;
        });
        if (validate) {
          if (this.form.is_room || (this.form.is_short_term && !this.form.studio_room)) {
            if (this.form.rooms && this.form.rooms.length < 1) {
              this.premisesDetailsValid = false;
            } else {
              this.premisesDetailsValid = true;
            }
          } else {
            this.premisesDetailsValid = true;
          }
        } else {
          this.premisesDetailsValid = false;
        }
      } else {
        this.premisesDetailsValid = false;
      }
      this.recomputePremisesDetails++;
    },
    async validatePremisesStatus() {
      if (this.$refs.premisesStatusFormObserver) {
        if (this.form.is_short_term && this.form.is_pet_friendly && this.form.pet_friendly_paid) {
          let validate = await this.$refs.premisesStatusFormObserver.validate().then(function (data) {
            return data;
          });
          if (validate) {
            this.premisesStatusValid = true;
          } else {
            this.premisesStatusValid = false;
          }
          // When we dont see pet friendly fee no validation check for them
        } else {
          this.premisesStatusValid = true;
        }
      } else {
        this.premisesStatusValid = true;
      }
      this.recomputePremisesStatus++;
    },
    async validateServicesAndAmenities() {
      if (this.$refs.servicesAndAmenitiesFormObserver) {
        if (this.form.is_short_term &&
            (
                (this.form.room_service && this.form.room_service_paid) ||
                (this.form.cleaning_service && this.form.cleaning_service_paid)
            )
        ) {
          let validate = await this.$refs.servicesAndAmenitiesFormObserver.validate().then(function (data) {
            return data;
          });
          if (validate) {
            this.servicesAndAmenitiesValid = true;
          } else {
            this.servicesAndAmenitiesValid = false;
          }
          // When we dont see either service fee or cleaning fee no validation check for them
        } else {
          this.servicesAndAmenitiesValid = true;
        }
      } else {
        this.servicesAndAmenitiesValid = true;
      }
      this.recomputeServicesAndAmenities++;
    },
    async validateAvailability() {
      if (this.$refs.availabilityFormObserver) {
        let validate = await this.$refs.availabilityFormObserver.validate().then(function (data) {
          return data;
        });
        // To see errors
        // console.log(this.$refs.availabilityFormObserver.errors);
        if (validate) {
          this.availabilityValid = true;
        } else {
          this.availabilityValid = false;
        }
      } else {
        this.availabilityValid = false;
      }
      this.recomputeAvailability++;
    },
    async validateContactDetails() {
      if (this.$refs.contactDetailsFormObserver) {
        let validate = await this.$refs.contactDetailsFormObserver.validate().then(function (data) {
          return data;
        });
        if (validate) {
          this.contactDetailsValid = true;
        } else {
          this.contactDetailsValid = false;
        }
      } else {
        this.contactDetailsValid = false;
      }
      this.recomputeContactDetails++;
    },
    // End Helper functions for Sections Validation
    sendModal(type = null) {
      if (type === 'new') {
        this.form.room = '';
      }
      this.$root.$emit('openModal', this.form, 'Add Room');
    },
    // Add House Rules Actions
    addRule() {
      if (this.form.rule && this.form.rule !== '') {
        let ruleId = 1;
        if (this.form.house_rules && this.form.house_rules.length > 0) {
          let ids = this.form.house_rules.map(object => {
            return object.id;
          });
          let max = Math.max.apply(null, ids);
          ruleId = max + 1;
        } else {
          ruleId = 1;
        }
        this.form.house_rules.push({id: ruleId, rule: this.form.rule});
        this.form.rule = '';
      }
    },
    async deleteRule(rule) {
      this.processing = true;

      // Do axios to remove from DB if exists (check by property_id, user_id and room_id)
      if (this.id && rule.property_id) {
        let data = new FormData();
        data.append("id", rule.id);
        data.append("user_id", this.$store.state.auth.user.id);
        data.append("property_id", rule.property_id);

        await axios.post('api/rule/delete', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          this.validationErrors = null;
          this.validationMessage = null;

          // Remove Rule from house_rules[];
          let newRooms = this.form.house_rules.filter(data => data.id != rule.id);
          this.form.house_rules = newRooms;

          this.processing = false
        }).catch(({response}) => {
          if (response && response.data && response.data.errors) {
            this.validationErrors = response.data.errors;
            this.validationMessage = response.data.message;
            this.processing = false
          }
        });
      } else {
        // Remove Rule from house_rules[];
        let newHouseRules = this.form.house_rules.filter(data => data.id != rule.id);
        this.form.house_rules = newHouseRules;

        this.processing = false;
      }
    },
    // Add Rooms Actions
    addRoom(room) {
      // Add/Update Room in rooms[];
      if (!room.id || room.id === '' || room.id === null || room.id === undefined || isNaN(room.id)) {
        if (this.form.rooms && this.form.rooms.length > 0) {
          let ids = this.form.rooms.map(object => {
            return object.id;
          });
          let max = Math.max.apply(null, ids);
          room.id = max + 1;
        } else {
          room.id = 1;
        }

        // Add New Room
        this.$set(this.form.rooms, this.form.rooms.length, room);

        // Calculate rooms
        this.calculateRooms(room, 'add');

        // Clear room
        this.form.room = '';
      } else {

        // Update Room
        for (let i = 0; i < this.form.rooms.length; i++) {
          if (this.form.rooms[i].id === room.id) {
            // Calculate rooms
            this.calculateRooms(room, 'edit', this.form.rooms[i]);
            // Update room
            this.$set(this.form.rooms, i, room);
          }
        }

        // Clear room
        this.form.room = '';
      }
    },
    editRoom(room) {
      // Existing rooms are updated in the backend so they can be send along with the new rooms
      this.form.room = {...room};
      this.sendModal();
    },
    async deleteRoom(room) {
      this.processing = true;
      // Do axios to remove from DB if exists (check by property_id, user_id and room_id)
      if (this.id && room.property_id) {
        let data = new FormData();
        data.append("id", room.id);
        data.append("user_id", this.$store.state.auth.user.id);
        data.append("property_id", room.property_id);

        await axios.post('api/room/delete', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          this.validationErrors = null;
          this.validationMessage = null;

          // Calculate rooms
          this.calculateRooms(room, 'sub');

          // Remove Room from rooms[];
          let newRooms = this.form.rooms.filter(data => data.id != room.id);
          this.form.rooms = newRooms;

          this.processing = false;
        }).catch(({response}) => {
          if (response && response.data && response.data.errors) {
            this.validationErrors = response.data.errors;
            this.validationMessage = response.data.message;
            this.processing = false;
          }
        });
      } else {
        // Calculate rooms
        this.calculateRooms(room, 'sub');

        // Remove Room from rooms[];
        let newRooms = this.form.rooms.filter(data => data.id != room.id);
        this.form.rooms = newRooms;

        this.processing = false
      }
    },
    calculateRooms(room, operation, oldRoom = null) {
      // Edit
      if (operation === 'edit' && oldRoom) {
        // First remove oldRoom
        switch(oldRoom.property_room_type.type) {
          case 'Single':
            this.form.single_room = this.form.single_room === 0 ? 0 : Number(this.form.single_room) - 1;
            break;
          case 'Double':
            this.form.double_room = this.form.double_room === 0 ? 0 : Number(this.form.double_room) - 1;
            break;
          case 'Twin':
            this.form.twin_room = this.form.twin_room === 0 ? 0 : Number(this.form.twin_room) - 1;
            break;
          case 'Twin/Double':
            this.form.twin_double_room = this.form.twin_double_room === 0 ? 0 : Number(this.form.twin_double_room) - 1;
            break;
          case 'Triple':
            this.form.triple_room = this.form.triple_room === 0 ? 0 : Number(this.form.triple_room) - 1;
            break;
          case 'Quadruple':
            this.form.quadruple_room = this.form.quadruple_room === 0 ? 0 : Number(this.form.quadruple_room) - 1;
            break;
          case 'Family':
            this.form.family_room = this.form.family_room === 0 ? 0 : Number(this.form.family_room) - 1;
            break;
          case 'Suite/Apartment':
            this.form.single_room_suite = oldRoom.single_beds > 0 ? Number(this.form.single_room_suite) - Number(oldRoom.single_beds) : this.form.single_room_suite;
            this.form.double_room_suite = oldRoom.double_beds > 0 ? Number(this.form.double_room_suite) - Number(oldRoom.double_beds) : this.form.double_room_suite;
            break;
          default:
            this.form.single_room_suite = '';
            this.form.double_room_suite = '';
            this.form.single_room = '';
            this.form.double_room = '';
            this.form.twin_room = '';
            this.form.twin_double_room = '';
            this.form.triple_room = '';
            this.form.quadruple_room = '';
            this.form.family_room = '';
            break;
        }

        // Then apply new room data
        switch(room.property_room_type.type) {
          case 'Single':
            this.form.single_room = this.form.single_room === '' ? 1 : Number(this.form.single_room) + 1;
            break;
          case 'Double':
            this.form.double_room = this.form.double_room === '' ? 1 : Number(this.form.double_room) + 1;
            break;
          case 'Twin':
            this.form.twin_room = this.form.twin_room === '' ? 1 : Number(this.form.twin_room) + 1;
            break;
          case 'Twin/Double':
            this.form.twin_double_room = this.form.twin_double_room === '' ? 1 : Number(this.form.twin_double_room) + 1;
            break;
          case 'Triple':
            this.form.triple_room = this.form.triple_room === '' ? 1 : Number(this.form.triple_room) + 1;
            break;
          case 'Quadruple':
            this.form.quadruple_room = this.form.quadruple_room === '' ? 1 : Number(this.form.quadruple_room) + 1;
            break;
          case 'Family':
            this.form.family_room = this.form.family_room === '' ? 1 : Number(this.form.family_room) + 1;
            break;
          case 'Suite/Apartment':
            this.form.single_room_suite = room.single_beds > 0 ? Number(this.form.single_room_suite) + Number(room.single_beds) : this.form.single_room_suite;
            this.form.double_room_suite = room.double_beds > 0 ? Number(this.form.double_room_suite) + Number(room.double_beds) : this.form.double_room_suite;
            break;
          default:
            this.form.single_room_suite = '';
            this.form.double_room_suite = '';
            this.form.single_room = '';
            this.form.double_room = '';
            this.form.twin_room = '';
            this.form.twin_double_room = '';
            this.form.triple_room = '';
            this.form.quadruple_room = '';
            this.form.family_room = '';
            break;
        }


        // Add or Substract
      } else if (room && room.property_room_type && room.property_room_type.type) {
        switch(room.property_room_type.type) {
          case 'Single':
            if (operation === 'sub') {
              this.form.single_room = this.form.single_room === 0 ? 0 : Number(this.form.single_room) - 1;
            }
            if (operation === 'add') {
              this.form.single_room = this.form.single_room === '' ? 1 : Number(this.form.single_room) + 1;
            }
            break;
          case 'Double':
            if (operation === 'sub') {
              this.form.double_room = this.form.double_room === 0 ? 0 : Number(this.form.double_room) - 1;
            }
            if (operation === 'add') {
              this.form.double_room = this.form.double_room === '' ? 1 : Number(this.form.double_room) + 1;
            }
            break;
          case 'Twin':
            if (operation === 'sub') {
              this.form.twin_room = this.form.twin_room === 0 ? 0 : Number(this.form.twin_room) - 1;
            }
            if (operation === 'add') {
              this.form.twin_room = this.form.twin_room === '' ? 1 : Number(this.form.twin_room) + 1;
            }
            break;
          case 'Twin/Double':
            if (operation === 'sub') {
              this.form.twin_double_room = this.form.twin_double_room === 0 ? 0 : Number(this.form.twin_double_room) - 1;
            }
            if (operation === 'add') {
              this.form.twin_double_room = this.form.twin_double_room === '' ? 1 : Number(this.form.twin_double_room) + 1;
            }
            break;
          case 'Triple':
            if (operation === 'sub') {
              this.form.triple_room = this.form.triple_room === 0 ? 0 : Number(this.form.triple_room) - 1;
            }
            if (operation === 'add') {
              this.form.triple_room = this.form.triple_room === '' ? 1 : Number(this.form.triple_room) + 1;
            }
            break;
          case 'Quadruple':
            if (operation === 'sub') {
              this.form.quadruple_room = this.form.quadruple_room === 0 ? 0 : Number(this.form.quadruple_room) - 1;
            }
            if (operation === 'add') {
              this.form.quadruple_room = this.form.quadruple_room === '' ? 1 : Number(this.form.quadruple_room) + 1;
            }
            break;
          case 'Family':
            if (operation === 'sub') {
              this.form.family_room = this.form.family_room === 0 ? 0 : Number(this.form.family_room) - 1;
            }
            if (operation === 'add') {
              this.form.family_room = this.form.family_room === '' ? 1 : Number(this.form.family_room) + 1;
            }
            break;
          case 'Suite/Apartment':
            if (operation === 'sub') {
              this.form.single_room_suite = room.single_beds > 0 ? Number(this.form.single_room_suite) - Number(room.single_beds) : this.form.single_room_suite;
              this.form.double_room_suite = room.double_beds > 0 ? Number(this.form.double_room_suite) - Number(room.double_beds) : this.form.double_room_suite;
            }
            if (operation === 'add') {
              this.form.single_room_suite = room.single_beds > 0 ? Number(this.form.single_room_suite) + Number(room.single_beds) : this.form.single_room_suite;
              this.form.double_room_suite = room.double_beds > 0 ? Number(this.form.double_room_suite) + Number(room.double_beds) : this.form.double_room_suite;
            }
            break;
          default:
            this.form.single_room_suite = '';
            this.form.double_room_suite = '';
            this.form.single_room = '';
            this.form.double_room = '';
            this.form.twin_room = '';
            this.form.twin_double_room = '';
            this.form.triple_room = '';
            this.form.quadruple_room = '';
            this.form.family_room = '';
            break;
        }
      }
    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    async getDrafts() {
      let formData = new FormData();
      formData.append('user_id', this.$store.state.auth.user.id);
      formData.append('type', 'ad premises');

      await axios.post('api/drafts', formData).then(({data})=>{
        this.drafts = data;
        this.validationErrors = null;
        this.processing = false;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        this.processing = false;
      }).finally(()=>{
        this.processing = false;
      })
    },
    async getSelectedDraft(draft_id) {
      if (draft_id === null || draft_id === '') {
        return;
      }
      let formData = new FormData();
      formData.append('user_id', this.$store.state.auth.user.id);
      formData.append('type', 'ad premises');
      formData.append('draft_id', draft_id);

      await axios.post('api/draft/get', formData).then(({data})=>{
        // Get draft data
        this.selectedDraft = data[0];
        // Set it as draft load so that watchers won't reset stuff unnecessarily
        this.draftLoad = true;
        // Assign draft data to form
        if (this.selectedDraft.data.is_sale) {
          this.postType = 1;
        }
        if (this.selectedDraft.data.is_buy) {
          this.postType = 2;
        }
        if (this.selectedDraft.data.is_let) {
          this.postType = 3;
        }
        if (this.selectedDraft.data.is_rent) {
          this.postType = 4;
        }
        this.form = this.selectedDraft.data;
        this.validationErrors = null;
        this.processing = false;
        const timeout = setTimeout(() => {
          this.draftLoad = false;
          clearTimeout(timeout);
        }, 1000);
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        this.processing = false;
      }).finally(()=>{
        this.processing = false;
      })
    },
    // Load all necessary data of component
    async initialComponentLoad() {

      /* START EDIT AD RELATED */
      // Checks for 'edit' ad rather than 'create' ad
      if (this.id && this.id !== '' && this.id !== 'undefined') {
        this.editing = true;
        // Special flags so that watchers do not recompute stuff too early
        this.firstLoad = true;
        this.firstLoadShowRangeDate = true;

        await axios.get('api/properties/' + this.id + '/edit').then(({data}) => {
          // console.log(data);
          // We use that to #addressPrepopulated and #propertyCatTypePrepopulated
          this.editData = data;
          // Here I need to assign all the data to the form data... in a relevant way
          // How to do that in a nice way?... it would be painful to go one by one, with multiple if statements...

          // Assign addresses manually as mapbox query() and setInput() does not help at all... due to suggestions window popping up
          // Assign Property Address
          if (data.data && data.data.address) {
            Object.keys(data.data.address).forEach((key) => {
              // console.log('Key : ' + key + ', Value : ' + data.data.address[key])
              if (key === 'full_address') {
                this.form.full_address = data.data.address[key];
              }
              if (key === 'postcode') {
                this.form.postcode = data.data.address[key];
              }
              if (key === 'line_2') {
                this.form.line_2 = data.data.address[key];
              }
              if (key === 'city') {
                this.form.city = data.data.address[key];
              }
              if (key === 'county') {
                this.form.county = data.data.address[key];
              }
              if (key === 'country') {
                this.form.country = data.data.address[key];
              }
              if (key === 'street') {
                this.form.street = data.data.address[key];
              }
              if (key === 'line_1') {
                this.form.line_1 = data.data.address[key];
              }
              if (key === 'longitude') {
                this.form.longitude = data.data.address[key];
              }
              if (key === 'latitude') {
                this.form.latitude = data.data.address[key];
              }
              if (key === 'boundary_box') {
                this.form.boundary_box = data.data.address[key];
              }
            });
          }

          // Assign Property Contact Address
          if (data.data && data.data.contact && data.data.contact.address) {
            Object.keys(data.data.contact.address).forEach((key) => {
              // console.log('Key : ' + key + ', Value : ' + data.data.contact.address[key])
              if (key === 'full_address') {
                this.form.prop_contact_full_address = data.data.contact.address[key];
              }
              if (key === 'postcode') {
                this.form.prop_contact_postcode = data.data.contact.address[key];
              }
              if (key === 'street') {
                this.form.street = data.data.address[key];
              }
              if (key === 'line_2') {
                this.form.prop_contact_line_2 = data.data.contact.address[key];
              }
              if (key === 'city') {
                this.form.prop_contact_city = data.data.contact.address[key];
              }
              if (key === 'county') {
                this.form.prop_contact_county = data.data.contact.address[key];
              }
              if (key === 'country') {
                this.form.prop_contact_country = data.data.contact.address[key];
              }
              if (key === 'street') {
                this.form.prop_contact_street = data.data.contact.address[key];
              }
              if (key === 'line_1') {
                this.form.prop_contact_line_1 = data.data.contact.address[key];
              }
              if (key === 'longitude') {
                this.form.prop_contact_longitude = data.data.contact.address[key];
              }
              if (key === 'latitude') {
                this.form.prop_contact_latitude = data.data.contact.address[key];
              }
              if (key === 'boundary_box') {
                this.form.prop_contact_boundary_box = data.data.contact.address[key];
              }
            });
          }

          // Assign Property Data
          Object.keys(data.data).forEach((key) => {
            // console.log('Key : ' + key + ', Value : ' + data.data[key])
            Object.keys(this.form).forEach((formKey) => {
              // Assign postType correctly based on correct type of advertise
              if((key === 'is_sale' || key === 'is_rent' || key === 'is_let' || key === 'is_buy') && key === formKey) {
                this.form[formKey] = data.data[key];
                // #SkipWatchEdit Below we are setting postType which would trigger 'watch' and reset some values, hence
                // we resolved it by creating editDataLoaded property and toggling it from false to true
                if(key === 'is_sale' && data.data[key] === 1) {
                  this.postType = 1;
                } else if(key === 'is_buy' && data.data[key] === 1) {
                  this.postType = 2;
                } else if(key === 'is_let' && data.data[key] === 1) {
                  this.postType = 3;
                } else if(key === 'is_rent' && data.data[key] === 1) {
                  this.postType = 4;
                }
                // Assign all form data to form keys
              } else if(key === formKey) {
                this.form[formKey] = data.data[key];

                // Assign date ranges
                if(key === 'is_date_range_end_tbd' && key === formKey) {
                  this.form.is_date_range_end_tbd = data.data.is_date_range_end_tbd;
                  if(data.data.is_date_range_end_tbd === true || data.data.is_date_range_end_tbd === 1 ||
                      (data.data.is_short_term && data.data.is_let === 1)
                  ) {
                    this.form.single_start_date = new Date(this.moment.utc(data.data.date_start).format('YYYY-MM-DD'));
                  }
                }

                if(key === 'date_start' && key === formKey) {
                  // this.form.date_range.start = this.moment(data.data.date_start).format('DD-MM-YYYY');
                  if(data.data.date_start === null) {
                    this.form.date_start = null;
                  } else {
                    this.form.date_start = this.moment.utc(data.data.date_start).format('DD-MM-YYYY');
                  }
                }
                if(key === 'date_end' && key === formKey) {
                  // this.form.date_range.end = this.moment(data.data.date_end).format('DD-MM-YYYY');
                  if(data.data.date_end === null) {
                    this.form.date_end = null;
                  } else {
                    this.form.date_end = this.moment.utc(data.data.date_end).format('DD-MM-YYYY');
                  }
                }

                // Assign check in and out dates (times) - note that we need singular_date being assigned before that
                // And that we need to consider whether values is 'Flexible' due to is_flexible toggles
                if(key === 'check_in_from' && key === formKey) {
                  if(data.data.check_in_from === null || data.data.check_in_from === 'Flexible') {
                    this.form.check_in_from = null;
                    this.firstLoad = false;
                  } else {
                    this.form.check_in_from = new Date(this.moment.utc(data.data.check_in_from, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
                  }
                }
                if(key === 'check_in_to' && key === formKey) {
                  if(data.data.check_in_to === null || data.data.check_in_to === 'Flexible') {
                    this.form.check_in_to = null;
                    this.firstLoad = false;
                  } else {
                    this.form.check_in_to = new Date(this.moment.utc(data.data.check_in_to, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
                  }
                }
                if(key === 'check_out_from' && key === formKey) {
                  if(data.data.check_out_from === null || data.data.check_out_from === 'Flexible') {
                    this.form.check_out_from = null;
                    this.firstLoad = false;
                  } else {
                    this.form.check_out_from = new Date(this.moment.utc(data.data.check_out_from, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
                  }
                }
                if(key === 'check_out_to' && key === formKey) {
                  if(data.data.check_out_to === null || data.data.check_out_to === 'Flexible') {
                    this.form.check_out_to = null;
                    this.firstLoad = false;
                  } else {
                    this.form.check_out_to = new Date(this.moment.utc(data.data.check_out_to, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
                  }
                }

                // Assign working_hours_from and to dates (times) - note that we need singular_date being assigned before that
                // And that we need to consider whether values is 'Flexible' due to is_flexible toggles
                if(key === 'working_hours_from' && key === formKey) {
                  if(data.data.working_hours_from === null || data.data.working_hours_from === 'Flexible') {
                    this.form.working_hours_from = null;
                    // this.firstLoad = false;
                  } else {
                    this.form.working_hours_from = new Date(this.moment.utc(data.data.working_hours_from, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
                  }
                }
                if(key === 'working_hours_to' && key === formKey) {
                  if(data.data.working_hours_to === null || data.data.working_hours_to === 'Flexible') {
                    this.form.working_hours_to = null;
                    // this.firstLoad = false;
                  } else {
                    this.form.working_hours_to = new Date(this.moment.utc(data.data.working_hours_to, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
                  }
                }

                // Assign freehold to be true or false rather than 1 and 0, as otherwise it wont assign proper value from
                // the dropdown in the front-end (tenure)
                if(key === 'is_freehold' && key === formKey) {
                  this.form.is_freehold = data.data[key] === 1;
                }
                // Same situation with is_free_ad and is_paid_ad as above
                if(key === 'is_free_ad' && key === formKey) {
                  this.form.is_free_ad = data.data[key] === 1;
                  // #lifeCycleProblem - I had to trigger child component function later below the code, for some reason
                  // it is undefined at this point.. not sure why
                  // this.$refs.purchase.setAdPlanOnEdit(data.data[key]);
                }
                if(key === 'is_paid_ad' && key === formKey) {
                  this.form.is_paid_ad = data.data[key] === 1;
                  // #lifeCycleProblem - I had to trigger child component function later below the code, for some reason
                  // it is undefined at this point.. not sure why
                  // this.$refs.purchase.setAdPlanOnEdit(data.data[key]);
                }
              }
            })
          });

          // #propertyCatTypePrepopulated Assign property category and type
          this.setPropertyCatType(this.editData.data.property_category, this.editData.data.property_type, this.editData.data.property_type_details);

          // Assign Property Investment Details Data
          if (data.data.investment_property_details) {
            Object.keys(data.data.investment_property_details).forEach((propInvestDetailsKey) => {
              // console.log('Key : ' + propInvestDetailsKey + ', Value : ' + data.data.investment_property_details[propInvestDetailsKey])
              Object.keys(this.form).forEach((formDetailsKey) => {
                if (propInvestDetailsKey === formDetailsKey) {
                  this.form[formDetailsKey] = data.data.investment_property_details[propInvestDetailsKey];
                }
              })
            })
          }

          // Assign Property Details Data
          Object.keys(data.data.property_details).forEach((propDetailsKey) => {
            // console.log('Key : ' + key + ', Value : ' + data.data[key])
            Object.keys(this.form).forEach((formDetailsKey) => {
              if(propDetailsKey === formDetailsKey) {
                this.form[formDetailsKey] = data.data.property_details[propDetailsKey];
              }
            })
          })

          // Assign Discounts Policies
          if (data.data.discounts && data.data.discounts.length > 0) {
            Object.keys(data.data.discounts).forEach((key) => {
              if (data.data.discounts[key].discount_type && data.data.discounts[key].discount_type.type == 'Term') {
                this.form.is_discount_term_term = true;
                this.form.discount_term_term = data.data.discounts[key].discount_term ? data.data.discounts[key].discount_term : null;
                this.form.discount_fee_type_term = data.data.discounts[key].discount_fee_type ? data.data.discounts[key].discount_fee_type : 1;
                this.form.discount_fee_term = data.data.discounts[key].discount_fee ? data.data.discounts[key].discount_fee : null;
              }
              if (data.data.discounts[key].discount_type && data.data.discounts[key].discount_type.type == 'Last Minute') {
                this.form.is_discount_term_last_minute = true;
                this.form.discount_term_last_minute = data.data.discounts[key].discount_term ? data.data.discounts[key].discount_term : null;
                this.form.discount_fee_type_last_minute = data.data.discounts[key].discount_fee_type ? data.data.discounts[key].discount_fee_type : 1;
                this.form.discount_fee_last_minute = data.data.discounts[key].discount_fee ? data.data.discounts[key].discount_fee : null;
              }
              if (data.data.discounts[key].discount_type && data.data.discounts[key].discount_type.type == 'Number of Guests') {
                this.form.is_discount_term_no_of_guests = true;
                this.form.discount_term_no_of_guests = data.data.discounts[key].discount_term ? data.data.discounts[key].discount_term : null;
                this.form.discount_fee_type_no_of_guests = data.data.discounts[key].discount_fee_type ? data.data.discounts[key].discount_fee_type : 1;
                this.form.discount_fee_no_of_guests = data.data.discounts[key].discount_fee ? data.data.discounts[key].discount_fee : null;
              }
            })
          }

          // Assign Contact Details
          Object.keys(data.data.contact).forEach((contactDetailsKey) => {
            // console.log('Key : ' + contactDetailsKey + ', Value : ' + data.data.contact[contactDetailsKey])
            Object.keys(this.form).forEach((formDetailsKey) => {
              if(contactDetailsKey === formDetailsKey) {
                // We want to apply only to specific keys
                if (contactDetailsKey === 'company_name' ||
                    contactDetailsKey === 'contact_type_id' ||
                    contactDetailsKey === 'email' ||
                    contactDetailsKey === 'landing' ||
                    contactDetailsKey === 'landing_prefix' ||
                    contactDetailsKey === 'mobile' ||
                    contactDetailsKey === 'mobile_prefix' ||
                    contactDetailsKey === 'name' ||
                    contactDetailsKey === 'surname' ||
                    contactDetailsKey === 'title'
                ) {
                  this.form[formDetailsKey] = data.data.contact[contactDetailsKey];
                }
              }
            })
          })

          // Assign Settings
          let countSettings = data.data.settings ? data.data.settings.length : null;
          if (countSettings) {

            for (let i=0; i<countSettings; i++) {
              Object.keys(data.data.settings[i]).forEach((settingsKey) => {
                // console.log('Key : ' + settingsKey + ', Value : ' + data.data.settings[i][settingsKey])
                this.assignSettings(data.data.settings[i]);
              })
            }

          }

          // Assign Property Images
          if(this.editData.data.documents_property_images && this.editData.data.documents_property_images.length > 0) {
            Object.keys(this.editData.data.documents_property_images).forEach((propKey) => {
              // console.log('Key : ' + propKey + ', Value : ' + this.editData.data.documents_property_images[propKey]);
              this.form.editPropImages.push(this.editData.data.documents_property_images[propKey]);

              // let requireImage = "require(`@/assets/" + this.editData.data.documents_property_images[propKey].file_path + "`)";
              // console.log(requireImage);
              // I am encountering new problem here, I need to either store also base64 string of images into db
              // or blob... as fileReader() cannot handle just the URL in form of file_path.. I need somehow to get
              // images being previewed on PreviewAdDetails.vue... and also list the rest of the files as a list, so one can
              // remove them on click (remove from DB)
              // My solution might need revision, as it seems a bit pointless to do it that way as described above,
              // I will be using S3 buckets so I should make sure to make it working for S3 buckets, on AddEditAd, AdDetails,
              // List and PreviewAdDetails components

              // this.form.propImages.push(this.editData.data.documents_property_images[docKey].file_path);
              // that.form.previewFloorplanImages.push(e.target.result);
              // Object.keys(this.form).forEach((formDetailsKey) => {
              //   if(propDetailsKey === formDetailsKey) {
              //     this.form[formDetailsKey] = data.data.property_details[propDetailsKey];
              //   }
              // })
            })
          }
          // previewPropImages: [],
          //     previewFloorplanImages: [],
          //     propImages: [],
          //     floorplanImages: []

          // Assign Floorplan Images
          if(this.editData.data.documents_floorplans_images && this.editData.data.documents_floorplans_images.length > 0) {
            Object.keys(this.editData.data.documents_floorplans_images).forEach((floorplanKey) => {
              // console.log('Key : ' + floorplanKey + ', Value : ' + this.editData.data.documents_floorplans_images[floorplanKey]);
              this.form.editFloorplanImages.push(this.editData.data.documents_floorplans_images[floorplanKey]);
            })
          }

          // Get premises House Rules
          if (this.editData && this.editData.data && this.editData.data.rules && this.editData.data.rules.length > 0) {
            this.form.house_rules = this.editData.data.rules;
          }

          // Get premises Rooms
          if (this.editData && this.editData.data && this.editData.data.property_rooms && this.editData.data.property_rooms.length > 0) {
            for (let i=0; i<this.editData.data.property_rooms.length; i++) {
              let room = {...this.editData.data.property_rooms[i], ...this.editData.data.property_rooms[i].property_room_detail}
              this.form.rooms.push(room);
            }
          }

        })

        // #lifeCycleProblem - Not sure why but it seems that I cannot run $refs of child components in main function above,
        // I had to do it in here... weird...
        this.$nextTick(() => {
          if (this.editing && (this.form.is_short_term === 0 || this.form.is_short_term === false)) {
            // Set payment plan in subscribe
            // this.$refs.purchase.setAdPlanOnEdit(this.form.is_paid_ad); // (#FREEPAYMENT) commented out for now
          }
        })

        // #SkipWatchEdit Used to skip first trigger of postType watch on Edit Ad
        this.$nextTick(() => {
          this.editDataLoaded = true
        })

      }
      /* END EDIT AD RELATED */

      // setAdPlan but only if postType was preselected from query
      if (this.$route.query && this.$route.query.type) {
        this.$nextTick(() => {
          if (this.editing && (this.form.is_short_term === 0 || this.form.is_short_term === false)) {
            // Set payment plan in subscribe
            // this.$refs.purchase.setAdPlanOnEdit(this.form.is_paid_ad); // (#FREEPAYMENT) commented out for now
          }
        })
      }

      // #SkipWatchEdit Used to skip first trigger of postType watch on Edit Ad
      this.$nextTick(() => {
        // Retrigger validation
        this.validateAll();
        this.editDataLoaded = true
      })

      if(this.$store.state.properties.prop_power_plant_type_details) {
        /* START MOVED THAT TO App.vue and VUEX STATE and added WATCH below */
        // // Wait until we get prop categories and until they are assigned to propertyCategoriesOptions
        // await this.getAllPropertyCategories();
        // // Loop through all categories and assign property types accordingly
        // for (const [key, value] of Object.entries(this.propertyCategoriesOptions)) {
        //   this.assignPropertyTypes(value);
        // }
        // // Wait until we get prop types and until they are assigned to propertyTypesOptions
        // await this.getAllPropertyTypes();
        // // Loop through all property types and assign property type details accordingly
        // for (const [key, value] of Object.entries(this.propertyTypesOptions)) {
        //   this.assignPropertyTypeDetails(value);
        // }
        /* END MOVED THAT TO App.vue and VUEX STATE */

        /* START Because MOVED to App.vue and VUEX STATE let's assign values */
        this.propertyCategoriesOptions = this.$store.state.properties.property_categories_options;
        this.propertyTypesOptions = this.$store.state.properties.property_types_options;
        /* First Level Dropdown - Holds property types according to property categories - skipped room as no subitems */
        this.propResidentialTypesOptions = this.$store.state.properties.prop_residential_types_options;
        this.propCommercialTypesOptions = this.$store.state.properties.prop_commercial_types_options;
        this.propIndustrialTypesOptions = this.$store.state.properties.prop_industrial_types_options;
        this.propLandTypesOptions = this.$store.state.properties.prop_land_types_options
        this.propLuxuryTypesOptions = this.$store.state.properties.prop_luxury_types_options;
        this.propSpecialUseTypesOptions = this.$store.state.properties.prop_special_use_types_options;
        /* Second Level Dropdown - Holds property type details according to property types */
        // Residential
        this.propHouseTypeDetails = this.$store.state.properties.prop_house_type_details;
        this.propFlatTypeDetails = this.$store.state.properties.prop_flat_type_details;
        // Commercial
        this.propOfficeTypeDetails = this.$store.state.properties.prop_office_type_details;
        this.propRetailTypeDetails = this.$store.state.properties.prop_retail_type_details;
        this.propLeisureTypeDetails = this.$store.state.properties.prop_leisure_type_details;
        this.propHealthcareTypeDetails = this.$store.state.properties.prop_healthcare_type_details;
        this.propParkingTypeDetails = this.$store.state.properties.prop_parking_type_details;
        this.propMultifamilyTypeDetails = this.$store.state.properties.prop_multifamily_type_details;
        // Industrial (we skipped Warehouse and Factory as no subcategories)
        this.propPowerPlantTypeDetails = this.$store.state.properties.prop_power_plant_type_details;
        /* END Because MOVED to App.vue and VUEX STATE let's assign values */
      }

      // this.$nextTick(() => {
      //   // this.editLoaded = true
      //   // Ok the problem here is that once we assign the value as expected, suddenly seems like the component is reloaded one
      //   // more time, hence it resets the value
      //   this.$refs.purchase.setAdPlanOnEdit(this.form.is_paid_ad);
      //   // this.form.property_description = this.form.property_description + '';
      // })
    },
    handleFocus(event) {
      if(!event.target.classList.contains('select-clicked')) {
        event.target.classList.toggle('select-clicked');
      }
    },
    handleFocusOut(value, event) {
      if(value === null || value === '') {
        if(event.target.classList.contains('select-clicked')) {
          event.target.classList.toggle('select-clicked');
        }
      }
    },
    calculateImages(images = null, editImages = null) {
      // Check how many images added compared to set limit
      let count = 0;
      let countEditImages = 0;

      if (images) {
        count = images.length;
      }
      // check if edit images exists
      if (editImages) {
        countEditImages = editImages.length;
      }

      return count + countEditImages;
    },
    calculateAllowedImages(images, editImages = null, limit) {
      // Check how many images added compared to set limit
      if (images) {
        let count = images.length;
        let countEditImages = 0;

        // check if edit images exists
        if (editImages) {
          countEditImages = editImages.length;
        }

        if (limit - count - countEditImages >= 0) {
          return limit - count - countEditImages;
        } else {
          return 'Exceeded allowed number of images';
        }
      // Return maximum allowed
      } else {
        return limit;
      }
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    },
    uploadPropImages(e) {
      this.form.previewPropImages = [];
      this.form.propImages = [];

      // Restrict number of images on free ad
      // if (this.is_paid_ad === false && e.target.files.length > 3) {
      //   alert('On free ad you can post no more than 3 property images.');
      //   return;
      // }

      // Restrict number of images in overall
      if (e.target.files.length > 20) {  // editPropImages
        alert('Maximum number of allowed images is 20.');
        return;
      }

      let fileList = Array.prototype.slice.call(e.target.files);
      fileList.forEach(f => {

        if(!f.type.match("image.*")) {
          return;
        }

        this.form.propImages.push(f)

        let reader = new FileReader();
        let that = this;

        reader.onload = function (e) {
          that.form.previewPropImages.push(e.target.result);
        }
        reader.readAsDataURL(f);
      });
      // console.log(this.form.previewPropImages);
      // console.log(this.form.propImages);
    },
    uploadFloorplanImages(e) {
      this.form.previewFloorplanImages = [];
      this.form.floorplanImages = [];
      this.form.floor_plans = false;

      // Restrict number of images in overall
      if (e.target.files.length > 5) {
        alert('Maximum number of allowed images is 5.');
        return;
      }

      let fileList = Array.prototype.slice.call(e.target.files);
      fileList.forEach(f => {

        if(!f.type.match("image.*")) {
          return;
        }

        this.form.floorplanImages.push(f)

        // Set floorplans as uploaded
        this.form.floor_plans = true;

        let reader = new FileReader();
        let that = this;

        reader.onload = function (e) {
          that.form.previewFloorplanImages.push(e.target.result);
        }
        reader.readAsDataURL(f);
      });
    },
    changeAdPlan(plan) {
      if(plan === 1) {
        this.form.is_free_ad = false;
        this.form.is_paid_ad = true;
      }
      if(plan === 0) {
        this.form.is_free_ad = true;
        this.form.is_paid_ad = false;
      }
    },
    checkDate(date) {
      const endDate = Date.parse(date)
      let currentDate = new Date()
      currentDate = Date.parse(currentDate)

      return currentDate < endDate
    },
    async addProperty() {
      this.processing = true;

      await this.validateBasicDetails();
      const validateBasicDetails = await this.$refs.basicDetailsFormObserver.validate();
      await this.validatePremisesDetails();
      const validatePremisesDetails= await this.$refs.premisesDetailsFormObserver.validate();
      await this.validatePremisesStatus();
      const validatePremisesStatus = await this.$refs.premisesStatusFormObserver.validate();
      await this.validateServicesAndAmenities();
      const validateServicesAndAmenities = await this.$refs.servicesAndAmenitiesFormObserver.validate();
      await this.validateAvailability();
      const validateAvailability = await this.$refs.availabilityFormObserver.validate();
      await this.validateContactDetails();
      const validateContactDetails = await this.$refs.contactDetailsFormObserver.validate();
      const validatePayment = await this.$refs.paymentFormObserver.validate();

      if (validateBasicDetails && validatePremisesDetails && validatePremisesStatus && validateServicesAndAmenities &&
          validateAvailability && validateContactDetails && validatePayment
      ) {
        console.log('validated');
        this.refreshGlobalAddRoomEvent();

        let formData = new FormData();

        // Append all form data, also if statement changes true and false into 0 and 1
        for (let key in this.form) {
          // Prevent passing null values to back-end
          if (this.form[key] === null) {
            this.form[key] = '';
          }
          if (this.form[key] === false) {
            // Set is_free_ad or is_paid_ad empty rather than 0 so that 'required_without' rule in laravel validation can work
            this.form[key] = 0;
            if (key === 'is_free_ad' || key === 'is_paid_ad') {
              // For time being comment out, as we want all ads to be for free
              // this.form[key] = ''; (#FREEPAYMENT)
            }
          } else if (this.form[key] === true) {
            this.form[key] = 1;
          }

          // (#FREEPAYMENT) Temporarily, as we want all ads to be for free, this whole part will be unnecessary later on
          if (key === 'is_free_ad') {
            this.form[key] = '';
          }
          if (key === 'is_paid_ad') {
            this.form[key] = 1;
          }

          if (key === 'date_start') {
            if (this.form.date_range.start !== null && this.form.date_range.start !== '') {
              this.form[key] = this.moment(this.form.date_range.start).format('DD-MM-YYYY');
            } else {
              this.form[key] = null;
            }
          }
          if (key === 'date_end') {
            if (this.form.date_range.end !== null && this.form.date_range.end !== '') {
              this.form[key] = this.moment(this.form.date_range.end).format('DD-MM-YYYY');
            } else {
              this.form[key] = null;
            }
          }

          // Append Check In Out Dates
          if (key === 'check_in_from') {
            if (this.form.check_in_from !== null && this.form.check_in_from !== '') {
              this.form[key] = this.moment(this.form.check_in_from).format('DD-MM-YYYY HH:mm:ss');
            } else {
              this.form[key] = null;
            }
          }
          if (key === 'check_in_to') {
            if (this.form.check_in_to !== null && this.form.check_in_to !== '') {
              this.form[key] = this.moment(this.form.check_in_to).format('DD-MM-YYYY HH:mm:ss');
            } else {
              this.form[key] = null;
            }
          }
          if (key === 'check_out_from') {
            if (this.form.check_out_from !== null && this.form.check_out_from !== '') {
              this.form[key] = this.moment(this.form.check_out_from).format('DD-MM-YYYY HH:mm:ss');
            } else {
              this.form[key] = null;
            }
          }
          if (key === 'check_out_to') {
            if (this.form.check_out_to !== null && this.form.check_out_to !== '') {
              this.form[key] = this.moment(this.form.check_out_to).format('DD-MM-YYYY HH:mm:ss');
            } else {
              this.form[key] = null;
            }
          }

          // Append Available Time Slots (Working Hours) - for Viewings
          if (key === 'working_hours_from') {
            if (this.form.working_hours_from !== null && this.form.working_hours_from !== '') {
              this.form[key] = this.moment(this.form.working_hours_from).format('DD-MM-YYYY HH:mm:ss');
            } else {
              this.form[key] = null;
            }
          }
          if (key === 'working_hours_to') {
            if (this.form.working_hours_to !== null && this.form.working_hours_to !== '') {
              this.form[key] = this.moment(this.form.working_hours_to).format('DD-MM-YYYY HH:mm:ss');
            } else {
              this.form[key] = null;
            }
          }

          // Append Key to form
          formData.append(key, this.form[key]);

          // Append rooms
          if (key === 'rooms') {
            let rooms = JSON.stringify(this.form[key]);
            formData.append("roomsData", rooms);
          }

          // Append rules
          if (key === 'house_rules') {
            let rules = JSON.stringify(this.form[key]);
            formData.append("rulesData", rules);
          }

          // Append Date Range
          if (key === 'date_range') {
            let dateRange = JSON.stringify(this.form[key]);
            formData.append("date_range_data", dateRange);
          }
          // Append Date Range To
          if (key === 'date_range_to') {
            let dateRangeTo = JSON.stringify(this.form[key]);
            formData.append("date_range_to_data", dateRangeTo);
          }
        }

        // Append property images
        if (this.form.propImages.length !== 0) {
          this.form.propImages.forEach((propertyImage, index) => {
            formData.append("propImages[]", propertyImage)
          })
        }
        // Append property floorplan images
        if (this.form.floorplanImages.length !== 0) {
          this.form.floorplanImages.forEach((floorplanImage, index) => {
            formData.append("floorplanImages[]", floorplanImage)
          })
        }

        let paymentResult = false;
        let paymentRequired = false;
        // If editing make sure that we want to run 'purchase' based on whether we changed subscription plan
        if (this.editing === true && this.id && this.id !== '' && this.id !== 'undefined') {
          // Check if is_paid_ad is set to true (1), and if its value is different than on initial editData
          // Then we know that it used to be free_ad and someone is upgrading to paid_ad (charge him)
          if (this.form.is_paid_ad === 1 && this.editData.data.is_paid_ad !== this.form.is_paid_ad) {
            // Validate before payment
            let validate = await axios.post('api/validate/properties/' + this.id + '/update', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(async response => {
              this.validationErrors = null;
              this.validationMessage = null;
              this.veeValidationErrors = null;
              this.veeValidationMessage = null;

              // #paymentFunction
              paymentRequired = false; // (#FREEPAYMENT) should be 'true' for time being we make it 'false' and comment out purchase()
              // paymentResult = await this.$refs.purchase.purchase();
            }).catch(({response}) => {
              if (response && response.data && response.data.errors) {
                this.validationErrors = response.data.errors;
                this.validationMessage = response.data.message;
                // Important, to refresh event so that adding/removing rooms can work correctly
                this.refreshGlobalAddRoomEvent();
                this.processing = false
              }
            }).finally(() => {
              // Processing still should be true, it will be set to false at the end of whole process
              // this.processing = false
            })

            // // If validation went through proceed to payment and add advertise
            // if (validate === true) {
            //   // #paymentFunction
            //   paymentRequired = true;
            //   paymentResult = await this.$refs.purchase.purchase();
            // }
          }

          // I should add another check here, if current setup is 'is_paid_ad === 1' but its ad_ends_at is expired
          // we still should trigger purchase();
          if (this.form.is_paid_ad === 1 && this.editData.data.is_paid_ad === this.form.is_paid_ad && this.checkDate(this.editData.data.ad_ends_at) === false) {
            // Validate before payment
            let validate = await axios.post('api/validate/properties/' + this.id + '/update', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(async response => {
              this.validationErrors = null;
              this.validationMessage = null;
              this.veeValidationErrors = null;
              this.veeValidationMessage = null;

              // #paymentFunction
              paymentRequired = false; // (#FREEPAYMENT) should be 'true' for time being we make it 'false' and comment out purchase()
              // paymentResult = await this.$refs.purchase.purchase();

            }).catch(({response}) => {
              if (response && response.data && response.data.errors) {
                this.validationErrors = response.data.errors;
                this.validationMessage = response.data.message;
                // Important, to refresh event so that adding/removing rooms can work correctly
                this.refreshGlobalAddRoomEvent();
                this.processing = false
              }
            }).finally(() => {
              // Processing still should be true, it will be set to false at the end of whole process
              // this.processing = false
            })

            // // If validation went through proceed to payment and add advertise
            // if (validate === true) {
            //   // #paymentFunction
            //   paymentRequired = true;
            //   paymentResult = await this.$refs.purchase.purchase();
            // }
          }

          // Check if is_free_ad is set to true (1), and if its value is different than on initial editData
          // Then we know that it used to be paid_ad and if ad_ends_at is not expired, we should ask for confirmation
          // Whether user is sure they want to change from 'paid_ad' to 'free_ad'
          if (this.form.is_free_ad === 1 && this.editData.data.is_free_ad !== this.form.is_free_ad && this.checkDate(this.editData.data.ad_ends_at) === true) {
            if (!confirm("Do you really want to change it to a free advertise? Your current advertise will expire on " + this.editData.data.ad_ends_at + " ?")) {
              this.processing = false
              return;
            }
          }

          // Check if we are sending is_short_term (provision based payment)
          if (this.form.is_short_term === 1) {
            paymentRequired = false;
            paymentResult = {
              id: 'PROVISION AD',
              status: true,
              paymentAmount: 0
            };
          }

          if ((paymentRequired === true && paymentResult && paymentResult.status && paymentResult.status === true) || paymentRequired === false) {
            // (#FREEPAYMENT) For time being we set object manually to indicate its FREE (this code to be removed later on)
            paymentResult = {
              id: 'FREE',
              status: true,
              paymentAmount: 0
            };

            // Append Payment Results
            if (paymentResult) {
              formData.append('payment_id', paymentResult.id);
              formData.append('payment_status', paymentResult.status);
              formData.append('payment_amount', paymentResult.paymentAmount)
            }

            await axios.post('api/properties/' + this.id + '/update', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {
              this.propId = response.data.id;
              this.validationErrors = null;
              this.validationMessage = null;
              this.veeValidationErrors = null;
              this.veeValidationMessage = null;
            }).catch(({response}) => {
              if (response && response.data && response.data.errors) {
                this.validationErrors = response.data.errors;
                this.validationMessage = response.data.message;
                // Important, to refresh event so that adding/removing rooms can work correctly
                this.refreshGlobalAddRoomEvent();
                this.editing = true;
              }
            }).finally(() => {
              this.processing = false
            })

            // Redirect after editing
            if (!this.validationErrors && this.id && this.id !== '' && this.id !== 'undefined') {
              // Change editing to be false after it finished
              this.editing = false;

              // Reload properties in STATE but only if address or drawbboxs exist
              if ((this.$store.state.searchFilters.searchFilters &&
                      this.$store.state.searchFilters.searchFilters.address &&
                      this.$store.state.searchFilters.searchFilters.address.length > 0) ||
                  (this.$store.state.searchFilters.searchFilters &&
                      this.$store.state.searchFilters.searchFilters.drawbboxs &&
                      this.$store.state.searchFilters.searchFilters.drawbboxs.length > 0)
              ) {
                this.$emit('refreshProps', this.$store.state.searchFilters.searchFilters)
              }

              const propId = this.propId;

              // If payment is not required preset paymentResult
              if (paymentRequired === false) {
                paymentResult = {
                  id: 'UPDATE',
                  status: true,
                  paymentAmount: 0
                };
              }

              // Clear form data
              this.form = {...this.defaultForm};

              await router.push({path: `/add-submit-confirmation/${propId}/property/${paymentResult.id}`});
            }
          } else {
            // Show error of payment
            this.validationCardMessage = paymentResult;
            // Important, to refresh event so that adding/removing rooms can work correctly
            this.refreshGlobalAddRoomEvent();
            this.processing = false
          }

        } else if (this.editing === false && (!this.id || this.id === '' || this.id === 'undefined')) {
          // Validate before payment
          let validate = await axios.post('api/validate/properties', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            this.validationErrors = null;
            this.validationMessage = null;
            this.veeValidationErrors = null;
            this.veeValidationMessage = null;
            return true;
          }).catch(({response}) => {
            if (response && response.data && response.data.errors) {
              this.validationErrors = response.data.errors;
              this.validationMessage = response.data.message;
              // Important, to refresh event so that adding/removing rooms can work correctly
              this.refreshGlobalAddRoomEvent();
              this.processing = false
            }
            return false;
          }).finally(() => {
            // Processing still should be true, it will be set to false at the end of whole process
            // this.processing = false
          })

          // If validation went through proceed to payment and add advertise
          if (validate === true) {

            // Check if we are sending is_short_term (provision based payment)
            if (this.form.is_short_term === 1) {
              paymentRequired = false;
              paymentResult = {
                id: 'PROVISION AD',
                status: true,
                paymentAmount: 0
              };
            } else {
              paymentRequired = false; // (#FREEPAYMENT) should be 'true' for time being we make it 'false' and comment out purchase()
            }

            if (this.form.is_short_term === 0) {
              // #paymentFunction
              // paymentResult = await this.$refs.purchase.purchase(); // (#FREEPAYMENT) should be 'true' for time being we make it 'false' and comment out purchase()
            }

            if ((paymentResult && paymentResult.status && paymentResult.status === true) || paymentRequired === false) {
              // (#FREEPAYMENT) For time being we set object manually to indicate its FREE (this code to be removed later on)
              if (this.form.is_short_term !== 1) {
                paymentResult = {
                  id: 'FREE',
                  status: true,
                  paymentAmount: 0
                };
              }

              // Append Payment Results
              if (paymentResult) {
                formData.append('payment_id', paymentResult.id);
                formData.append('payment_status', paymentResult.status);
                formData.append('payment_amount', paymentResult.paymentAmount)
              }

              await axios.post('api/properties', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then(response => {
                this.propId = response.data.id;
                this.validationErrors = null;
                this.validationMessage = null;
                this.veeValidationErrors = null;
                this.veeValidationMessage = null;
              }).catch(({response}) => {
                if (response && response.data && response.data.errors) {
                  this.validationErrors = response.data.errors;
                  this.validationMessage = response.data.message;
                  // Important, to refresh event so that adding/removing rooms can work correctly
                  this.refreshGlobalAddRoomEvent();
                }
              }).finally(() => {
                this.processing = false
              })

              // Redirect after adding
              if (!this.validationErrors) {
                // Reload properties in STATE but only if address or drawbboxs exist
                if ((this.$store.state.searchFilters.searchFilters &&
                        this.$store.state.searchFilters.searchFilters.address &&
                        this.$store.state.searchFilters.searchFilters.address.length > 0) ||
                    (this.$store.state.searchFilters.searchFilters &&
                        this.$store.state.searchFilters.searchFilters.drawbboxs &&
                        this.$store.state.searchFilters.searchFilters.drawbboxs.length > 0)
                ) {
                  this.$emit('refreshProps', this.$store.state.searchFilters.searchFilters)
                }

                // Clear form data
                this.form = {...this.defaultForm};

                const propId = this.propId;

                await router.push({path: `/add-submit-confirmation/${propId}/property/${paymentResult.id}`})
              }
            } else {
              // Show error of payment
              this.validationCardMessage = paymentResult;
              // Important, to refresh event so that adding/removing rooms can work correctly
              this.refreshGlobalAddRoomEvent();
              this.processing = false
            }
          }

        }

      } else {
        this.veeValidationErrors = {error_msg: 'Please review your fields, as some areas are not valid'};
        this.veeValidationMessage = 'Please review your fields, as some areas are not valid';
        // Important, to refresh event so that adding/removing rooms can work correctly
        this.refreshGlobalAddRoomEvent();
        this.processing = false;
      }
    },
    deleteImage(id, type, name) {

      if(confirm("Do you really want to delete " + name + " ?")){
        axios.post('api/document/' + id + '/delete').then(({data}) => {
          // Type 2 = Property Image
          if (type === 2) {
            this.form.editPropImages = this.form.editPropImages.filter(propImage => propImage.id !== id);
          // Type 3 = Floorplan Image
          } else if (type === 3) {
            this.form.editFloorplanImages = this.form.editFloorplanImages.filter(floorplanImage => floorplanImage.id !== id);
          }
        })
      }
    },
    getAllPropertyCategories: async function() {
      let headers = {
        'Retry-After': 3
      }
        return axios.post('api/property-categories', [], {headers: headers}).then(({data}) => {
          this.propertyCategoriesOptions = data;
        })
    },
    getAllPropertyTypes: async function() {
      let headers = {
        'Retry-After': 3
      }
      return axios.post('api/property-types',[],{headers: headers}).then(({data})=>{
        this.propertyTypesOptions = data;
      })
    },
    getPropertyTypes(variable, index) {
      let headers = {
        'Retry-After': 3
      }
      axios.post('api/property-types', index, {headers: headers}).then(({data})=>{
        // Holds name of property name to which we want to assign data
        let propName = variable;
        this[propName] = data;
      })
    },
    getPropertyTypeDetails(variable, index) {
      let headers = {
        'Retry-After': 3
      }
      axios.post('api/property-type-details', index, {headers: headers}).then(({data})=>{
        // Holds name of property name to which we want to assign data
        let propName = variable;
        this[propName] = data;
      })
    },
    assignSettings(settings) {
      // Setting Type Id 1 = show company name
      if (settings && settings.setting_type_id === 1) {
        this.form.show_company_name = settings.setting_value === '1';
      }
      // Setting Type Id 2 = show address
      if (settings && settings.setting_type_id === 2) {
        this.form.show_address = settings.setting_value === '1';
      }
      // Setting Type Id 3 = show mobile
      if (settings && settings.setting_type_id === 3) {
        this.form.show_mobile = settings.setting_value === '1';
      }
      // Setting Type Id 4 = show landing
      if (settings && settings.setting_type_id === 4) {
        this.form.show_landing = settings.setting_value === '1';
      }
      // Setting Type Id 5 = show email
      if (settings && settings.setting_type_id === 5) {
        this.form.show_email = settings.setting_value === '1';
      }
    },
    /*
      propTypes = Property Types derived from getPropertyTypes()
     */
    assignPropertyTypes(propTypes) {
      // Residential
      if(propTypes.id === 1) {
        this.getPropertyTypes('propResidentialTypesOptions', propTypes.id);
      }
      // Commercial
      if(propTypes.id === 2) {
        this.getPropertyTypes('propCommercialTypesOptions', propTypes.id);
      }
      // Industrial
      if(propTypes.id === 3) {
        this.getPropertyTypes('propIndustrialTypesOptions', propTypes.id);
      }
      // Land
      if(propTypes.id === 4) {
        this.getPropertyTypes('propLandTypesOptions', propTypes.id);
      }
      // Luxury
      if(propTypes.id === 5) {
        this.getPropertyTypes('propLuxuryTypesOptions', propTypes.id);
      }
      // Special Use
      if(propTypes.id === 6) {
        this.getPropertyTypes('propSpecialUseTypesOptions', propTypes.id);
      }
    },
    /*
      propTypeDetails = Property Type Details derived from getPropertyTypeDetails()
     */
    assignPropertyTypeDetails(propTypeDetails) {
      // House
      if(propTypeDetails.id === 1) {
        this.getPropertyTypeDetails('propHouseTypeDetails', propTypeDetails.id);
      }
      // Flat
      if(propTypeDetails.id === 2) {
        this.getPropertyTypeDetails('propFlatTypeDetails', propTypeDetails.id);
      }
      // Office
      if(propTypeDetails.id === 3) {
        this.getPropertyTypeDetails('propOfficeTypeDetails', propTypeDetails.id);
      }
      // Retail
      if(propTypeDetails.id === 4) {
        this.getPropertyTypeDetails('propRetailTypeDetails', propTypeDetails.id);
      }
      // Leisure
      if(propTypeDetails.id === 5) {
        this.getPropertyTypeDetails('propLeisureTypeDetails', propTypeDetails.id);
      }
      // Healthcare
      if(propTypeDetails.id === 6) {
        this.getPropertyTypeDetails('propHealthcareTypeDetails', propTypeDetails.id);
      }
      // Parking
      if(propTypeDetails.id === 7) {
        this.getPropertyTypeDetails('propParkingTypeDetails', propTypeDetails.id);
      }
      // Multifamily
      if(propTypeDetails.id === 8) {
        this.getPropertyTypeDetails('propMultifamilyTypeDetails', propTypeDetails.id);
      }
      // Power Plant
      if(propTypeDetails.id === 11) {
        this.getPropertyTypeDetails('propPowerPlantTypeDetails', propTypeDetails.id);
      }
    },
    setPropertyCatType(propCat, propType = '', propTypeDetail = '') {
      // If values are null make sure to set them as empty
      if (propType == null) {
       this.form.property_type_id = '';
      }
      if (propTypeDetail == null) {
        this.form.property_type_details_id = '';
      }
      // propCat, propType and propTypeDetail provided
      if(propTypeDetail && propTypeDetail !== '') {
        this.form.property_category_id = propCat.id;
        this.form.property_type_id = propType.id;
        this.form.property_type_details_id = propTypeDetail.id;
        this.form.propertyCatType = propCat.type + ' ' + propType.type + ' ' + propTypeDetail.type_detail;
        this.form.propertyCategory = propCat.type;
        if(propTypeDetail.type_detail === 'Studio Flat') {
          this.form.studio_room = true;
          this.form.single_room = 0;
          this.form.double_room = 0;
          this.form.living_room = 1;
          this.form.dining_room = 0;
          this.form.kitchen = 1;
          this.form.box_room = 0;
        }
      // propCat and propType provided
      } else if(propType && propType !== '') {
        this.form.property_category_id = propCat.id;
        this.form.property_type_id = propType.id;
        this.form.propertyCatType = propCat.type + ' ' + propType.type;
        this.form.propertyCategory = propCat.type;
      // Only propCat provided
      } else {
        this.form.property_category_id = propCat.id;
        this.form.propertyCatType = propCat.type;
        this.form.propertyCategory = propCat.type;
      }

      /* Cheating again, I am focusing and unfocusing, as property_category_id seems to not be set on time */
      let dropdown = document.getElementById('mainMenuPropertyCategory');
      if(dropdown) {
        document.getElementById('mainMenuPropertyCategory').focus();
        document.getElementById('mainMenuPropertyCategory').blur();
      }
    },
    async setGeocoder(type) {
      // Mapbox Geocoding Address Autocomplete
      let geocoder = '';
      // postType 2 = Buy; postType 4 = Rent
      // We want to search only by locality if thats possible
      if(type === 2 || type === 4) {
        geocoder = new MapboxGeocoder({
          accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
          types: 'region,place,district,postcode,locality',
          limit: 7,
          countries: 'gb, ie, es', // Restrict to UK,Ireland and Spain only
        });
        return geocoder;
      } else {
        geocoder = new MapboxGeocoder({
          accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
          types: 'region,place,district,postcode,locality,address',
          limit: 7,
          countries: 'gb, ie, es', // Restrict to UK,Ireland and Spain only
        });
        return geocoder;
      }
    },
    async initGeocodeBox(type) {
      let that = this;
      await this.$nextTick();
      let geocoder = await this.setGeocoder(type);
      let element = that.$refs.geocoderAddEditAdRef;
      if (element) {
        element.innerHTML = '';
        if (element && !element.hasChildNodes()) {
          geocoder.addTo(element);
        }
      }

      // #addressPrepopulated If Editing Ad we want to prepopulate its address
      if (that.editData && that.editData.data.address && that.editData.data.address.full_address) {
        // Later I will use this solution, I am awaiting for second parameter to be added
        // https://github.com/mapbox/mapbox-gl-geocoder/pull/425
        // geocoder.setInput(this.editData.data.address.full_address);
        geocoder._inputEl.value = that.editData.data.address.full_address;
        // geocoder.query(this.editData.data.address.full_address);
      }

      // If we are loading draft we want to prepopulate address
      if (that.draftLoad) {
        if (geocoder) {
          geocoder._inputEl.value = that.form.full_address;
        }
      }

      // Get the geocoder results container.
      const results = document.getElementById('result');

      // Add geocoder result to container.
      geocoder.on('result', (e) => {
        // console.log(e);
        // results.innerText = JSON.stringify(e.result, null, 2);
        // For districts such as South Yorkshire, Hampshire etc.
        if (e.result.place_type.includes('district')) {
          for (let i = 0; i < e.result.context.length; i++) {
            if (e.result.context[i].id.includes('postcode')) {
              that.form.postcode = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('locality')) {
              that.form.line_2 = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('place')) {
              that.form.city = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('country')) {
              that.form.country = e.result.context[i].text;
            }
          }
          // district
          if (e.result.text) {
            that.form.county = e.result.text;
          }
          // For places, which are cities, such as Manchester, Leeds etc.
        } else if (e.result.place_type.includes('place')) {
          for (let i = 0; i < e.result.context.length; i++) {
            if (e.result.context[i].id.includes('postcode')) {
              that.form.postcode = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('locality')) {
              that.form.line_2 = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('district')) {
              that.form.county = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('country')) {
              that.form.country = e.result.context[i].text;
            }
          }
          // Place - City
          if (e.result.text) {
            that.form.city = e.result.text;
          }
        } else if (e.result.place_type.includes('postcode')) {
          for (let i = 0; i < e.result.context.length; i++) {
            if (e.result.context[i].id.includes('locality')) {
              that.form.line_2 = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('district')) {
              that.form.county = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('country')) {
              that.form.country = e.result.context[i].text;
            }
          }
          // Postcode
          if (e.result.text) {
            that.form.postcode = e.result.text;
          }
          // For locality such as Cudworth, Cosham etc.
        } else if (e.result.place_type.includes('locality')) {
          for (let i = 0; i < e.result.context.length; i++) {
            if (e.result.context[i].id.includes('district')) {
              that.form.county = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('place')) {
              that.form.city = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('country')) {
              that.form.country = e.result.context[i].text;
            }
          }
          // Locality
          if (e.result.text) {
            that.form.line_2 = e.result.text;
          }
          // For addresses, detailed ones such as 7 California Street etc. and all the rest
        } else {
          for (let i = 0; i < e.result.context.length; i++) {
            if (e.result.context[i].id.includes('postcode')) {
              that.form.postcode = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('locality')) {
              that.form.line_2 = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('place')) {
              that.form.city = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('district')) {
              that.form.county = e.result.context[i].text;
            }
            if (e.result.context[i].id.includes('country')) {
              that.form.country = e.result.context[i].text;
            }
          }
          // First line of address
          if (e.result.text || e.result.address) {
            that.form.line_1 = (e.result.address ? e.result.address + ' ' : '') + (e.result.text ? e.result.text : '');
          }
          // Street Only
          if (e.result.text) {
            that.form.street = e.result.text ? e.result.text : '';
          }
        }

        // Full Address
        if (e.result.place_name) {
          that.form.full_address = e.result.place_name;
        } else {
          that.form.full_address = '';
        }
        // Coordinates
        if (e.result.center) {
          that.form.longitude = e.result.center[0];
          that.form.latitude = e.result.center[1];
        }
        // Boundary Box
        if (e.result.bbox) {
          that.form.boundary_box = e.result.bbox;
        }
      });

      // Clear results container when search is cleared.
      geocoder.on('clear', () => {
        // results.innerText = '';
        that.form.postcode = '';
        that.form.line_2 = '';
        that.form.city = '';
        that.form.county = '';
        that.form.country = '';
        that.form.street = '';
        that.form.line_1 = '';
        that.form.longitude = '';
        that.form.latitude = '';
        that.form.boundary_box = '';
        that.form.full_address = '';
      });

    },
    initGeocodeBoxPropertyContactAddress(type) {
      let that = this;
      this.$nextTick(() => {

        // Mapbox Geocoding Address Autocomplete
        let geocoderPropertyContactAddress = '';

        // We want to search only by locality if thats possible
        geocoderPropertyContactAddress = new MapboxGeocoder({
          accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
          types: 'region,place,district,postcode,locality,address',
          limit: 7,
          countries: 'gb, ie, es', // Restrict to UK,Ireland and Spain only
        });

        let geoCheck = document.getElementById('geocoderAddEditPropertyContactAddress');
        if (geoCheck) {
          if (geoCheck.children.length <= 0) {
            geocoderPropertyContactAddress.addTo('#geocoderAddEditPropertyContactAddress');
          }
        }

        // #addressPrepopulated If Editing Ad we want to prepopulate its address
        if (this.editData && this.editData.data.contact &&
            this.editData.data.contact.address &&
            this.editData.data.contact.address.full_address
        ) {
          // Later I will use this solution, I am awaiting for second parameter to be added
          // https://github.com/mapbox/mapbox-gl-geocoder/pull/425
          // geocoderPropertyContactAddress.setInput(this.editData.data.contact.address.full_address);
          geocoderPropertyContactAddress._inputEl.value = this.editData.data.contact.address.full_address;
          // geocoderPropertyContactAddress.query(this.editData.data.contact.address.full_address);
        }

        // If we are loading draft we want to prepopulate address
        if (this.draftLoad) {
          if (geocoderPropertyContactAddress) {
            geocoderPropertyContactAddress._inputEl.value = this.form.prop_contact_full_address;
          }
        }

        // Get the geocoder results container.
        const results = document.getElementById('result');

        // Add geocoder result to container.
        geocoderPropertyContactAddress.on('result', (e) => {
          // console.log(e);
          // results.innerText = JSON.stringify(e.result, null, 2);
          // For districts such as South Yorkshire, Hampshire etc.
          if (e.result.place_type.includes('district')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('postcode')) {
                this.form.prop_contact_postcode = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('locality')) {
                this.form.prop_contact_line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('place')) {
                this.form.prop_contact_city = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.prop_contact_country = e.result.context[i].text;
              }
            }
            // district
            if (e.result.text) {
              this.form.prop_contact_county = e.result.text;
            }
            // For places, which are cities, such as Manchester, Leeds etc.
          } else if (e.result.place_type.includes('place')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('postcode')) {
                this.form.prop_contact_postcode = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('locality')) {
                this.form.prop_contact_line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('district')) {
                this.form.prop_contact_county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.prop_contact_country = e.result.context[i].text;
              }
            }
            // Place - City
            if (e.result.text) {
              this.form.prop_contact_city = e.result.text;
            }
          } else if (e.result.place_type.includes('postcode')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('locality')) {
                this.form.prop_contact_line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('district')) {
                this.form.prop_contact_county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.prop_contact_country = e.result.context[i].text;
              }
            }
            // Postcode
            if (e.result.text) {
              this.form.prop_contact_postcode = e.result.text;
            }
            // For locality such as Cudworth, Cosham etc.
          } else if (e.result.place_type.includes('locality')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('district')) {
                this.form.prop_contact_county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('place')) {
                this.form.prop_contact_city = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.prop_contact_country = e.result.context[i].text;
              }
            }
            // Locality
            if (e.result.text) {
              this.form.prop_contact_line_2 = e.result.text;
            }
            // For addresses, detailed ones such as 7 California Street etc. and all the rest
          } else {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('postcode')) {
                this.form.prop_contact_postcode = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('locality')) {
                this.form.prop_contact_line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('place')) {
                this.form.prop_contact_city = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('district')) {
                this.form.prop_contact_county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.form.prop_contact_country = e.result.context[i].text;
              }
            }
            // First line of address
            if (e.result.text || e.result.address) {
              this.form.prop_contact_line_1 = (e.result.address ? e.result.address + ' ' : '') + (e.result.text ? e.result.text : '');
            }
            // Street Only
            if (e.result.text) {
              this.form.prop_contact_street = e.result.text ? e.result.text : '';
            }
          }

          // Full Address
          if (e.result.place_name) {
            this.form.prop_contact_full_address = e.result.place_name;
          }
          // Coordinates
          if (e.result.center) {
            this.form.prop_contact_longitude = e.result.center[0];
            this.form.prop_contact_latitude = e.result.center[1];
          }
          // Boundary Box
          if (e.result.bbox) {
            this.form.prop_contact_boundary_box = e.result.bbox;
          }
        });

        // Clear results container when search is cleared.
        geocoderPropertyContactAddress.on('clear', () => {
          // results.innerText = '';
          this.form.prop_contact_postcode = '';
          this.form.prop_contact_line_2 = '';
          this.form.prop_contact_city = '';
          this.form.prop_contact_county = '';
          this.form.prop_contact_country = '';
          this.form.prop_contact_street = '';
          this.form.prop_contact_line_1 = '';
          this.form.prop_contact_longitude = '';
          this.form.prop_contact_latitude = '';
          this.form.prop_contact_boundary_box = '';
          this.form.prop_contact_full_address = '';
        });

      });
    },
    async resetDisabledCheckboxes() {
      this.form.property_types.houses_disable = false;
      this.form.property_types.flats_disable = false;
      this.form.property_types.offices_disable = false;
      this.form.property_types.land_disable = false;
      this.form.property_types.leisure_disable = false;
      this.form.property_types.luxury_disable = false;
      this.form.property_types.retail_disable = false;
      this.form.property_types.healthcare_disable = false;
      this.form.property_types.multifamily_disable = false;
      this.form.property_types.garage_parking_disable = false;
      this.form.property_types.special_use_disable = false;
      this.form.property_types.rooms_disable = false;
      this.form.property_types.warehouses_disable = false;
      this.form.property_types.factories_disable = false;
      this.form.property_types.power_plants_disable = false;
    },
    async actionPropType(type, event) {
      if (type === 'houses') {
        // console.log('triggered actionPropType function');
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('houses');

          this.form.property_types.detached_house = true;
          this.form.property_types.semi_detached_house = true;
          this.form.property_types.mid_terraced = true;
          this.form.property_types.end_terraced = true;
          this.form.property_types.detached_bungalow = true;
          this.form.property_types.semi_detached_bungalow = true;
          this.form.property_types.cottage = true;

          // Disable buttons
          this.form.property_types.houses_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
          let type = this.propertyTypesOptions.find(o => o.type === 'House');

          this.setPropertyCatType(category, type);
        } else {
          this.form.property_types.houses_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'flats') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('flats');

          this.form.property_types.flat = true;
          this.form.property_types.studio = true;
          this.form.property_types.maisonette = true;

          // Disable buttons
          this.form.property_types.flats_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
          let type = this.propertyTypesOptions.find(o => o.type === 'Flat');

          this.setPropertyCatType(category, type);
        } else {
          this.form.property_types.flats_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'land') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('land');

          this.form.property_types.greenfield = true;
          this.form.property_types.brownfield = true;

          // Disable buttons
          this.form.property_types.land_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Land');

          this.setPropertyCatType(category);
        } else {
          this.form.property_types.land_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'retail') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('retail');

          this.form.property_types.shop = true;
          this.form.property_types.shopping_mall = true;
          this.form.property_types.retail_store = true;

          // Disable buttons
          this.form.property_types.retail_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Retail');

          this.setPropertyCatType(category, type);
        } else {
          this.form.property_types.retail_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'leisure') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('leisure');

          this.form.property_types.restaurant_cafe = true;
          this.form.property_types.takeaway = true;
          this.form.property_types.pub = true;
          this.form.property_types.entertainment = true;
          this.form.property_types.sport_healthcare_facility = true;
          this.form.property_types.hotel_tourism = true;

          // Disable buttons
          this.form.property_types.leisure_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');

          this.setPropertyCatType(category, type);
        } else {
          this.form.property_types.leisure_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'offices') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('offices');

          this.form.property_types.traditional_office = true;
          this.form.property_types.serviced_office = true;
          this.form.property_types.managed_office = true;

          // Disable buttons
          this.form.property_types.offices_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Office');

          this.setPropertyCatType(category, type);
        } else {
          this.form.property_types.offices_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'healthcare') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('healthcare');

          this.form.property_types.pharmacy = true;
          this.form.property_types.medical_center = true;
          this.form.property_types.nursing_home = true;
          this.form.property_types.hospital = true;

          // Disable buttons
          this.form.property_types.healthcare_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Healthcare');

          this.setPropertyCatType(category, type);
        } else {
          this.form.property_types.healthcare_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'garage_parking') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('garage_parking');

          this.form.property_types.parking_space = true;
          this.form.property_types.single_garage = true;
          this.form.property_types.multiple_garages = true;
          this.form.property_types.parking_lot = true;
          this.form.property_types.multi_storey_parking = true;

          // Disable buttons
          this.form.property_types.garage_parking_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');

          this.setPropertyCatType(category, type);
        } else {
          this.form.property_types.garage_parking_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'multifamily') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('multifamily');

          this.form.property_types.duplex = true;
          this.form.property_types.block_of_flats = true;
          this.form.property_types.apartment_complex = true;

          // Disable buttons
          this.form.property_types.multifamily_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Multifamily');

          this.setPropertyCatType(category, type);
        } else {
          this.form.property_types.multifamily_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'luxury') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('luxury');

          this.form.property_types.mansion = true;
          this.form.property_types.villa = true;

          // Disable buttons
          this.form.property_types.luxury_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Luxury');

          this.setPropertyCatType(category);
        } else {
          this.form.property_types.luxury_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'special_use') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('special_use');

          this.form.property_types.church = true;
          this.form.property_types.cemetery = true;
          this.form.property_types.park = true;
          this.form.property_types.school = true;
          this.form.property_types.barn = true;
          this.form.property_types.campsite = true;

          // Disable buttons
          this.form.property_types.special_use_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');

          this.setPropertyCatType(category);
        } else {
          this.form.property_types.special_use_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'rooms') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('rooms');

          // Disable buttons
          this.form.property_types.rooms_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Room');

          this.setPropertyCatType(category);
          // ...
        } else {
          this.form.property_types.rooms_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'warehouses') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('warehouses');

          // Disable buttons
          this.form.property_types.warehouses_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Warehouse');

          this.setPropertyCatType(category, type);
          // ...
        } else {
          this.form.property_types.warehouses_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'factories') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('factories');

          // Disable buttons
          this.form.property_types.factories_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Factory');

          this.setPropertyCatType(category, type);
          // ...
        } else {
          this.form.property_types.factories_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
      if (type === 'power_plants') {
        // Reset disabled checkboxes
        await this.resetDisabledCheckboxes();
        if (event.target.checked) {
          // Clear all
          await this.setPropertyTypes('power_plants');

          this.form.property_types.hydroelectric_plant = true;
          this.form.property_types.coal_fired_plant = true;
          this.form.property_types.diesel_fired_plant = true;
          this.form.property_types.geothermal_plant = true;
          this.form.property_types.gas_fired_plant = true;
          this.form.property_types.solar_power_plant = true;
          this.form.property_types.wind_power_plant = true;
          this.form.property_types.tidal_power_plant = true;

          // Disable buttons
          this.form.property_types.power_plants_disable = true;

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');

          this.setPropertyCatType(category, type);
        } else {
          this.form.property_types.power_plants_disable = false;
          await this.setPropertyTypes();
          // Restart property type and category dropdown fields
          this.form.property_category_id = '';
          this.form.property_type_id = '';
          this.form.property_type_details_id = '';
          this.form.propertyCatType = 'Select premises type';
          this.form.propertyCategory = '';
          // Get property type and category dropdown
          let dropdownEl = document.getElementById('mainMenuPropertyCategory');
          if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
            document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
          }
        }
      }
    },
    setPropertyTypes(type = null) {
      // Houses
      this.form.property_types.houses = (type === 'houses' || type === 'detached_house' || type === 'semi_detached_house' || type === 'mid_terraced' || type === 'end_terraced' || type === 'detached_bungalow' || type === 'semi_detached_bungalow') ? true : false;
      this.form.property_types.detached_house = type === 'detached_house';
      this.form.property_types.semi_detached_house = type === 'semi_detached_house';
      this.form.property_types.mid_terraced = type === 'mid_terraced';
      this.form.property_types.end_terraced = type === 'end_terraced';
      this.form.property_types.detached_bungalow = type === 'detached_bungalow';
      this.form.property_types.semi_detached_bungalow = type === 'semi_detached_bungalow';
      this.form.property_types.cottage = type === 'cottage';
      // Flats
      this.form.property_types.flats = (type === 'flats' || type === 'flat' || type === 'studio' || type === 'maisonette') ? true : false;
      this.form.property_types.flat = type === 'flat';
      this.form.property_types.studio = type === 'studio';
      this.form.property_types.maisonette = type === 'maisonette';
      // Offices
      this.form.property_types.offices = (type === 'offices' || type === 'traditional_office' || type === 'serviced_office' || type === 'managed_office') ? true : false;
      this.form.property_types.traditional_office = type === 'traditional_office';
      this.form.property_types.serviced_office = type === 'serviced_office';
      this.form.property_types.managed_office = type === 'managed_office';
      // Retail
      this.form.property_types.retail = (type === 'retail' || type === 'shop' || type === 'shopping_mall' || type === 'retail_store') ? true : false;
      this.form.property_types.shop = type === 'shop';
      this.form.property_types.shopping_mall = type === 'shopping_mall';
      this.form.property_types.retail_store = type === 'retail_store';
      // Leisure
      this.form.property_types.leisure = (type === 'leisure' || type === 'restaurant_cafe' || type === 'takeaway' || type === 'pub' || type === 'entertainment' || type === 'sport_healthcare_facility' || type === 'hotel_tourism') ? true : false;
      this.form.property_types.restaurant_cafe = type === 'restaurant_cafe';
      this.form.property_types.takeaway = type === 'takeaway';
      this.form.property_types.pub = type === 'pub';
      this.form.property_types.entertainment = type === 'entertainment';
      this.form.property_types.sport_healthcare_facility = type === 'sport_healthcare_facility';
      this.form.property_types.hotel_tourism = type === 'hotel_tourism';
      // Healthcare
      this.form.property_types.healthcare = (type === 'healthcare' || type === 'pharmacy' || type === 'medical_center' || type === 'nursing_home' || type === 'hospital') ? true : false;
      this.form.property_types.pharmacy = type === 'pharmacy';
      this.form.property_types.medical_center = type === 'medical_center';
      this.form.property_types.nursing_home = type === 'nursing_home';
      this.form.property_types.hospital = type === 'hospital';
      // Garage/Parking
      this.form.property_types.garage_parking = (type === 'garage_parking' || type === 'parking_space' || type === 'single_garage' || type === 'multiple_garages' || type === 'parking_lot' || type === 'multi_storey_parking') ? true : false;
      this.form.property_types.parking_space = type === 'parking_space';
      this.form.property_types.single_garage = type === 'single_garage';
      this.form.property_types.multiple_garages = type === 'multiple_garages';
      this.form.property_types.parking_lot = type === 'parking_lot';
      this.form.property_types.multi_storey_parking = type === 'multi_storey_parking';
      // Multifamily
      this.form.property_types.multifamily = (type === 'multifamily' || type === 'duplex' || type === 'block_of_flats' || type === 'apartment_complex') ? true : false;
      this.form.property_types.duplex = type === 'duplex';
      this.form.property_types.block_of_flats = type === 'block_of_flats';
      this.form.property_types.apartment_complex = type === 'apartment_complex';
      // Warehouse
      this.form.property_types.warehouses = type === 'warehouses';
      // Factory
      this.form.property_types.factories = type === 'factories';
      // Powerplants
      this.form.property_types.power_plants = (type === 'power_plants' || type === 'hydroelectric_plant' || type === 'coal_fired_plant' || type === 'diesel_fired_plant' || type === 'geothermal_plant' || type === 'gas_fired_plant' || type === 'solar_power_plant' || type === 'wind_power_plant' || type === 'tidal_power_plant') ? true : false;
      this.form.property_types.hydroelectric_plant = type === 'hydroelectric_plant';
      this.form.property_types.coal_fired_plant = type === 'coal_fired_plant';
      this.form.property_types.diesel_fired_plant = type === 'diesel_fired_plant';
      this.form.property_types.geothermal_plant = type === 'geothermal_plant';
      this.form.property_types.gas_fired_plant = type === 'gas_fired_plant';
      this.form.property_types.solar_power_plant = type === 'solar_power_plant';
      this.form.property_types.wind_power_plant = type === 'wind_power_plant';
      this.form.property_types.tidal_power_plant = type === 'tidal_power_plant';
      // Land
      this.form.property_types.land = (type === 'land' || type === 'greenfield' || type === 'brownfield') ? true : false;
      this.form.property_types.greenfield = type === 'greenfield';
      this.form.property_types.brownfield = type === 'brownfield';
      // Luxury
      this.form.property_types.luxury = (type === 'luxury' || type === 'mansion' || type === 'villa') ? true : false;
      this.form.property_types.mansion = type === 'mansion';
      this.form.property_types.villa = type === 'villa';
      // Special Use
      this.form.property_types.special_use = (type === 'special_use' || type === 'church' || type === 'cemetery' || type === 'park' || type === 'school' || type === 'barn' || type === 'campsite') ? true : false;
      this.form.property_types.church = type === 'church';
      this.form.property_types.cemetery = type === 'cemetery';
      this.form.property_types.park = type === 'park';
      this.form.property_types.school = type === 'school';
      this.form.property_types.barn = type === 'barn';
      this.form.property_types.campsite = type === 'campsite';
      // Rooms
      this.form.property_types.rooms = type === 'rooms';
    },
    refreshGlobalAddRoomEvent() {
      this.$root.$off('addRoomGlobal');
      this.$root.$on('addRoomGlobal', (data) => this.addRoom(data));
    },
    scrollToView(id) {
      const timeout = setTimeout(() => {
        let el = document.getElementById(id);
        let rect = el.getBoundingClientRect();
        let height = el.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: height - 150, behavior: 'smooth' });
        clearTimeout(timeout);
      }, 500);
    },
    resetFormValues() {
      // Do not reset at least following fields:
      // is_let, is_rent, is_sale, is_buy
      // is_short_term, property_category_id, property_type_details_id, property_type_id
      // If we are in studio room dont reset following
      if (this.form.property_type_details_id === 8) {
        this.form.studio_room = true;
        this.form.single_room = 0;
        this.form.double_room = 0;
        this.form.living_room = 1;
        this.form.dining_room = 0;
        this.form.kitchen = 1;
      } else {
        this.form.single_room = '';
        this.form.double_room = '';
        this.form.living_room = '';
        this.form.dining_room = '';
        this.form.kitchen = '';
      }

      // Reset form values
      /* Date Picker */
      this.form.date_range = {
        start: null,
        end: null
      };
      // For check_in_to and check_out_to values
      this.form.date_range_to = {
        start: null,
        end: null
      };
      this.form.is_coming_soon = false;
      this.form.date_start = null;
      this.form.date_end = null;
      this.form.date_start_to = null;
      this.form.date_end_to = null;
      this.form.is_date_range_end_tbd = false;
      this.form.show_range_date = true;
      this.form.single_start_date = null;
      this.form.check_in_flexible = false;
      this.form.check_out_flexible = false;
      this.form.check_in_instructions_id = '';
      this.form.check_out_instructions_id = '';
      this.form.check_in_from = null;
      this.form.check_in_to = null;
      this.form.check_out_from = null;
      this.form.check_out_to = null;
      /* End Date Picker */
      this.form.property_title = '';
      this.form.property_description = '';
      /* Start of property details */
      this.form.easements = false;
      this.form.epc_rating = '';
      this.form.floor_plans = false;
      this.form.floors = '';
      this.form.basement = false;
      this.form.attic = false;
      this.form.balcony = false;
      this.form.front_porch = false;
      this.form.rear_porch = false;
      this.form.front_garden = false;
      this.form.rear_garden = false;
      this.form.garden_terrace = false;
      this.form.conservatory = false;
      this.form.green_house = '';
      this.form.shed = '';
      this.form.garage = '';
      this.form.driveway = '';
      this.form.parking = false;
      this.form.parking_paid = false;
      this.form.parking_fee = '';
      this.form.parking_spaces = '';
      this.form.charging_station = false;
      this.form.charging_station_paid = false;
      this.form.solar_panels = false;
      this.form.room = ''; // Used for edit rooms #look at editRoom() function - it should hold current room data
      this.form.rooms = [];
      this.form.twin_room = '';
      this.form.twin_double_room = '';
      this.form.triple_room = '';
      this.form.quadruple_room = '';
      this.form.family_room = '';
      this.form.box_room = '';
      this.form.fridge = false;
      this.form.freezer = false;
      this.form.microwave = false;
      this.form.oven = false;
      this.form.kettle = false;
      this.form.coffee = false;
      this.form.toaster = false;
      this.form.bath_room = '';
      this.form.bath = '';
      this.form.shower = '';
      this.form.sink = '';
      this.form.wc = '';
      this.form.downstairs_wc = '';
      this.form.inside_pool = false;
      this.form.inside_pool_paid = false;
      this.form.outside_pool = false;
      this.form.outside_pool_paid = false;
      this.form.sauna = false;
      this.form.sauna_paid = false;
      this.form.hot_tub = false;
      this.form.hot_tub_paid = false;
      this.form.spa = false;
      this.form.spa_paid = false;
      this.form.gym = false;
      this.form.gym_paid = false;
      this.form.cinema_room = false;
      this.form.cinema_room_paid = false;
      this.form.bicycle = false;
      this.form.bicycle_paid = false;
      this.form.wifi = false;
      this.form.wifi_paid = false;
      this.form.restaurant = false;
      this.form.room_service = false;
      this.form.room_service_paid = false;
      this.form.service_fee = '';
      this.form.bar = false;
      this.form.h24_front_desk = false;
      this.form.non_smoking_rooms = false;
      this.form.smoking_rooms = false;
      this.form.airport_shuttle = false;
      this.form.air_conditioning = false;
      this.form.breakfast = false;
      this.form.breakfast_paid = false;
      this.form.stove = false;
      this.form.security_cameras = false;
      this.form.fire_rings = false; // Campsite as well
      this.form.fire_rings_paid = false; // Campsite as well
      this.form.other = '';
      /* Start Campsite */
      this.form.tents = '';
      this.form.caravans = '';
      this.form.hammocks = false;
      this.form.campsite_shower = false;
      this.form.campsite_shower_paid = false;
      this.form.campsite_shower_fee = '';
      this.form.campsite_toilet = false;
      this.form.campsite_toilet_paid = false;
      this.form.campsite_toilet_fee = '';
      this.form.electricity = false;
      this.form.electricity_paid = false;
      this.form.water_supply = false;
      this.form.water_supply_paid = false;
      this.form.fire_fuels = false;
      this.form.fire_fuels_paid = false;
      /* End Campsite */
      /* End of property details */
      this.form.cancellation_period = '';
      this.form.cancellation_late_rule_id = '';
      this.form.cancellation_fee = '';
      // Term Discount
      this.form.is_discount_term_term = false;
      this.form.discount_term_term = '';
      this.form.discount_fee_type_term = '1';
      this.form.discount_fee_term = '';
      this.form.refreshTermDiscountFeePlaceholder = 0;
      this.form.refreshLastMinuteDiscountFeePlaceholder = 0;
      this.form.refreshNoOfGuestsDiscountFeePlaceholder = 0;
      // Last Minute Discount
      this.form.is_discount_term_last_minute = false;
      this.form.discount_term_last_minute = '';
      this.form.discount_fee_type_last_minute = '1';
      this.form.discount_fee_last_minute = '';
      // Number of Guests Discount
      this.form.is_discount_term_no_of_guests = false;
      this.form.discount_term_no_of_guests = '';
      this.form.discount_fee_type_no_of_guests = '1';
      this.form.discount_fee_no_of_guests = '';
      // House Rules must be stored as an array of strings (json ?)
      this.form.house_rules = [];
      this.form.rule = '';
      this.form.cleaning_service = false;
      this.form.cleaning_service_paid = false;
      this.form.cleaning_fee = '';
      this.form.star_rating = '';
      this.form.property_square_meter = '';
      this.form.price = '';
      this.form.tenants_guests = '';
      this.form.document_id = '';
      this.form.property_history_id = '';
      this.form.property_rent_type_id = '';
      this.form.property_rent_type = {id: null, type: null};
      this.form.is_furnished = false;
      this.form.is_freehold = '';
      this.form.is_shared_ownership = false;
      this.form.is_over_60s = false;
      this.form.is_accessible = false;
      this.form.is_pet_friendly = false;
      this.form.pet_friendly_paid = false;
      this.form.pet_friendly_fee = '';
      this.form.events_allowed = false;
      this.form.is_dss_accepted = false;
      this.form.is_holiday = false;
      this.form.is_seashore = false;
      this.form.is_waterfront = false;
      this.form.is_mixed_use = false;
      this.form.is_investment = false;
      // is_buy_to_let = false;
      /* Start of Investment Details */
      this.form.roi = '';
      this.form.roa = '';
      this.form.yield = '';
      this.form.cash_flow = '';
      this.form.share_percentage = '';
      this.form.maintenance_cost = '';
      this.form.number_of_flats = '';
      this.form.number_of_blocks = '';
      this.form.number_of_units = '';
      /* End of Investment Details */
      this.form.investment_property_id = '';
      this.form.is_hmo = false;
      this.form.is_tenanted = false;
      this.form.is_cash = false;
      this.form.is_auction = false;
      this.form.declaration_of_truth = false;
    },
    toggleShortTerm() {
      this.$nextTick(function () {
        this.form.is_short_term = !this.form.is_short_term;
      });
    }
  },
  async mounted() {
    await this.initialComponentLoad();
    await this.getDrafts();
  },
  created() {
    // Global addRoom so we can trigger it from Modal opened in App.vue
    this.$root.$on('addRoomGlobal', (data) => this.addRoom(data));
    this.$root.$on('refreshDrafts', () => this.getDrafts());
  },
  beforeDestroy() {
    // Must destroy global trigger before we leave the page/component, otherwise it will layer on each other
    // and when we call it, it will trigger several times, breaking functionality
    this.$root.$off('addRoomGlobal');
    this.$root.$off('refreshDrafts');
  },
  watch: {
    'form.is_short_term': {
      handler: function (val) {
        if (val === 1 || val === true) {
          // Additional check if user is_host and done_onboarding (STRIPE ACCOUNT)
          if (this.$store.state.auth.user && this.$store.state.auth.user.is_host == 1) {
            this.showBecomeHostMsg = false;
            this.form.is_paid_ad = '';
            this.form.is_free_ad = '';
            // swap to true whenever we toggle short-term, as its not visible on short-term but should be set to true
            this.form.is_date_range_end_tbd = true;
          } else {
            // Toggle back to false
            this.toggleShortTerm();
            this.showBecomeHostMsg = true;
          }
        }
        if (this.editing === false) {
          // Trigger only if not loading draft data
          if (!this.draftLoad) {
            this.form.property_rent_type_id = '';
            // swap to false whenever we toggle long-term in order to reset its value
            this.form.is_date_range_end_tbd = false;
            // reset coming soon toggle
            this.form.is_coming_soon = false;
          }
        }
      }, deep: true
    },
    'form.date_range': {
      handler: function (val, oldVal) {
        let oldStartDate = null;
        let oldEndDate = null;
        let newStartDate = null;
        let newEndDate = null;

        if (oldVal && oldVal.start) {
          oldStartDate = this.moment(oldVal.start);
        }
        if (oldVal &&oldVal.end) {
          oldEndDate = this.moment(oldVal.end);
        }
        if (val && val.start) {
          newStartDate = this.moment(val.start);
        }
        if (val && val.end) {
          newEndDate = this.moment(val.end);
        }

        // On Edit
        if ((val === null || val === 'null') && (this.form.date_start !== null || this.form.date_start !== '')) {
          // If only start date exists
          if (this.form.date_end === null || this.form.date_end === '' || this.form.date_end === 'null') {
            this.form.date_range_to = {
              start: this.moment(this.form.date_start, 'DD-MM-YYYY').format('YYYY-MM-DD'),
              end: null
            };
            this.form.date_range = {
              start: this.moment(this.form.date_start, 'DD-MM-YYYY').format('YYYY-MM-DD'),
              end: null
            };
          } else {
            // If both dates exists
            let startDate = new Date(this.moment(this.form.date_start, 'DD-MM-YYYY').format('YYYY-MM-DD'));
            let endDate = new Date(this.moment(this.form.date_end, 'DD-MM-YYYY').format('YYYY-MM-DD'));
            this.form.date_range_to = {start: startDate, end: endDate};
            this.form.date_range = {start: startDate, end: endDate};
            // Used to force refresh on date_picker
            this.changed_dates++;
          }
        }

        // Normal processing applied only if just date changed but not time
        if ((oldStartDate && newStartDate && !oldStartDate.isSame(newStartDate, 'day')) || (oldEndDate && newEndDate && !oldEndDate.isSame(newEndDate, 'day'))) {
          this.form.date_range_to = {...this.form.date_range};
        }
        let that = this;
        const revalidate = setInterval(function () {
          // Revalidate
          that.validateAvailability();
          clearInterval(revalidate);
        }, 500);
      }, deep: true
    },
    'form.single_start_date': {
      handler: function (val) {
        // Trigger only if not loading draft data
        if (this.draftLoad) {
          // Used to force refresh on date_picker
          this.changed_singular_date++;
         return;
        }
        if (this.form.is_short_term) {
          if (this.firstLoad === true) {
            this.firstLoad = false;
            this.form.date_range_to = {start: val, end: val};
            this.form.date_range = {start: val, end: val};
          } else {
            this.form.date_range_to = {start: val, end: val};
            // Check in and check out values reset to picked date
            this.form.check_in_from = val;
            this.form.check_in_to = val;
            this.form.check_out_from = val;
            this.form.check_out_to = val;

            this.form.date_range = {start: val, end: val};
          }
        } else {
          // this.form.date_range_to = {start: val, end: null};
          this.form.date_range = {start: val, end: null};
        }
        // Used to force refresh on date_picker
        this.changed_singular_date++;
        // Revalidate
        this.validateAvailability();
      }, deep: true
    },
    'form.show_range_date': {
      handler(val, oldVal) {
        // Trigger only if not loading draft data
        if (this.draftLoad) {
          // Used to force refresh on date_picker
          this.changed_dates++;
          return;
        }
        // On editing skip at first iteration
        if (this.editing && this.firstLoadShowRangeDate) {
          this.firstLoadShowRangeDate = false;
        } else {
          if (val == true) {
            // Reset values
            this.form.date_start = null;
            this.form.date_end = null;
            this.form.date_range = {start: null, end: null};
            // Used to force refresh on date_picker
            this.changed_dates++;
          } else {
            this.form.single_start_date = null;
            // Reset values
            this.form.date_start = null;
            this.form.date_end = null;
            this.form.date_range = {start: null, end: null};
          }
        }
      }
    },
    'form.is_date_range_end_tbd': {
      handler(val, oldVal) {
        // Set true or false instead of 0/1 (after we submit, on failed validation our boolean values are swaped)
        if (val === 1) {
          this.form.is_date_range_end_tbd = true;
        }
        if (val === 0) {
          this.form.is_date_range_end_tbd = false;
        }
        // Check and recalculate dates
        if (this.form.is_date_range_end_tbd === true || this.form.is_date_range_end_tbd === 1 || this.form.is_date_range_end_tbd === '1') {
          if (this.form.single_start_date !== null && this.form.single_start_date !== '' && this.form.single_start_date !== 'null') {
            // this.form.date_range_to = {start: this.moment(this.form.single_start_date, 'DD-MM-YYYY').format('YYYY-MM-DD'), end: null};
            this.form.date_range = {start: this.moment(this.form.single_start_date, 'DD-MM-YYYY').format('YYYY-MM-DD'), end: null};
          } else {
            // Reset values
            this.form.date_start = null;
            this.form.date_end= null;
            // this.form.date_start_to = null;
            // this.form.date_end_to = null;
            // this.form.date_range_to = {start: null, end: null};
            this.form.date_range = {start: null, end: null};
            // Used to force refresh on date_picker
            this.changed_dates++;
          }
        }
        if (this.form.is_date_range_end_tbd === false || this.form.is_date_range_end_tbd === 0 || this.form.is_date_range_end_tbd === '0') {
          if (this.editData && this.editData.data && this.editData.data.is_short_term !== 1) { // && this.editData.data.is_let !== 1
            this.form.single_start_date = null;
            if (this.form.date_start !== null && this.form.date_start !== '' && this.form.date_start !== 'null' &&
                this.form.date_end !== null && this.form.date_end !== '' && this.form.date_end !== 'null'
            ) {
              let startDate = this.moment(this.form.date_start, 'DD-MM-YYYY').format('YYYY-MM-DD');
              let endDate = this.moment(this.form.date_end, 'DD-MM-YYYY').format('YYYY-MM-DD');
              // let startDateTo = this.moment(this.form.date_start_to, 'DD-MM-YYYY').format('YYYY-MM-DD');
              // let endDateTo = this.moment(this.form.date_end_to, 'DD-MM-YYYY').format('YYYY-MM-DD');
              // this.form.date_range_to = {start: startDateTo, end: endDateTo};
              this.form.date_range = {start: startDate, end: endDate};
            } else {
              // Reset values
              this.form.date_start = null;
              this.form.date_end = null;
              // this.form.date_start_to = null;
              // this.form.date_end_to= null;
              // this.form.date_range_to = {start: null, end: null};
              this.form.date_range = {start: null, end: null};
            }
          }
        }

        // Show or hide correct calendar type (range or singular)
        if (val === false || val === 0) {
          this.form.show_range_date = true;
        } else {
          this.form.show_range_date = false;
        }

      }, deep: true
    },
    'id': {
      handler: function (val) {
        if (!val) {
          this.loading = true;
          this.editDataLoaded = true;

          // Reset Component Data for SELL, BUY, LET, RENT router-view
          Object.assign(this.$data, this.$options.data.apply(this))
          this.initialComponentLoad();
          this.getDrafts();

          this.loading = false;
        }
      }, deep: true
    },
    'postTypeProp': {
      handler: function (val) {
        if (val) {
          this.loading = true;
          this.editDataLoaded = true;

          // Trigger only if not loading draft data
          if (!this.draftLoad) {
            this.initialComponentLoad();
          }

          this.loading = false;
        }
      }, deep: true
    },
    '$store.state.properties.prop_power_plant_type_details': function() {
      this.propertyCategoriesOptions = this.$store.state.properties.property_categories_options;
      this.propertyTypesOptions = this.$store.state.properties.property_types_options;
      /* First Level Dropdown - Holds property types according to property categories - skipped room as no subitems */
      this.propResidentialTypesOptions = this.$store.state.properties.prop_residential_types_options;
      this.propCommercialTypesOptions = this.$store.state.properties.prop_commercial_types_options;
      this.propIndustrialTypesOptions = this.$store.state.properties.prop_industrial_types_options;
      this.propLandTypesOptions = this.$store.state.properties.prop_land_types_options
      this.propLuxuryTypesOptions = this.$store.state.properties.prop_luxury_types_options;
      this.propSpecialUseTypesOptions = this.$store.state.properties.prop_special_use_types_options;
      /* Second Level Dropdown - Holds property type details according to property types */
      // Residential
      this.propHouseTypeDetails = this.$store.state.properties.prop_house_type_details;
      this.propFlatTypeDetails = this.$store.state.properties.prop_flat_type_details;
      // Commercial
      this.propOfficeTypeDetails = this.$store.state.properties.prop_office_type_details;
      this.propRetailTypeDetails = this.$store.state.properties.prop_retail_type_details;
      this.propLeisureTypeDetails = this.$store.state.properties.prop_leisure_type_details;
      this.propHealthcareTypeDetails = this.$store.state.properties.prop_healthcare_type_details;
      this.propParkingTypeDetails = this.$store.state.properties.prop_parking_type_details;
      this.propMultifamilyTypeDetails = this.$store.state.properties.prop_multifamily_type_details;
      // Industrial (we skipped Warehouse and Factory as no subcategories)
      this.propPowerPlantTypeDetails = this.$store.state.properties.prop_power_plant_type_details;
    },
    "$route.query": {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        // console.log('query value');
        // console.log(val);
        // console.log('===============BEFORE CHANGES=====================');
        // console.log('is sale');
        // console.log(this.form.is_sale);
        // console.log('is buy');
        // console.log(this.form.is_buy);
        // console.log('is let');
        // console.log(this.form.is_let);
        // console.log('is rent');
        // console.log(this.form.is_rent);
        // console.log('is paid');
        // console.log(this.form.is_paid_ad);
        // console.log('is free');
        // console.log(this.form.is_free_ad);
        if (this.$route.query && this.$route.query.type) {
          if (!this.id) {
            // Reset some values
            this.postType = 'reset';
            this.editing = false;
            this.editDataLoaded = true;
            // Sell
            if (this.$route.query.type === 'sell') {
              // console.log('query type');
              // console.log(this.$route.query.type)
              this.postType = 1;
            }
            // Buy
            if (this.$route.query.type === 'buy') {
              // console.log('query type');
              // console.log(this.$route.query.type)
              this.postType = 2;
            }
            // Let
            if (this.$route.query.type === 'let') {
              // console.log('query type');
              // console.log(this.$route.query.type)
              this.postType = 3;
            }
            // Rent
            if (this.$route.query.type === 'rent') {
              // console.log('query type');
              // console.log(this.$route.query.type)
              this.postType = 4;
            }
          }
        }
      }
    },
    "postType": {
      immediate: true,
      deep: true,
      handler (type, oldType) {
        // Trigger only if not loading draft data
        if (this.draftLoad) {
          return;
        }

        // Get property type and category dropdown
        let dropdownEl = document.getElementById('mainMenuPropertyCategory');

        if (this.editDataLoaded) {
          switch (type) {
            case 1: // Sell
              this.form.is_let = false;
              this.form.is_rent = false;
              this.form.is_sale = true;
              this.form.is_buy = false;
              this.form.is_swap = false;
              // Restart long-short term from LET type
              this.form.is_short_term = false;
              // Restart property type and category dropdown fields
              this.resetDisabledCheckboxes();
              this.setPropertyTypes();
              this.form.property_category_id = '';
              this.form.property_type_id = '';
              this.form.property_type_details_id = '';
              this.form.propertyCatType = 'Select premises type';
              this.form.propertyCategory = '';
              if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
                document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
              }
              // Restart ad title
              this.form.property_title = '';
              // Restart is_coming_soon
              this.form.is_coming_soon = false;
              break;
            case 2: // Buy
              this.form.is_let = false;
              this.form.is_rent = false;
              this.form.is_sale = false;
              this.form.is_buy = true;
              this.form.is_swap = false;
              // Restart long-short term from LET type
              this.form.is_short_term = false;
              // Restart property type and category dropdown fields
              this.resetDisabledCheckboxes();
              this.setPropertyTypes();
              this.form.property_category_id = '';
              this.form.property_type_id = '';
              this.form.property_type_details_id = '';
              this.form.propertyCatType = 'Select premises type';
              this.form.propertyCategory = '';
              if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
                document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
              }
              // Restart ad title
              this.form.property_title = '';
              // Restart is_coming_soon
              this.form.is_coming_soon = false;
              break;
            case 3: // Let
              this.form.is_let = true;
              this.form.is_rent = false;
              this.form.is_sale = false;
              this.form.is_buy = false;
              this.form.is_swap = false;
              // Restart long-short term from LET type
              this.form.is_short_term = false;
              // Restart property type and category dropdown fields
              this.resetDisabledCheckboxes();
              this.setPropertyTypes();
              this.form.property_category_id = '';
              this.form.property_type_id = '';
              this.form.property_type_details_id = '';
              this.form.propertyCatType = 'Select premises type';
              this.form.propertyCategory = '';
              if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
                document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
              }
              // Restart ad title
              this.form.property_title = '';
              // As its Let, make sure to reset some values related to sell, buy, room so we dont add it in the back-end
              this.form.is_investment = false;
              this.form.is_mixed_use = false;
              this.form.is_auction = false;
              this.form.is_cash = false;
              // Restart is_coming_soon
              this.form.is_coming_soon = false;
              break;
            case 4: // Rent
              this.form.is_let = false;
              this.form.is_rent = true;
              this.form.is_sale = false;
              this.form.is_buy = false;
              this.form.is_swap = false;
              // Restart long-short term from LET type
              this.form.is_short_term = false;
              // Restart property type and category dropdown fields
              this.resetDisabledCheckboxes();
              this.setPropertyTypes();
              this.form.property_category_id = '';
              this.form.property_type_id = '';
              this.form.property_type_details_id = '';
              this.form.propertyCatType = 'Select premises type';
              this.form.propertyCategory = '';
              if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
                document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
              }
              // Restart ad title
              this.form.property_title = '';
              // As its Rent, make sure to reset some values related to sell, buy, room so we dont add it in the back-end
              this.form.is_investment = false;
              this.form.is_mixed_use = false;
              this.form.is_auction = false;
              this.form.is_cash = false;
              // Restart is_coming_soon
              this.form.is_coming_soon = false;
              break;
            case 5: // Swap
              this.form.is_let = false;
              this.form.is_rent = false;
              this.form.is_sale = false;
              this.form.is_buy = false;
              this.form.is_swap = true;
              // Restart long-short term from LET type
              this.form.is_short_term = false;
              // Restart property type and category dropdown fields
              this.resetDisabledCheckboxes();
              this.setPropertyTypes();
              this.form.property_category_id = '';
              this.form.property_type_id = '';
              this.form.property_type_details_id = '';
              this.form.propertyCatType = 'Select premises type';
              this.form.propertyCategory = '';
              if (dropdownEl && dropdownEl.classList.contains('select-clicked')) {
                document.getElementById('mainMenuPropertyCategory').classList.toggle('select-clicked');
              }
              // Restart ad title
              this.form.property_title = '';
              // Restart is_coming_soon
              this.form.is_coming_soon = false;
              break;
          }

        }

        // Clear geocoder search box (so we can prepopulate it according to postType in a sec)
        let element = this.$refs.geocoderAddEditAdRef;
        if (element && element.hasChildNodes()) {
          element.firstChild.remove();
        }

        this.loading = false;
        // Add geocoder search box to address fields
        this.initGeocodeBox(type);
        this.initGeocodeBoxPropertyContactAddress(type);

        // console.log('===============AFTER CHANGES=====================');
        // console.log('is sale');
        // console.log(this.form.is_sale);
        // console.log('is buy');
        // console.log(this.form.is_buy);
        // console.log('is let');
        // console.log(this.form.is_let);
        // console.log('is rent');
        // console.log(this.form.is_rent);
        // console.log('is paid');
        // console.log(this.form.is_paid_ad);
        // console.log('is free');
        // console.log(this.form.is_free_ad);
      }
    },
    "form.propertyCatType": function (propCat) {
      if (this.editing === false) {
        // Trigger only if not loading draft data
        if (this.draftLoad) {
          return;
        }
        // Reset form values
        this.resetFormValues();
      }
    },
    "form.property_category_id": function (prod_cat_id) {
      switch(prod_cat_id) {
        case 1: // Residential
          this.form.is_residential = true;
          this.form.is_commercial = false;
          this.form.is_industrial = false;
          this.form.is_luxury = false;
          this.form.is_land = false;
          this.form.is_special_use = false;
          this.form.is_room = false;
          // this.form.studio_room = false;
          break;
        case 2: // Commercial
          this.form.is_residential = false;
          this.form.is_commercial = true;
          this.form.is_industrial = false;
          this.form.is_luxury = false;
          this.form.is_land = false;
          this.form.is_special_use = false;
          this.form.is_room = false;
          this.form.studio_room = false;
          break;
        case 3: // Industrial
          this.form.is_residential = false;
          this.form.is_commercial = false;
          this.form.is_industrial = true;
          this.form.is_luxury = false;
          this.form.is_land = false;
          this.form.is_special_use = false;
          this.form.is_room = false;
          this.form.studio_room = false;
          break;
        case 4: // Land
          this.form.is_residential = false;
          this.form.is_commercial = false;
          this.form.is_industrial = false;
          this.form.is_luxury = false;
          this.form.is_land = true;
          this.form.is_special_use = false;
          this.form.is_room = false;
          this.form.studio_room = false;
          break;
        case 5: // Luxury
          this.form.is_residential = false;
          this.form.is_commercial = false;
          this.form.is_industrial = false;
          this.form.is_luxury = true;
          this.form.is_land = false;
          this.form.is_special_use = false;
          this.form.is_room = false;
          this.form.studio_room = false;
          break;
        case 6: // Special Use
          this.form.is_residential = false;
          this.form.is_commercial = false;
          this.form.is_industrial = false;
          this.form.is_luxury = false;
          this.form.is_land = false;
          this.form.is_special_use = true;
          this.form.is_room = false;
          this.form.studio_room = false;
          break;
        case 7: // Room
          this.form.is_residential = false;
          this.form.is_commercial = false;
          this.form.is_industrial = false;
          this.form.is_luxury = false;
          this.form.is_land = false;
          this.form.is_special_use = false;
          this.form.is_room = true;
          this.form.studio_room = false;
          // As its room, make sure to reset some values related to sell, buy, room so we dont add it in the back-end
          this.form.is_investment = false;
          this.form.is_mixed_use = false;
          this.form.is_auction = false;
          this.form.is_cash = false;
          break;
        default:
          this.form.is_residential = false;
          this.form.is_commercial = false;
          this.form.is_industrial = false;
          this.form.is_luxury = false;
          this.form.is_land = false;
          this.form.is_special_use = false;
          this.form.is_room = false;
          this.form.studio_room = false;
          break;
      }

      // Add geocoder search box to address fields
      let type = this.postType;
      this.initGeocodeBox(type);
      this.initGeocodeBoxPropertyContactAddress(type);
    },
    "form.property_type_details_id": function (prod_type_detail_id) {
      // If we picked something else than Studio Flat reset studio_room value
      if (prod_type_detail_id !== 8) {
        this.form.studio_room = false;
      } else {
        this.form.studio_room = true;
      }
    },
    "form.cancellation_late_rule_id": {
      handler (val) {
        switch(Number(val)) {
          case 1:
            this.form.cancellation_rule = {rule: 'First Night Price Charged'};
            break;
          case 2:
            this.form.cancellation_rule = {rule: 'Full Stay Price Charged'};
            break;
          case 3:
            this.form.cancellation_rule = {rule: 'Fixed Fee Charged'};
            break;
        }
      }, deep: true
    },
    // Discounts
    "form.discount_fee_type_term": {
      handler (val) {
        this.refreshTermDiscountFeePlaceholder++;
      }, immediate: true
    },
    "form.discount_fee_type_last_minute": {
      handler (val) {
        this.refreshLastMinuteDiscountFeePlaceholder++;
      }, immediate: true
    },
    "form.discount_fee_type_no_of_guests": {
      handler (val) {
        this.refreshNoOfGuestsDiscountFeePlaceholder++;
      }, immediate: true
    },
    // Start Free/Paid Toggles
    "form.parking": function (bool) {
      if (bool === false || bool == 0) {
        this.form.parking_paid = bool;
      }
    },
    "form.is_pet_friendly": function (bool) {
      if (bool === false || bool == 0) {
        this.form.pet_friendly_paid = bool;
      }
    },
    "form.room_service": function (bool) {
      if (bool === false || bool == 0) {
        this.form.room_service_paid = bool;
      }
    },
    "form.cleaning_service": function (bool) {
      if (bool === false || bool == 0) {
        this.form.cleaning_service_paid = bool;
      }
    },
    "form.wifi": function (bool) {
      if (bool === false || bool == 0) {
        this.form.wifi_paid = bool;
      }
    },
    "form.breakfast": function (bool) {
      if (bool === false || bool == 0) {
        this.form.breakfast_paid = bool;
      }
    },
    "form.inside_pool": function (bool) {
      if (bool === false || bool == 0) {
        this.form.inside_pool_paid = bool;
      }
    },
    "form.outside_pool": function (bool) {
      if (bool === false || bool == 0) {
        this.form.outside_pool_paid = bool;
      }
    },
    "form.cinema_room": function (bool) {
      if (bool === false || bool == 0) {
        this.form.cinema_room_paid = bool;
      }
    },
    "form.gym": function (bool) {
      if (bool === false || bool == 0) {
        this.form.gym_paid = bool;
      }
    },
    "form.sauna": function (bool) {
      if (bool === false || bool == 0) {
        this.form.sauna_paid = bool;
      }
    },
    "form.hot_tub": function (bool) {
      if (bool === false || bool == 0) {
        this.form.hot_tub_paid = bool;
      }
    },
    "form.spa": function (bool) {
      if (bool === false || bool == 0) {
        this.form.spa_paid = bool;
      }
    },
    "form.bicycle": function (bool) {
      if (bool === false || bool == 0) {
        this.form.bicycle_paid = bool;
      }
    },
    "form.fire_rings": function (bool) {
      if (bool === false || bool == 0) {
        this.form.fire_rings_paid = bool;
      }
    },
    /* Watch Property Type Filters */
    /* Residential - Houses */
    'form.property_types.detached_house': async function (val) {
      if (val === true) {
        this.form.property_types.detached_house = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.houses === true) {
            if (this.form.property_types.houses_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              this.form.property_types.detached_house = true;

              await this.setPropertyTypes('detached_house');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
              let type = this.propertyTypesOptions.find(o => o.type === 'House');
              let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Detached');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.houses = true;

            await this.setPropertyTypes('detached_house');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
            let type = this.propertyTypesOptions.find(o => o.type === 'House');
            let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Detached');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('detached_house');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
          let type = this.propertyTypesOptions.find(o => o.type === 'House');
          let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Detached');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.semi_detached_house': async function (val) {
      if (val === true) {
        this.form.property_types.semi_detached_house = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.houses === true) {
            if (this.form.property_types.houses_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              // Else trigger code
              this.form.property_types.houses = true;
              await this.setPropertyTypes('semi_detached_house');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
              let type = this.propertyTypesOptions.find(o => o.type === 'House');
              let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Semi-Detached');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.houses = true;

            await this.setPropertyTypes('semi_detached_house');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
            let type = this.propertyTypesOptions.find(o => o.type === 'House');
            let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Semi-Detached');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('semi_detached_house');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
          let type = this.propertyTypesOptions.find(o => o.type === 'House');
          let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Semi-Detached');

          await this.setPropertyCatType(category, type, typeDetail);
        }

      }
    },
    'form.property_types.mid_terraced': async function (val) {
      if(val === true) {
        this.form.property_types.mid_terraced = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.houses === true) {
            if (this.form.property_types.houses_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              this.form.property_types.houses = true;

              await this.setPropertyTypes('mid_terraced');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
              let type = this.propertyTypesOptions.find(o => o.type === 'House');
              let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Mid-Terraced');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.houses = true;

            await this.setPropertyTypes('mid_terraced');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
            let type = this.propertyTypesOptions.find(o => o.type === 'House');
            let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Mid-Terraced');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('mid_terraced');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
          let type = this.propertyTypesOptions.find(o => o.type === 'House');
          let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Mid-Terraced');

          await this.setPropertyCatType(category, type, typeDetail);
        }


      }
    },
    'form.property_types.end_terraced': async function (val) {
      if(val === true) {
        this.form.property_types.end_terraced = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.houses === true) {
            if (this.form.property_types.houses_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              this.form.property_types.houses = true;

              await this.setPropertyTypes('end_terraced');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
              let type = this.propertyTypesOptions.find(o => o.type === 'House');
              let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'End-Terraced');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.houses = true;

            await this.setPropertyTypes('end_terraced');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
            let type = this.propertyTypesOptions.find(o => o.type === 'House');
            let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'End-Terraced');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('end_terraced');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
          let type = this.propertyTypesOptions.find(o => o.type === 'House');
          let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'End-Terraced');

          await this.setPropertyCatType(category, type, typeDetail);
        }


      }
    },
    'form.property_types.detached_bungalow': async function (val) {
      if(val === true) {
        this.form.property_types.detached_bungalow = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.houses === true) {
            if (this.form.property_types.houses_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              this.form.property_types.houses = true;

              await this.setPropertyTypes('detached_bungalow');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
              let type = this.propertyTypesOptions.find(o => o.type === 'House');
              let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Detached Bungalow');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();
            this.form.property_types.houses = true;

            await this.setPropertyTypes('detached_bungalow');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
            let type = this.propertyTypesOptions.find(o => o.type === 'House');
            let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Detached Bungalow');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('detached_bungalow');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
          let type = this.propertyTypesOptions.find(o => o.type === 'House');
          let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Detached Bungalow');

          await this.setPropertyCatType(category, type, typeDetail);
        }


      }
    },
    'form.property_types.semi_detached_bungalow': async function (val) {
      if(val === true) {
        this.form.property_types.semi_detached_bungalow = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.houses === true) {
            if (this.form.property_types.houses_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('semi_detached_bungalow');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
              let type = this.propertyTypesOptions.find(o => o.type === 'House');
              let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Semi-Detached Bungalow');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.houses = true;

            await this.setPropertyTypes('semi_detached_bungalow');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
            let type = this.propertyTypesOptions.find(o => o.type === 'House');
            let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Semi-Detached Bungalow');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('semi_detached_bungalow');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
          let type = this.propertyTypesOptions.find(o => o.type === 'House');
          let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Semi-Detached Bungalow');

          await this.setPropertyCatType(category, type, typeDetail);
        }


      }
    },
    'form.property_types.cottage': async function (val) {
      if(val === true) {
        this.form.property_types.cottage = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.houses === true) {
            if (this.form.property_types.houses_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              this.form.property_types.houses = true;

              await this.setPropertyTypes('cottage');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
              let type = this.propertyTypesOptions.find(o => o.type === 'House');
              let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Cottage');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.houses = true;

            await this.setPropertyTypes('cottage');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
            let type = this.propertyTypesOptions.find(o => o.type === 'House');
            let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Cottage');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('cottage');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
          let type = this.propertyTypesOptions.find(o => o.type === 'House');
          let typeDetail = this.propHouseTypeDetails.find(o => o.type_detail === 'Cottage');

          await this.setPropertyCatType(category, type, typeDetail);
        }


      }
    },
    /* Residential - Flats */
    'form.property_types.flat': async function (val) {
      if(val === true) {
        this.form.property_types.flat = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.flats === true) {
            if (this.form.property_types.flats_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('flat');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
              let type = this.propertyTypesOptions.find(o => o.type === 'Flat');
              let typeDetail = this.propFlatTypeDetails.find(o => o.type_detail === 'Flat');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.flats = true;

            await this.setPropertyTypes('flat');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
            let type = this.propertyTypesOptions.find(o => o.type === 'Flat');
            let typeDetail = this.propFlatTypeDetails.find(o => o.type_detail === 'Flat');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('flat');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
          let type = this.propertyTypesOptions.find(o => o.type === 'Flat');
          let typeDetail = this.propFlatTypeDetails.find(o => o.type_detail === 'Flat');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.studio': async function (val) {
      if(val === true) {
        this.form.property_types.studio = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.flats === true) {
            if (this.form.property_types.flats_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('studio');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
              let type = this.propertyTypesOptions.find(o => o.type === 'Flat');
              let typeDetail = this.propFlatTypeDetails.find(o => o.type_detail === 'Studio Flat');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.flats = true;

            await this.setPropertyTypes('studio');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
            let type = this.propertyTypesOptions.find(o => o.type === 'Flat');
            let typeDetail = this.propFlatTypeDetails.find(o => o.type_detail === 'Studio Flat');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('studio');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
          let type = this.propertyTypesOptions.find(o => o.type === 'Flat');
          let typeDetail = this.propFlatTypeDetails.find(o => o.type_detail === 'Studio Flat');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.maisonette': async function (val) {
      if(val === true) {
        this.form.property_types.maisonette = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.flats === true) {
            if (this.form.property_types.flats_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('maisonette');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
              let type = this.propertyTypesOptions.find(o => o.type === 'Flat');
              let typeDetail = this.propFlatTypeDetails.find(o => o.type_detail === 'Maisonette');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.flats = true;

            await this.setPropertyTypes('maisonette');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
            let type = this.propertyTypesOptions.find(o => o.type === 'Flat');
            let typeDetail = this.propFlatTypeDetails.find(o => o.type_detail === 'Maisonette');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('maisonette');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Residential');
          let type = this.propertyTypesOptions.find(o => o.type === 'Flat');
          let typeDetail = this.propFlatTypeDetails.find(o => o.type_detail === 'Maisonette');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    /* Commercial - Offices */
    'form.property_types.traditional_office': async function (val) {
      if(val === true) {
        this.form.property_types.traditional_office = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.offices === true) {
            if (this.form.property_types.offices_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('traditional_office');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Office');
              let typeDetail = this.propOfficeTypeDetails.find(o => o.type_detail === 'Traditional Office');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.offices = true;

            await this.setPropertyTypes('traditional_office');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Office');
            let typeDetail = this.propOfficeTypeDetails.find(o => o.type_detail === 'Traditional Office');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('traditional_office');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Office');
          let typeDetail = this.propOfficeTypeDetails.find(o => o.type_detail === 'Traditional Office');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.serviced_office': async function (val) {
      if(val === true) {
        this.form.property_types.serviced_office = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.offices === true) {
            if (this.form.property_types.offices_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('serviced_office');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Office');
              let typeDetail = this.propOfficeTypeDetails.find(o => o.type_detail === 'Serviced Office');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.offices = true;

            await this.setPropertyTypes('serviced_office');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Office');
            let typeDetail = this.propOfficeTypeDetails.find(o => o.type_detail === 'Serviced Office');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('serviced_office');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Office');
          let typeDetail = this.propOfficeTypeDetails.find(o => o.type_detail === 'Serviced Office');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.managed_office': async function (val) {
      if(val === true) {
        this.form.property_types.managed_office = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.offices === true) {
            if (this.form.property_types.offices_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('managed_office');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Office');
              let typeDetail = this.propOfficeTypeDetails.find(o => o.type_detail === 'Managed Office');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.offices = true;

            await this.setPropertyTypes('managed_office');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Office');
            let typeDetail = this.propOfficeTypeDetails.find(o => o.type_detail === 'Managed Office');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('managed_office');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Office');
          let typeDetail = this.propOfficeTypeDetails.find(o => o.type_detail === 'Managed Office');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    /* Commercial - Retail */
    'form.property_types.shop': async function (val) {
      if(val === true) {
        this.form.property_types.shop = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.retail === true) {
            if (this.form.property_types.retail_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('shop');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Retail');
              let typeDetail = this.propRetailTypeDetails.find(o => o.type_detail === 'Shop');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.retail = true;

            await this.setPropertyTypes('shop');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Retail');
            let typeDetail = this.propRetailTypeDetails.find(o => o.type_detail === 'Shop');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('shop');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Retail');
          let typeDetail = this.propRetailTypeDetails.find(o => o.type_detail === 'Shop');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.shopping_mall': async function (val) {
      if(val === true) {
        this.form.property_types.shopping_mall = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.retail === true) {
            if (this.form.property_types.retail_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('shopping_mall');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Retail');
              let typeDetail = this.propRetailTypeDetails.find(o => o.type_detail === 'Shopping Mall');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.retail = true;

            await this.setPropertyTypes('shopping_mall');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Retail');
            let typeDetail = this.propRetailTypeDetails.find(o => o.type_detail === 'Shopping Mall');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('shopping_mall');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Retail');
          let typeDetail = this.propRetailTypeDetails.find(o => o.type_detail === 'Shopping Mall');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.retail_store': async function (val) {
      if(val === true) {
        this.form.property_types.retail_store = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.retail === true) {
            if (this.form.property_types.retail_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('retail_store');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Retail');
              let typeDetail = this.propRetailTypeDetails.find(o => o.type_detail === 'Retail Store');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.retail = true;

            await this.setPropertyTypes('retail_store');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Retail');
            let typeDetail = this.propRetailTypeDetails.find(o => o.type_detail === 'Retail Store');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('retail_store');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Retail');
          let typeDetail = this.propRetailTypeDetails.find(o => o.type_detail === 'Retail Store');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    /* Commercial - Leisure */
    'form.property_types.restaurant_cafe': async function (val) {
      if(val === true) {
        this.form.property_types.restaurant_cafe = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.leisure === true) {
            if (this.form.property_types.leisure_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('restaurant_cafe');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
              let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Restaurant / Cafe');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.leisure = true;

            await this.setPropertyTypes('restaurant_cafe');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
            let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Restaurant / Cafe');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('restaurant_cafe');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
          let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Restaurant / Cafe');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.takeaway': async function (val) {
      if(val === true) {
        this.form.property_types.takeaway = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.leisure === true) {
            if (this.form.property_types.leisure_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('takeaway');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
              let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Takeaway');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.leisure = true;

            await this.setPropertyTypes('takeaway');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
            let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Takeaway');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('takeaway');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
          let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Takeaway');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.pub': async function (val) {
      if(val === true) {
        this.form.property_types.pub = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.leisure === true) {
            if (this.form.property_types.leisure_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('pub');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
              let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Pub');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.leisure = true;

            await this.setPropertyTypes('pub');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
            let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Pub');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('pub');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
          let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Pub');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.entertainment': async function (val) {
      if(val === true) {
        this.form.property_types.entertainment = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.leisure === true) {
            if (this.form.property_types.leisure_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('entertainment');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
              let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Entertainment');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.leisure = true;

            await this.setPropertyTypes('entertainment');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
            let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Entertainment');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('entertainment');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
          let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Entertainment');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.sport_healthcare_facility': async function (val) {
      if(val === true) {
        this.form.property_types.sport_healthcare_facility = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.leisure === true) {
            if (this.form.property_types.leisure_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('sport_healthcare_facility');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
              let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Sport / Healthcare Facility');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.leisure = true;

            await this.setPropertyTypes('sport_healthcare_facility');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
            let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Sport / Healthcare Facility');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('sport_healthcare_facility');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
          let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Sport / Healthcare Facility');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.hotel_tourism': async function (val) {
      if(val === true) {
        this.form.property_types.hotel_tourism = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.leisure === true) {
            if (this.form.property_types.leisure_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('hotel_tourism');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
              let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Hotel / Tourism');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.leisure = true;

            await this.setPropertyTypes('hotel_tourism');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
            let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Hotel / Tourism');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('hotel_tourism');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Leisure');
          let typeDetail = this.propLeisureTypeDetails.find(o => o.type_detail === 'Hotel / Tourism');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    /* Commercial Healthcare */
    'form.property_types.pharmacy': async function (val) {
      if(val === true) {
        this.form.property_types.pharmacy = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.healthcare === true) {
            if (this.form.property_types.healthcare_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('pharmacy');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Healthcare');
              let typeDetail = this.propHealthcareTypeDetails.find(o => o.type_detail === 'Pharmacy');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.healthcare = true;

            await this.setPropertyTypes('pharmacy');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Healthcare');
            let typeDetail = this.propHealthcareTypeDetails.find(o => o.type_detail === 'Pharmacy');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('pharmacy');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Healthcare');
          let typeDetail = this.propHealthcareTypeDetails.find(o => o.type_detail === 'Pharmacy');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.medical_center': async function (val) {
      if(val === true) {
        this.form.property_types.medical_center = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.healthcare === true) {
            if (this.form.property_types.healthcare_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('medical_center');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Healthcare');
              let typeDetail = this.propHealthcareTypeDetails.find(o => o.type_detail === 'Medical Center');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.healthcare = true;

            await this.setPropertyTypes('medical_center');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Healthcare');
            let typeDetail = this.propHealthcareTypeDetails.find(o => o.type_detail === 'Medical Center');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('medical_center');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Healthcare');
          let typeDetail = this.propHealthcareTypeDetails.find(o => o.type_detail === 'Medical Center');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.nursing_home': async function (val) {
      if(val === true) {
        this.form.property_types.nursing_home = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.healthcare === true) {
            if (this.form.property_types.healthcare_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('nursing_home');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Healthcare');
              let typeDetail = this.propHealthcareTypeDetails.find(o => o.type_detail === 'Nursing Home');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.healthcare = true;

            await this.setPropertyTypes('nursing_home');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Healthcare');
            let typeDetail = this.propHealthcareTypeDetails.find(o => o.type_detail === 'Nursing Home');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('nursing_home');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Healthcare');
          let typeDetail = this.propHealthcareTypeDetails.find(o => o.type_detail === 'Nursing Home');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.hospital': async function (val) {
      if(val === true) {
        this.form.property_types.hospital = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.healthcare === true) {
            if (this.form.property_types.healthcare_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('hospital');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Healthcare');
              let typeDetail = this.propHealthcareTypeDetails.find(o => o.type_detail === 'Hospital');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.healthcare = true;

            await this.setPropertyTypes('hospital');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Healthcare');
            let typeDetail = this.propHealthcareTypeDetails.find(o => o.type_detail === 'Hospital');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('hospital');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Healthcare');
          let typeDetail = this.propHealthcareTypeDetails.find(o => o.type_detail === 'Hospital');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    /* Commercial - Garage/Parking */
    'form.property_types.parking_space': async function (val) {
      if(val === true) {
        this.form.property_types.parking_space = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.garage_parking === true) {
            if (this.form.property_types.garage_parking_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('parking_space');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
              let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Parking Space');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.garage_parking = true;

            await this.setPropertyTypes('parking_space');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
            let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Parking Space');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('parking_space');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
          let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Parking Space');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.single_garage': async function (val) {
      if(val === true) {
        this.form.property_types.single_garage = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.garage_parking === true) {
            if (this.form.property_types.garage_parking_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('single_garage');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
              let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Single Garage');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.garage_parking = true;

            await this.setPropertyTypes('single_garage');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
            let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Single Garage');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('single_garage');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
          let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Single Garage');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.multiple_garages': async function (val) {
      if(val === true) {
        this.form.property_types.multiple_garages = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.garage_parking === true) {
            if (this.form.property_types.garage_parking_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('multiple_garages');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
              let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Multiple Garages');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.garage_parking = true;

            await this.setPropertyTypes('multiple_garages');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
            let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Multiple Garages');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('multiple_garages');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
          let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Multiple Garages');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.parking_lot': async function (val) {
      if(val === true) {
        this.form.property_types.parking_lot = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.garage_parking === true) {
            if (this.form.property_types.garage_parking_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('parking_lot');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
              let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Parking Lot');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.garage_parking = true;

            await this.setPropertyTypes('parking_lot');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
            let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Parking Lot');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('parking_lot');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
          let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Parking Lot');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.multi_storey_parking': async function (val) {
      if(val === true) {
        this.form.property_types.multi_storey_parking = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.garage_parking === true) {
            if (this.form.property_types.garage_parking_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('multi_storey_parking');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
              let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Multi-storey Parking');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.garage_parking = true;

            await this.setPropertyTypes('multi_storey_parking');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
            let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Multi-storey Parking');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('multi_storey_parking');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Garage/Parking');
          let typeDetail = this.propParkingTypeDetails.find(o => o.type_detail === 'Multi-storey Parking');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    /* Commercial - Multifamily */
    'form.property_types.duplex': async function (val) {
      if(val === true) {
        this.form.property_types.duplex = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.multifamily === true) {
            if (this.form.property_types.multifamily_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('duplex');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Multifamily');
              let typeDetail = this.propMultifamilyTypeDetails.find(o => o.type_detail === 'Duplex');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.multifamily = true;

            await this.setPropertyTypes('duplex');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Multifamily');
            let typeDetail = this.propMultifamilyTypeDetails.find(o => o.type_detail === 'Duplex');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('duplex');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Multifamily');
          let typeDetail = this.propMultifamilyTypeDetails.find(o => o.type_detail === 'Duplex');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.block_of_flats': async function (val) {
      if(val === true) {
        this.form.property_types.block_of_flats = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.multifamily === true) {
            if (this.form.property_types.multifamily_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('block_of_flats');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Multifamily');
              let typeDetail = this.propMultifamilyTypeDetails.find(o => o.type_detail === 'Block of Flats');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.multifamily = true;

            await this.setPropertyTypes('block_of_flats');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Multifamily');
            let typeDetail = this.propMultifamilyTypeDetails.find(o => o.type_detail === 'Block of Flats');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('block_of_flats');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Multifamily');
          let typeDetail = this.propMultifamilyTypeDetails.find(o => o.type_detail === 'Block of Flats');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.apartment_complex': async function (val) {
      if(val === true) {
        this.form.property_types.apartment_complex = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.multifamily === true) {
            if (this.form.property_types.multifamily_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('apartment_complex');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Multifamily');
              let typeDetail = this.propMultifamilyTypeDetails.find(o => o.type_detail === 'Apartment Complex');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.multifamily = true;

            await this.setPropertyTypes('apartment_complex');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Multifamily');
            let typeDetail = this.propMultifamilyTypeDetails.find(o => o.type_detail === 'Apartment Complex');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('apartment_complex');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Commercial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Multifamily');
          let typeDetail = this.propMultifamilyTypeDetails.find(o => o.type_detail === 'Apartment Complex');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    /* Industrial - Warehouse */
    'form.property_types.warehouses': async function (val) {
      if(val === true) {
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.warehouses === true) {
            if (this.form.property_types.warehouses_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('warehouses');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Warehouse');

              await this.setPropertyCatType(category, type);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.warehouses = true;

            await this.setPropertyTypes('warehouses');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Warehouse');

            await this.setPropertyCatType(category, type);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('warehouses');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Warehouse');

          await this.setPropertyCatType(category, type);
        }
      }
    },
    /* Industrial Factory */
    'form.property_types.factories': async function (val) {
      if(val === true) {
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.factories === true) {
            if (this.form.property_types.factories_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('factories');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Factory');

              await this.setPropertyCatType(category, type);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.factories = true;

            await this.setPropertyTypes('factories');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Factory');

            await this.setPropertyCatType(category, type);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('factories');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Factory');

          await this.setPropertyCatType(category, type);
        }
      }
    },
    /* Industrial - Powerplants */
    'form.property_types.hydroelectric_plant': async function (val) {
      if(val === true) {
        this.form.property_types.hydroelectric_plant = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.power_plants === true) {
            if (this.form.property_types.power_plants_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('hydroelectric_plant');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
              let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Hydroelectric Plant');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.power_plants = true;

            await this.setPropertyTypes('hydroelectric_plant');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
            let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Hydroelectric Plant');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('hydroelectric_plant');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
          let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Hydroelectric Plant');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.coal_fired_plant': async function (val) {
      if(val === true) {
        this.form.property_types.coal_fired_plant = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.power_plants === true) {
            if (this.form.property_types.power_plants_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('coal_fired_plant');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
              let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Coal-fired Plant');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.power_plants = true;

            await this.setPropertyTypes('coal_fired_plant');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
            let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Coal-fired Plant');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('coal_fired_plant');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
          let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Coal-fired Plant');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.diesel_fired_plant': async function (val) {
      if(val === true) {
        this.form.property_types.diesel_fired_plant = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.power_plants === true) {
            if (this.form.property_types.power_plants_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('diesel_fired_plant');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
              let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Diesel-fired Plant');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.power_plants = true;

            await this.setPropertyTypes('diesel_fired_plant');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
            let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Diesel-fired Plant');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('diesel_fired_plant');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
          let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Diesel-fired Plant');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.geothermal_plant': async function (val) {
      if(val === true) {
        this.form.property_types.geothermal_plant = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.power_plants === true) {
            if (this.form.property_types.power_plants_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('geothermal_plant');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
              let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Geothermal Plant');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.power_plants = true;

            await this.setPropertyTypes('geothermal_plant');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
            let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Geothermal Plant');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('geothermal_plant');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
          let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Geothermal Plant');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.gas_fired_plant': async function (val) {
      if(val === true) {
        this.form.property_types.gas_fired_plant = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.power_plants === true) {
            if (this.form.property_types.power_plants_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('gas_fired_plant');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
              let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Gas-fired Plant');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.power_plants = true;

            await this.setPropertyTypes('gas_fired_plant');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
            let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Gas-fired Plant');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('gas_fired_plant');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
          let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Gas-fired Plant');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.solar_power_plant': async function (val) {
      if(val === true) {
        this.form.property_types.solar_power_plant = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.power_plants === true) {
            if (this.form.property_types.power_plants_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('solar_power_plant');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
              let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Solar Power Plant');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.power_plants = true;

            await this.setPropertyTypes('solar_power_plant');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
            let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Solar Power Plant');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('solar_power_plant');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
          let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Solar Power Plant');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.wind_power_plant': async function (val) {
      if(val === true) {
        this.form.property_types.wind_power_plant = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.power_plants === true) {
            if (this.form.property_types.power_plants_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('wind_power_plant');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
              let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Wind Power Plant');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.power_plants = true;

            await this.setPropertyTypes('wind_power_plant');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
            let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Wind Power Plant');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('wind_power_plant');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
          let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Wind Power Plant');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    'form.property_types.tidal_power_plant': async function (val) {
      if(val === true) {
        this.form.property_types.tidal_power_plant = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.power_plants === true) {
            if (this.form.property_types.power_plants_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('tidal_power_plant');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
              let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
              let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Tidal Power Plant');

              await this.setPropertyCatType(category, type, typeDetail);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.power_plants = true;

            await this.setPropertyTypes('tidal_power_plant');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
            let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
            let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Tidal Power Plant');

            await this.setPropertyCatType(category, type, typeDetail);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('tidal_power_plant');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Industrial');
          let type = this.propertyTypesOptions.find(o => o.type === 'Power Plants');
          let typeDetail = this.propPowerPlantTypeDetails.find(o => o.type_detail === 'Tidal Power Plant');

          await this.setPropertyCatType(category, type, typeDetail);
        }
      }
    },
    /* Land */
    'form.property_types.greenfield': async function (val) {
      if(val === true) {
        this.form.property_types.greenfield = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.land === true) {
            if (this.form.property_types.land_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('greenfield');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Land');
              let type = this.propertyTypesOptions.find(o => o.type === 'Greenfield');

              await this.setPropertyCatType(category, type);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.land = true;

            await this.setPropertyTypes('greenfield');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Land');
            let type = this.propertyTypesOptions.find(o => o.type === 'Greenfield');

            await this.setPropertyCatType(category, type);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('greenfield');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Land');
          let type = this.propertyTypesOptions.find(o => o.type === 'Greenfield');

          await this.setPropertyCatType(category, type);
        }
      }
    },
    'form.property_types.brownfield': async function (val) {
      if(val === true) {
        this.form.property_types.brownfield = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.land === true) {
            if (this.form.property_types.land_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('brownfield');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Land');
              let type = this.propertyTypesOptions.find(o => o.type === 'Brownfield');

              await this.setPropertyCatType(category, type);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.land = true;

            await this.setPropertyTypes('brownfield');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Land');
            let type = this.propertyTypesOptions.find(o => o.type === 'Brownfield');

            await this.setPropertyCatType(category, type);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('brownfield');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Land');
          let type = this.propertyTypesOptions.find(o => o.type === 'Brownfield');

          await this.setPropertyCatType(category, type);
        }
      }
    },
    /* Luxury */
    'form.property_types.mansion': async function (val) {
      if(val === true) {
        this.form.property_types.mansion = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.luxury === true) {
            if (this.form.property_types.luxury_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('mansion');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Luxury');
              let type = this.propertyTypesOptions.find(o => o.type === 'Mansion');

              await this.setPropertyCatType(category, type);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.luxury = true;

            await this.setPropertyTypes('mansion');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Luxury');
            let type = this.propertyTypesOptions.find(o => o.type === 'Mansion');

            await this.setPropertyCatType(category, type);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('mansion');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Luxury');
          let type = this.propertyTypesOptions.find(o => o.type === 'Mansion');

          await this.setPropertyCatType(category, type);
        }
      }
    },
    'form.property_types.villa': async function (val) {
      if(val === true) {
        this.form.property_types.villa = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.luxury === true) {
            if (this.form.property_types.luxury_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('villa');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Luxury');
              let type = this.propertyTypesOptions.find(o => o.type === 'Villa');

              await this.setPropertyCatType(category, type);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.luxury = true;

            await this.setPropertyTypes('villa');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Luxury');
            let type = this.propertyTypesOptions.find(o => o.type === 'Villa');

            await this.setPropertyCatType(category, type);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('villa');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Luxury');
          let type = this.propertyTypesOptions.find(o => o.type === 'Villa');

          await this.setPropertyCatType(category, type);
        }
      }
    },
    /* Special Use */
    'form.property_types.church': async function (val) {
      if(val === true) {
        this.form.property_types.church = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.special_use === true) {
            if (this.form.property_types.special_use_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('church');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
              let type = this.propertyTypesOptions.find(o => o.type === 'Church');

              await this.setPropertyCatType(category, type);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.special_use = true;

            await this.setPropertyTypes('church');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
            let type = this.propertyTypesOptions.find(o => o.type === 'Church');

            await this.setPropertyCatType(category, type);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('church');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
          let type = this.propertyTypesOptions.find(o => o.type === 'Church');

          await this.setPropertyCatType(category, type);
        }
      }
    },
    'form.property_types.cemetery': async function (val) {
      if(val === true) {
        this.form.property_types.cemetery = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.special_use === true) {
            if (this.form.property_types.special_use_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('cemetery');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
              let type = this.propertyTypesOptions.find(o => o.type === 'Cemetery');

              await this.setPropertyCatType(category, type);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.special_use = true;

            await this.setPropertyTypes('cemetery');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
            let type = this.propertyTypesOptions.find(o => o.type === 'Cemetery');

            await this.setPropertyCatType(category, type);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('cemetery');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
          let type = this.propertyTypesOptions.find(o => o.type === 'Cemetery');

          await this.setPropertyCatType(category, type);
        }
      }
    },
    'form.property_types.park': async function (val) {
      if(val === true) {
        this.form.property_types.park = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.special_use === true) {
            if (this.form.property_types.special_use_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('park');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
              let type = this.propertyTypesOptions.find(o => o.type === 'Park');

              await this.setPropertyCatType(category, type);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.special_use = true;

            await this.setPropertyTypes('park');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
            let type = this.propertyTypesOptions.find(o => o.type === 'Park');

            await this.setPropertyCatType(category, type);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('park');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
          let type = this.propertyTypesOptions.find(o => o.type === 'Park');

          await this.setPropertyCatType(category, type);
        }
      }
    },
    'form.property_types.school': async function (val) {
      if(val === true) {
        this.form.property_types.school = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.special_use === true) {
            if (this.form.property_types.special_use_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('school');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
              let type = this.propertyTypesOptions.find(o => o.type === 'School');

              await this.setPropertyCatType(category, type);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.special_use = true;

            await this.setPropertyTypes('school');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
            let type = this.propertyTypesOptions.find(o => o.type === 'School');

            await this.setPropertyCatType(category, type);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('school');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
          let type = this.propertyTypesOptions.find(o => o.type === 'School');

          await this.setPropertyCatType(category, type);
        }
      }
    },
    'form.property_types.barn': async function (val) {
      if(val === true) {
        this.form.property_types.barn = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.special_use === true) {
            if (this.form.property_types.special_use_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('barn');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
              let type = this.propertyTypesOptions.find(o => o.type === 'Barn');

              await this.setPropertyCatType(category, type);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.special_use = true;

            await this.setPropertyTypes('barn');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
            let type = this.propertyTypesOptions.find(o => o.type === 'Barn');

            await this.setPropertyCatType(category, type);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('barn');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
          let type = this.propertyTypesOptions.find(o => o.type === 'Barn');

          await this.setPropertyCatType(category, type);
        }
      }
    },
    'form.property_types.campsite': async function (val) {
      if(val === true) {
        this.form.property_types.campsite = true;
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.special_use === true) {
            if (this.form.property_types.special_use_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('campsite');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
              let type = this.propertyTypesOptions.find(o => o.type === 'Campsite');

              await this.setPropertyCatType(category, type);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.special_use = true;

            await this.setPropertyTypes('campsite');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
            let type = this.propertyTypesOptions.find(o => o.type === 'Campsite');

            await this.setPropertyCatType(category, type);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('campsite');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Special Use');
          let type = this.propertyTypesOptions.find(o => o.type === 'Campsite');

          await this.setPropertyCatType(category, type);
        }
      }
    },
    /* Rooms */
    'form.property_types.rooms': async function (val) {
      if(val === true) {
        if (this.postType === 4 || this.postType === 2) {
          if (this.form.property_types.rooms === true) {
            if (this.form.property_types.rooms_disable) {
              // Do nothing as we picked whole group
            } else {
              // Reset disabled checkboxes
              await this.resetDisabledCheckboxes();

              await this.setPropertyTypes('rooms');

              let category = this.propertyCategoriesOptions.find(o => o.type === 'Room');

              await this.setPropertyCatType(category);
            }
          } else {
            // Reset disabled checkboxes
            await this.resetDisabledCheckboxes();

            this.form.property_types.rooms = true;

            await this.setPropertyTypes('rooms');

            let category = this.propertyCategoriesOptions.find(o => o.type === 'Room');

            await this.setPropertyCatType(category);
          }
        }
        if (this.postType !== 4 && this.postType !== 2) {
          await this.setPropertyTypes('rooms');

          let category = this.propertyCategoriesOptions.find(o => o.type === 'Room');

          await this.setPropertyCatType(category);
        }
      }
    },
    /* ** Start Validation Watchers ** */
    // Validation Basic Details/Premises Info
    'form.property_title': function (val) {
      let waitASec = setTimeout(() => {
        this.validateBasicDetails();
        clearTimeout(waitASec);
      }, 200);
    },
    'form.full_address': function (val) {
      let waitASec = setTimeout(() => {
        this.validateBasicDetails();
        clearTimeout(waitASec);
      }, 200);
    },
    // Validation Premises Details
    'form.price': function (val) {
      this.validatePremisesDetails();
    },
    'form.property_rent_type_id': function (val) {
      switch(val) {
        case 1:
          this.form.property_rent_type = {id: val, type: 'N/A'};
          break;
        case 2:
          this.form.property_rent_type = {id: val, type: 'hour'};
          break;
        case 3:
          this.form.property_rent_type = {id: val, type: 'night'};
          break;
        case 4:
          this.form.property_rent_type = {id: val, type: 'week'};
          break;
        case 5:
          this.form.property_rent_type = {id: val, type: 'month'};
          break;
        case 6:
          this.form.property_rent_type = {id: val, type: 'TBD'};
          break;
        default:
          this.form.property_rent_type = {id: null, type: null};
          break;
      }
      this.validatePremisesDetails();
    },
    'form.tenure': function (val) {
      this.validatePremisesDetails();
    },
    'form.tenants_guests': function (val) {
      this.validatePremisesDetails();
    },
    'form.epc_rating': function (val) {
      this.validatePremisesDetails();
    },
    'form.rooms': {
      handler (val, oldVal) {
        this.validatePremisesDetails();
      }, deep: true, immediate: true
    },
    // Validation Premises Status
    'form.pet_friendly_paid': {
      handler (val, oldVal) {
        this.validatePremisesStatus();
      }, immediate: true
    },
    'form.pet_friendly_fee': {
      handler (val, oldVal) {
        this.validatePremisesStatus();
      }, immediate: true
    },
    // Validation Services And Amenities
    'form.room_service_paid': {
      handler (val, oldVal) {
        this.validateServicesAndAmenities();
      }, immediate: true
    },
    'form.service_fee': {
      handler (val, oldVal) {
        this.validateServicesAndAmenities();
      }, immediate: true
    },
    // Validation Services And Amenities
    'form.cleaning_service_paid': {
      handler (val, oldVal) {
        this.validateServicesAndAmenities();
      }, immediate: true
    },
    'form.cleaning_fee': {
      handler (val, oldVal) {
        this.validateServicesAndAmenities();
      }, immediate: true
    },
    // Validation Availability
    'form.check_in_instructions_id': function (val) {
      this.validateAvailability();
    },
    'form.check_out_instructions_id': function (val) {
      this.validateAvailability();
    },
    'form.cancellation_fee': function (val) {
      this.validateAvailability();
    },
    'form.discount_fee_term': function (val) {
      this.validateAvailability();
    },
    'form.discount_fee_last_minute': function (val) {
      this.validateAvailability();
    },
    'form.discount_fee_no_of_guests': function (val) {
      this.validateAvailability();
    },
    // Validation Contact Details
    'form.title': function (val) {
      this.validateContactDetails();
    },
    'form.name': function (val) {
      this.validateContactDetails();
    },
    'form.surname': function (val) {
      this.validateContactDetails();
    },
    'form.mobile_prefix': function (val) {
      this.validateContactDetails();
    },
    'form.mobile': function (val) {
      this.validateContactDetails();
    },
    'form.email': function (val) {
      this.validateContactDetails();
    },
    /* ** End Validation Watchers ** */
  }
}
</script>

<style lang="scss">

#premisesGroup .sticky {
  background-color: white;
  top: 12%;
  z-index: 9998; // One less than Nav
}

/* Map Box Geocoder */
#geocoder {
  z-index: 1;
}
.mapboxgl-ctrl-geocoder {
  border-radius: .25rem;
  border: 1px solid #ced4da;
  width: 100%;
  box-shadow: none;
  min-width: 100%;
}
/* End Map Box Geocoder */

/* Start Sections Button */
.section-btn {
  color: white;
  background-color: deepskyblue;
  border: 1px solid transparent;
  border-radius: 4px;
  height: 50px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  &:hover {
    color: deepskyblue;
    background-color: transparent;
    border: 1px solid deepskyblue;
    border-radius: 4px;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    .check-not-filled {
      height: 25px;
      width: 25px;
      color: #9e9e9e;
      display: inline-block;
      float: right;
      margin: auto auto;
    }
    .check-no-validation-error {
      font-size: 9px;
      color: #9e9e9e;
      display: inline-block;
      margin: auto auto;
    }
  }
  .check-not-filled {
    height: 25px;
    width: 25px;
    color: white;
    display: inline-block;
    float: right;
    margin: auto auto;
  }
  .check-filled {
    height: 25px;
    width: 25px;
    color: limegreen !important; // #0d6efd
    background-color: white;
    border: 1px solid white;
    border-radius: 50%;
  }
  .check-no-validation-error {
    font-size: 9px;
    color: white;
    display: inline-block;
    margin: auto auto;
  }
  .check-validation-error {
    color: red !important; // #0d6efd
  }
}
.section-btn.collapsed {
  color: deepskyblue;
  background-color: transparent;
  border: 1px solid deepskyblue;
  border-radius: 4px;
  height: 50px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  &:hover {
    //color: white;
    //background-color: deepskyblue;
    //border: 1px solid transparent;
    //border-radius: 4px;
    //transition: 0.5s;
    //-webkit-transition: 0.5s;
    //-moz-transition: 0.5s;
    //-o-transition: 0.5s;
    //.check-not-filled {
    //  height: 25px;
    //  width: 25px;
    //  color: white;
    //  display: inline-block;
    //  float: right;
    //  margin: auto auto;
    //}
    //.check-no-validation-error {
    //  font-size: 9px;
    //  color: white;
    //  display: inline-block;
    //  margin: auto auto;
    //}
  }
  .check-not-filled {
    height: 25px;
    width: 25px;
    color: #9e9e9e;
    display: inline-block;
    float: right;
    margin: auto auto;
  }
  .check-no-validation-error {
    font-size: 9px;
    color: #9e9e9e;
    display: inline-block;
    margin: auto auto;
  }
}
/* End Sections Button */

/* Start Add Room */
.btn-add {
  white-space: nowrap;
}

.btn-add .add-icon {
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.btn-add:hover .add-icon {
  color: white;
  transform: rotate(90deg);
  transition: 0.5s;
  transition-delay: 0.1s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition-delay: 0.1s;
  -moz-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
}
/* End Add Room */

/* Start Add House Rules */
.rule-list {
  border-radius: 4px;
  border: 1px solid #ced4da;
  display: inline-block;
  width: 100%;
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #212529;
  .edit-btn {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: block;
    margin: auto;
    font-size: 9px;
    padding: 0;
    transition: 0.6s;
    border: 0;
    color: white;
    background-color: orange;
  }

  .edit-btn:hover {
    color: black;
    border: 1px solid orange !important;
    background-color: white;
    transition: 0.6s;
  }
}
/* End Add House Rules */

#mainMenu {
  position: relative;
  width: 95%;
  button.mainMenuButton {
    color: black;
    background-color: white;
    width: 120px;
    font-size: 12px;
    text-align: left;
    border-radius: 10px;
    transition: 0.6s;
    margin-left: 5px;
    padding: 5px;
  }
  button:hover.mainMenuButton {
    color: white;
    background-color: #0d6efd;
    transition: 0.6s;
  }
}
.firstLevelList {
  width: 90%;
  position: absolute;
  left: 100%;
  border: none;
  margin: 0;
  padding: 0;
  li {
    padding: 0;
    margin: 0;
  }
  button.firstLevelButton {
    color: black;
    background-color: white;
    width: 150px;
    font-size: 12px;
    text-align: left;
    border-radius: 10px;
    transition: 0.6s;
    padding: 5px;
  }
  button:hover.firstLevelButton {
    color: white;
    background-color: #0d6efd;
    transition: 0.6s;
  }
}
.secondLevelList {
  position: absolute;
  left: 100%;
  width: 90%;
  border: none;
  margin: 0;
  padding: 0;
  li {
    padding: 0;
    margin: 0;
  }
  button.secondLevelButton {
    color: black;
    background-color: white;
    width: 160px;
    font-size: 12px;
    text-align: left;
    border-radius: 10px;
    transition: 0.6s;
    padding: 5px;
  }
  button:hover.secondLevelButton {
    color: white;
    background-color: #0d6efd;
    transition: 0.6s;
  }
}

.mainMenu:hover .dropdown-menu {
  width: 100%;
  display: block;
  margin-top: 0; // remove the gap so it doesn't close
  .firstLevel .dropdown-menu {
    display: none;
  }
  .firstLevel:hover .dropdown-menu {
    width: 100%;
    display: block;
    margin-top: 0; // remove the gap so it doesn't close
  }
}

/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'Arial', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}

/* ===== Toggles ===== */
/* The switch - the box around the slider */
.switch {
  position: relative;
  text-align: center;
  display: inline-block;
  line-height: 20px;
  -moz-block-height: 20px;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 1px;
  bottom: 1.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.options-paragraph {
  font-size: 14px;
}

.options-select {
  width: 100%;
}

.options-input {
  border-radius: .25rem;
  border: 1px solid #ced4da;
  width: 100%;
  padding: .375rem .25rem .375rem .75rem;
}
.options-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  //white-space: -pre-wrap;     /* Opera <7 */
  white-space: -o-pre-wrap;   /* Opera 7 */
  word-wrap: break-word;      /* IE */

  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
}
/* end tooltip - popups info on icons */

/* Start Text truncate - white space - for long texts and icons */
.list-relative-contact {
  width: 100%;
  position: relative;
  min-height: 30px;
}

.list-relative {
  width: 100%;
  position: relative;
}

.text-truncate-item {
  width: 100%;
  box-sizing:border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing:border-box;
  overflow: visible !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  padding: 0 0 0 1px;
  border-radius: 5px;
  z-index: 1000;
}

.options-text-truncate-item {
  width: 100%;
  box-sizing:border-box;
  transition: 0.7s;
  border-radius: 5px;
}
.options-text-truncate-item:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing:border-box;
  overflow: visible !important;
  background-color: white;
  z-index: 1000;
}

.not-absolute-text-truncate-item {
  width: 100%;
  box-sizing:border-box;
  transition: 0.7s;
  border-radius: 5px;
}
.not-absolute-text-truncate-item:hover {
  transition: 0.7s;
  box-sizing:border-box;
  overflow: visible !important;
  background-color: white;
  z-index: 1000;
}
/* End Text truncate - white space - for long texts and icons */

/* ===== End toggles ===== */

/* Start Upload Files */
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1em;
  text-align: center;
  padding: 10px 0;
}

.uploading-property-images {
    width: 100%;
    max-width: 100%;
    height: 120px;
    max-height: 120px;
}

.uploading-floorplan-images {
  width: 100%;
  max-width: 100%;
  height: 120px;
  max-height: 120px;
}

.delete-btn {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: block;
  margin: auto;
  font-size: 9px;
  padding: 0;
  transition: 0.6s;
  border: 0;
  color: white;
  background-color: #dc3545;
}

.delete-btn:hover {
  color: black;
  border: 1px solid #dc3545 !important;
  background-color: white;
  transition: 0.6s;
}
/* End Upload Files */

/* Preview Ad Details */
#previewPropDetailsModal {
  width: 100%;
  border: 1px solid rgba(0,0,0,.125);
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  height: 76vh;
  position: sticky;
  z-index: 1;
  top: 18%;
  left: 36vw;
  //background-color: rgba(33, 150, 243, 50%);
  background-color: rgba(255, 255, 255, 70%);
  overflow-x: hidden;
  transition: 0.5s;
  border-radius: 5px;
  background-clip: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.preview-modal-tab::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.preview-modal-tab {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* End Preview Ad Details */

/* Start Property Types and Categories */
.dropdown-btn {
  color: #212529;
  background-color: #fff;
}

#addEditPropType.dropdown-menu-prop-type {
  position: absolute;
  width: 95%;
  top: 100%;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 450px;
}
#addEditPropType {
  .form-check-label {
    width: 100%;
    height: auto;
    padding: 0 5px 0 5px;
    border-radius: 10px;
  }

  .form-check-label:hover {
    background-color: #e9ecef;
    cursor: pointer;
  }

  .filter-prop-label {
    background-color: #eeeeee;
    border-radius: 10px;
  }
}
/* End Property Types and Categories */

///* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
//@media screen and (max-width: 499px) {
//  /* Preview Ad Details */
//  #previewPropDetailsModal {
//    height: 100%;
//    position: relative;
//    top: 0;
//    left: 0;
//  }
//}
//@media screen and (min-width: 500px) {
//  #previewPropDetailsModal {
//    height: 100%;
//    position: relative;
//    top: 0;
//    left: 0;
//  }
//}
//@media screen and (min-width: 768px) {
//  #previewPropDetailsModal {
//    height: 100%;
//    position: relative;
//    top: 0;
//    left: 0;
//  }
//}
//@media screen and (min-width: 992px) {
//  #previewPropDetailsModal {
//    height: 100%;
//    position: sticky;
//    top: 22%;
//    left: 36vw;;
//  }
//}
//@media screen and (min-width: 1200px) {
//  #previewPropDetailsModal {
//    height: 73vh;
//    position: sticky;
//    top: 22%;
//    left: 36vw;;
//  }
//}
//@media screen and (min-width: 1920px) {
//  #previewPropDetailsModal {
//    height: 73vh;
//    position: sticky;
//    top: 22%;
//    left: 36vw;;
//  }
//}

</style>