<template>
  <div class="form-draft m-0 p-0">
      <div class="row m-0 p-0">
        <!-- Select Input to pick Form Drafts -->
        <div class="form-group col-1 p-0 m-0 mt-1">
          <span data-tooltip="tooltip" class="info-tooltip">
            <div class="no-text-wrap overflow-hidden text-truncate">
              <font-awesome-icon icon="info-circle" class="icon-fa" style="color: orange;" />
              <span class="tooltiptext">You can save and then pre-select up to 5 drafts, this can help with quicker pre-filling of the form, especially when you have to add several properties with similar setup</span>
            </div>
          </span>
        </div>
        <div class="form-group col-7 p-0 m-0">
          <select v-model="draft_id"
                  class="form-select cursor-pointer"
                  tabindex="-1"
                  aria-label="draft_id"
                  name="draft_id"
                  id="draft_id"
          >
            <option value="">Load Draft...</option>
            <option v-for="(draft , index) in drafts" v-bind:key="index" :value="draft.id" class="cursor-pointer">
              {{ draft.title }}
<!--               Possibly no need to delete drafts as we can overwrite them-->
<!--              &lt;!&ndash; Remove &ndash;&gt;-->
<!--              <div class="col-2 m-0 p-0">-->
<!--                <button :disabled="processing" @click="deleteDraft(draft)" class="delete-btn">-->
<!--                  <font-awesome-icon icon="trash-alt" class="" />-->
<!--                </button>-->
<!--              </div>-->
            </option>
          </select>
        </div>
        <!-- Save -->
        <div class="col-4 m-0 p-0">
          <button
              data-bs-toggle="modal"
              data-bs-target="#formDraftModal"
              class="btn btn-outline-primary add-draft-btn"
              @click="sendModal(formData, drafts, type, user, 'Form Draft')"
              :disabled="processing"
          >
            Save Draft <font-awesome-icon icon="save" class="add-draft-icon"/>
          </button>
        </div>
      </div>
  </div>
</template>

<script>
import ValidationErrors from "../ValidationErrors";
import axios from "axios";

export default {
  name: 'FormDraft',
  props: ['type', 'drafts', 'formData', 'refresh'],
  components: {
    'ValidationErrors': ValidationErrors
  },
  data(){
    return {
      user: this.$store.state.auth && this.$store.state.auth.user ? this.$store.state.auth.user.id : null,
      draft_id: '',
      processing: false
    }
  },
  mounted() {

  },
  methods: {
    sendModal(formData, drafts, type, userId, modalType) {
      let data = {
        formData: formData,
        type: type,
        userId: userId,
        drafts: drafts
      };

      this.$root.$emit('openModal', data, modalType);
    },
    // Possibly no need to delete drafts as we can overwrite them
    // async deleteDraft(draft = null) {
    //   if (draft === null) {
    //     return;
    //   }
    //
    //   let formData = new FormData();
    //   formData.append('user_id', this.$store.state.auth.user.id);
    //   formData.append('draft_id', draft.id);
    //
    //   await axios.post('api/draft/delete', formData).then(({data}) => {
    //     // Clear form title
    //     this.form.title = '';
    //     this.validationMessage = "Your draft was saved successfully!";
    //     this.showError = true;
    //     this.$root.$emit('refreshDrafts');
    //     this.validationErrors = null;
    //   }).catch(({response})=>{
    //     if (response && response.data && response.data.errors) {
    //       this.validationErrors = response.data.errors;
    //       const timeout = setTimeout(() => {
    //         this.validationErrors = '';
    //         clearTimeout(timeout);
    //       }, 3000)
    //       // this.validationMessage = response.data.message;
    //     }
    //     // alert(data.message)
    //   }).finally(() => {
    //     this.processing = false
    //   })
    // }
  },
  watch: {
    "draft_id": {
      handler: function (val, oldVal) {
        if (val !== null && oldVal !== val) {
          // We will need something like switch here, and based on type, we emit different function from parent
          // So as an example for 'ad premises' type, we would emit function from AddEditAd.vue
          // For 'ad services' type, we would emit function from AddEditService.vue
          // If we would need more possibilities in the future, we would need to expand the list here, as this is the only
          // way to pass draft id back to parent component

          // Above not necessarily, we can $emit selectDraft which is added in parent component straight to FormDraft component
          // @selectDraft="getSelectedDraft"
          this.$emit('selectDraft', val);
        }
      }, deep: true, immediate: true
    }
  }
}
</script>

<style scoped lang="scss">

.add-draft-btn {
  white-space: nowrap;
  &:hover {
    color: white;
  }
}

.add-draft-btn .add-draft-icon {
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.add-draft-btn:hover .add-draft-icon {
  transform: rotate(90deg);
  transition: 0.5s;
  -moz-transition: 0.5s;
}

</style>